import React, {useEffect, useState} from 'react';
import DashboardSettingsHeader from "./DashboardSettingsHeader";
import {BackIcon, ButtonArrowIcon} from "../../_assets/icons";
import DashboardInput from "./DashboardInput";
import PasswordStrengthBar from "../../../app/modules/Auth/Components/PasswordStrengthBar";
import PasswordValidationTexts from "../../../app/modules/Auth/Components/PasswordValidationTexts";
import FormButton from "../../../app/modules/Auth/Components/FormButton";
import propTypes from 'prop-types';
import {useIntl} from "react-intl";
import {commitUpdatePassword, setPasswordUpdatePassword} from "../../../app/modules/Auth/_redux/authCrud";

DashboardCreatePassword.propTypes = {
    iconOnClick : propTypes.func.isRequired,
    changePage : propTypes.func.isRequired
}

function DashboardCreatePassword(props) {
    const intl = useIntl();
    const [password,setPassword] = useState("");
    const [passwordRetry,setPasswordRetry] = useState("");
    const [passwordStrength,setPasswordStrength] = useState("");
    const [passwordError, setPasswordError] = useState({status: false, message: ''});
    const [buttonDisable, setButtonDisable] = useState(true);
    const [loading, setLoading] = useState(false);



    const passwordChecker = () => {
        if (password === passwordRetry && passwordStrength === 100) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
        if (passwordRetry.length > 1 && passwordRetry !== password) {
            setPasswordError({status: true, message: "Passwords not match"})
        } else {
            setPasswordError({status: false, message: ''})
        }
    }

    useEffect(passwordChecker, [password, passwordRetry]);

    const _passwordSubmit = () => {
        setLoading(true);
        setPasswordUpdatePassword(password)
            .then(data => {
                commitUpdatePassword()
                    .then(()=>{
                        props.changePage();
                    })
                    .catch(err => {
                        console.log("error happen when commit on update password")
                        setLoading(false)
                        props.setToastMenu({
                            status: true,
                            descText: err?.response?.data?.errorMessage,
                            headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                            isWarning : true
                        })

                    })
            })
            .catch(err => {
                console.log("error when setting new password on update password");
                props.setToastMenu({
                    status: true,
                    descText: err?.response?.data?.errorMessage,
                    headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                    isWarning : true
                })
                setLoading(false);
            })
    }

    return (
        <div className="dashboard-form-area">
            <DashboardSettingsHeader
                title={intl.formatMessage({id: "GENERATING_NEW_PASSWORD"})}
                icon={<BackIcon/>}
                iconOnClick={props.iconOnClick}
                desc={
                    <p className="widget-freights-desc-text mt-5 ml-2" style={{fontWeight: '500'}}>
                        {intl.formatMessage({id: "CREATE_PASSWORD_DESC"})}
                    </p>
                }
            />

            <div className="row mt-10 ml-5 position-relative">
                <PasswordValidationTexts
                    password={password}
                    setPassword={setPassword}
                    setPasswordRetry={setPassword}
                    setPasswordStrength={setPasswordStrength}
                />
                <div className="col-12" id="password-container">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id: "NEW_PASSWORD_INPUT"})} <span
                            style={{color: 'red'}}>*</span></p>
                    </div>
                    <DashboardInput
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        inputType={"password"}
                        maxLength={12}
                        placeholder={intl.formatMessage({id: "NEW_PASSWORD_INPUT_PLACEHOLDER"})}
                    />
                    <div className="mt-2">
                        <PasswordStrengthBar percentage={passwordStrength.toString()}/>
                    </div>
                </div>


            </div>





            <div className="row mt-10 ml-5">
                <div className="col-12" id="password-retry-container">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id:"CONFIRM_PASSWORD_INPUT"})} <span
                            style={{color: 'red'}}>*</span></p>
                    </div>
                    <DashboardInput
                        value={passwordRetry}
                        onChange={(e)=>setPasswordRetry(e.target.value)}
                        inputType={"password"}
                        maxLength={12}
                        placeholder={intl.formatMessage({id: "NEW_PASSWORD_INPUT_PLACEHOLDER"})}
                        error={passwordError}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-end mt-10 ml-5">
                <FormButton
                    icon={<ButtonArrowIcon/>}
                    title={intl.formatMessage({id: "NEXT_BUTTON"})}
                    isDisabled={loading}
                    isPassive={buttonDisable}
                    onClick={_passwordSubmit}
                />
            </div>

        </div>
    );
}

export default DashboardCreatePassword;
