import React from 'react';
import {DashboardDangerIcon} from "../../../_assets/icons";
import {useIntl} from "react-intl";

function EmailVerifyWidget(props) {
    const intl = useIntl();
    return (
        <div
            className={`card card-custom widget-container card-stretch-quarter card-stretch gutter-b justify-content-start`}
            style={{backgroundColor: '#FFA800',padding:'.5em 2.5em'}}>
            <div>
                <DashboardDangerIcon/>
            </div>
            <div className="mt-2">
                <h4 className="email-verification-widget-title">
                    {intl.formatMessage({id: "EMAIL_VERIFICATION_REQUIRED"})}
                </h4>
                <p className="email-verification-widget-desc mb-1">
                    {intl.formatMessage({id: "EMAIL_VERIFY_LINK_DESC"})}
                    <a href="/personal-data"
                       className="email-verification-widget-link">{intl.formatMessage({id: "VERIFY_NOW"})}</a>
                </p>
            </div>
        </div>
    );
}

export default EmailVerifyWidget;
