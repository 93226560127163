import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import {generateTotp} from "./generateTotp";
import {useSelector} from "react-redux";
import PinVerificationWidget from "../widgets/lists/PinVerificationWidget";
import {DashboardTimer} from "../../_assets/icons";
import {useIntl} from "react-intl";


Totp.propTypes = {
    from : propTypes.string.isRequired,
}

function Totp(props) {
    const intl = useIntl();
    const {user} = useSelector(state => state.auth);
    const {totp} = useSelector(state => state.auth);
    const [totpPin,setTotpPin] = useState("");
    const [secondLeft, setSecondLeft] = useState(0);
    const [timerDuration, setTimerDuration] = useState(30);

    const reformatPin = () => {
        let formattedPin = user.didbIdNumericpart.toString().match(/.{1,3}/g);
        return formattedPin.join(' ');
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const {token,period} = generateTotp(totp);
            const getCurrentTime = new Date(Date.now()).getSeconds();
            setSecondLeft(30-(getCurrentTime%30))
            if(totpPin !== token) {
                setTotpPin(token);
            } else if (period !== timerDuration) {
                setTimerDuration(period)
            } else {
                return;
            }
        }, 1000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [totpPin,secondLeft,totp,timerDuration]);





    return (
        <>
        {
            props.from === "dashboard" ?
                <div className="col-lg-12 col-xl-6">
                    <PinVerificationWidget didbIdNumericpart={reformatPin()} secondLeft={secondLeft} totp={totpPin} timerDuration={timerDuration} />
                </div> :
                <li className="current-pin-code mt-2 menu">
                    <div>
                        <p className="pin-code-text m-0">{intl.formatMessage({id:"CURRENT_PASSCODE"})}</p>
                        <p className="pin-code m-0">{reformatPin()} <span style={{color: '#FFA800'}}>{totpPin}</span></p>
                    </div>
                    <div style={{width: '20%'}}>
                        <DashboardTimer sec={secondLeft} fillColor={"#FFA800"} totalSec={timerDuration} />
                    </div>
                </li>
        }
        </>
    );
}

export default Totp;
