import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getDriverImage} from "../../../app/modules/Auth/_redux/authCrud";

function DashboardLeftMenu(props) {
    const {user} = useSelector(state => state.auth);
    const [driverPhoto, setDriverPhoto] = useState(null);

    const _getProfilePhoto = () => {
        getDriverImage()
            .then(({data}) => {
                setDriverPhoto(data.fileData);
            })
            .catch(err => console.log(err.response))
    }

    useEffect(_getProfilePhoto, []);
    return (
        <div className="col-lg-3">
            <div className="personal-data-profile-container">
                <img
                    src={`data:image/jpeg;base64,${driverPhoto}`}
                    alt="driver"
                    className="driverImage"
                    style={{display: driverPhoto ? 'block' : "none"}}
                />
                <h5 className="widget-header-text mt-4">{user.nameCardname}</h5>
            </div>
        </div>
    );
}

export default DashboardLeftMenu;
