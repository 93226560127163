import React, {useEffect, useState} from 'react';
import {MenuTimesIcon} from "../../_assets/icons";
import {useIntl} from "react-intl";

function DeclineModal(props) {
    const intl = useIntl();

    const [checkbox, setCheckbox] = useState(false);

    const _disablePageScroll = () => {
        document.querySelector("body").style.overflow = 'hidden';
    };

    const _closeModal = () => {
        document.querySelector("body").style.overflow = 'visible';
        props.closeModal();
    }

    useEffect(_disablePageScroll, []);


    return (
        <div className="dashboard-modal">
            <div className="agreement-modal-container">
                <div style={{position: 'absolute', top: '2em', right: '2em', cursor: 'pointer'}} onClick={_closeModal}>
                    <MenuTimesIcon/>
                </div>
                <h4 className="form-head-text">{intl.formatMessage({id: "ARE_YOU_SURE_DECLINE"})}</h4>
                <ul className="decline-list mt-10">
                    <li style={{fontSize: 24}}>
                        <span className="agreement-text">{intl.formatMessage({id:'YOUR_PROFILE_WILL_BE_DELETED'})}</span>
                    </li>
                    <li style={{fontSize: 24}}>
                        <span className="agreement-text">{intl.formatMessage({id:"IT_CANNOT_BE_UNDONE"})}</span>
                    </li>
                </ul>
                <div className="d-flex align-items-center mt-10">
                    <input
                        type="checkbox"
                        className="mr-3"
                        value={checkbox}
                        onChange={(e) => setCheckbox(e.target.checked)}
                        style={{height: '20px', width: '20px'}}
                    />
                    <span className="decline-confirm-text">{intl.formatMessage({id:"I_WANT_TO_REMOVE_MY_ACCOUNT"})}</span>
                </div>
                <div className="d-flex justify-content-end align-items-center mt-10 w-100">
                    <button
                        onClick={_closeModal}
                        className="create-account-button passive"
                        style={{
                            padding: '.8em 1.7em',
                            height: '50px'
                        }}>
                        {intl.formatMessage({id:"CANCEL"})}
                    </button>
                    <button
                        disabled={!checkbox}
                        onClick={props.declineAgreement}
                        className="btn btn-primary ml-3"
                        style={{
                            padding: '.8em .9em',
                            backgroundColor: '#E61010',
                            outline: 'none',
                            border: 'none',
                            height: '50px'
                        }}>
                        {intl.formatMessage({id:"YES_REMOVE_MY_ACCOUNT"})}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DeclineModal;
