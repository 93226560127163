import * as OTPAuth from 'otpauth';

export const generateTotp = (totpKey) => {
    if(!totpKey) {
        return;
    }
    let secretKey = totpKey.split("secret=")[totpKey.split("secret=").length-1];
    let parsedTotp = OTPAuth.URI.parse(totpKey);
    let totp = new OTPAuth.TOTP({
        issuer: 'ht devel totp',
        label: 'ht devel totp:user@example.net',
        algorithm: 'SHA1',
        digits: parsedTotp.digits,
        period: parsedTotp.period,
        secret: secretKey,
    });
    let token = totp.generate();
    return {
        token,
        period: parsedTotp.period
    };
};
