import React from 'react';
import propTypes from 'prop-types';

FormButton.propTypes = {
    isDisabled : propTypes.bool,
    isPassive : propTypes.bool,
    onClick : propTypes.func,
    title : propTypes.string,
    icon : propTypes.any,
    className : propTypes.string,
    buttonClassName : propTypes.string,
    contentClassName : propTypes.string
}


function FormButton(props) {
    return (
        <div className={"form-group d-flex flex-wrap justify-content-end align-items-center " + (props.className ? props.className : "")}>
            <button
                type="submit"
                className={`btn form-button ${!props.isPassive ? " btn-primary" : " btn-secondary"} ${props.buttonClassName ? props.buttonClassName : ""}`}
                onClick={props.onClick && props.onClick}
                disabled={props.isDisabled ? true : false}
            >
                <div className={`d-flex flex-center ${props.contentClassName ? props.contentClassName : ""}`}>
                    <span>{props.title}</span>
                    {
                        props.icon && props.icon
                    }
                </div>
            </button>
        </div>
    );
}

export default FormButton;
