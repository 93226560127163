import React, { useState } from "react";
import FormHeader from "./FormHeader";
import FormInput from "./FormInput";
import {
  BackIcon,
  ButtonArrowIcon,
  ButtonBackIcon
} from "../../../../_metronic/_assets/icons";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { useIntl } from "react-intl";
import FormButton from "./FormButton";
import CarrierRegisterPageStepIcons from "./CarrierRegisterPageStepIcons";
import VatInput from "./VATInput";
import TooltipComponent from "../../../../_metronic/_partials/Components/TooltipComponent";
import CarrierSelect from "./CarrierSelect";
import { ibanController } from "./InputController";
import PhoneInputComponent from "./PhoneInputComponent";
import { createNewPartner } from "../_redux/authCrud";
import { useLang } from "../../../../_metronic/i18n";

function CarrierRegistrationOverview(props) {
  const locale = useLang();
  const intl = useIntl();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [registrantErrors, setRegistrantErrors] = useState({});

  const _companyDataOnChange = e => {
    let newData = Object.assign({}, props.data);
    newData.companyData[e.target.name] = e.target.value;
    props.setData(newData);
  };

  const _contactDataOnChange = e => {
    const textRegex = /(([^0-9-!@$%^&*()_+|~#=`{}[/\]:";'<>?,.]))/g;
    const checkText = () => {
      // This functions checks is fields have text chars
      if (e.target.value[e.target.value.length - 1] === undefined) {
        deleteField();
        return;
      }
      if (e.target.value[e.target.value.length - 1].match(textRegex)) {
        return true;
      } else {
        return false;
      }
    };
    const deleteField = () => {
      let newData = Object.assign({}, props.data);
      newData.contactDetails[e.target.name] = "";
      props.setData(newData);
    };

    const updateFields = () => {
      let newData = Object.assign({}, props.data);
      newData.contactDetails[e.target.name] = e.target.value;
      props.setData(newData);
    };

    if (e.target.name === "name") {
      if (checkText()) updateFields();
    } else {
      updateFields();
    }
  };

  const _registrantOnChange = (e, id) => {
    const data = Object.assign({}, props.data);
    const newData = props.data.registrants.filter(item => {
      return item.id === id;
    });
    const leftData = props.data.registrants.filter(left => {
      return left.id !== id;
    });
    newData[0][e.target.name] = e.target.value;
    data.registrants = [newData[0], ...leftData];
    props.setData(data);
  };

  const registrantControl = () => {
    const regexString3Chars = /(([^0-9-!@$%^&*()_+|~#=`{}[/\]:";'<>?,])){3}/g; //Its accepts just strings and length equal or over than 5
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    if (props.data.registrants.length > 0) {
      let newErrors = {};
      for (let i = 0; i < props.data.registrants.length; i++) {
        let registrant = props.data.registrants[i];
        newErrors[registrant.id] = {
          name: { status: false, message: "" },
          position: { status: false, message: "" },
          email: { status: false, message: "" },
          countryCode: { status: false, message: "" },
          phoneNumber: { status: false, message: "" }
        };

        const _numberController = () => {
          const numberRegex = /[0-9]/g;
          if (
            registrant.phoneNumber?.length > 0 &&
            registrant?.phoneNumber?.match(numberRegex)?.length ===
              registrant?.phoneNumber?.length
          ) {
            return true;
          } else {
            return false;
          }
        };

        //Name Controller
        if (registrant.name.match(regexString3Chars)) {
          newErrors[registrant.id].name = { status: false, message: "" };
        } else {
          newErrors[registrant.id].name = {
            status: true,
            message: intl.formatMessage({ id: "NAME_ERROR" })
          };
        }

        //Email Controller
        if (registrant.email.match(emailRegex)) {
          newErrors[registrant.id].email = { status: false, message: "" };
        } else {
          newErrors[registrant.id].email = {
            status: true,
            message: intl.formatMessage({ id: "EMAIL_ERROR" })
          };
        }

        //Position Controller
        if (registrant.position !== "" && registrant.position.label !== "") {
          newErrors[registrant.id].position = { status: false, message: "" };
        } else {
          newErrors[registrant.id].position = {
            status: true,
            message: intl.formatMessage({ id: "SELECT_ERROR" })
          };
        }

        //Phone Controller
        if (_numberController() && registrant.phoneNumber.length > 0) {
          newErrors[registrant.id].phoneNumber = { status: false, message: "" };
        } else if (!_numberController()) {
          newErrors[registrant.id].phoneNumber = {
            status: true,
            message: intl.formatMessage({ id: "PHONE_ERROR" })
          };
        }

        //Country Code Controller
        if (registrant.countryCode.label === "") {
          newErrors[registrant.id].countryCode = {
            status: true,
            message: intl.formatMessage({ id: "SELECT_ERROR" })
          };
        } else {
          newErrors[registrant.id].countryCode = { status: false, message: "" };
        }
      }
      setRegistrantErrors(newErrors);
      for (let a = 0; a < Object.keys(newErrors).length; a++) {
        if (
          !Object.values(newErrors)[a].name.status &&
          !Object.values(newErrors)[a].position.status &&
          !Object.values(newErrors)[a].email.status &&
          !Object.values(newErrors)[a].countryCode.status &&
          !Object.values(newErrors)[a].phoneNumber.status
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  const _finalizeForm = () => {
    if (props.pageController(0) && props.pageController(1)) {
      if (registrantControl()) {
        let IbanNumber = props.data.companyData.ibanNumber.split(" ");

        let postData = {
          partner: {
            partnertype:
              props.data.companyData.companyType === "both"
                ? ["lsp", "freight"]
                : props.data.companyData.companyType === "carrier"
                ? ["freight"]
                : ["lsp"],
            name: props.data.companyData.companyName,
            registrationNumber: props.data.companyData.registrationNumber,
            billingTaxnumber:
              props.data.companyData.VATCountry.value +
              props.data.companyData.VATNumber,
            addressCountryKey: props.data.companyData.country.value,
            addressZipcode: props.data.companyData.zipCode,
            addressCity: props.data.companyData.city,
            addressAddress: props.data.companyData.address,
            bankIban: IbanNumber.join(""),
            headofficeEmail: props.data.companyData.centralEmail,
            invoicingEmail: props.data.companyData.invoiceEmail,
            billingPartnerDiffers:
              props.data.companyData.payment === "byUs" ? 0 : 1,
            billingPartnerNote:
              props.data.companyData.payment === "byAnotherCompany"
                ? props.data.companyData.invoicePayerCompany
                : ""
          },
          mainContact: {
            name: props.data.contactDetails.name,
            contactPositionId: props.data.contactDetails.position.value,
            email: props.data.contactDetails.email,
            phonenumber: props.data.contactDetails.phoneNumber,
            phoneprefix: props.data.contactDetails.countryCode.value
          },
          contacts: [
            {
              name: props.data.companyData.nameRepresentative,
              contactPositionText:
                props.data.companyData.positionRepresentative
            }
          ],
          users: []
        };

        for (let i = 0; i < props.data.registrants.length; i++) {
          let newRegistrant = {};
          newRegistrant.name = props.data.registrants[i].name;
          newRegistrant.contactPositionId =
            props.data.registrants[i].position.value;
          newRegistrant.email = props.data.registrants[i].email;
          newRegistrant.phoneprefix =
            props.data.registrants[i].countryCode.value;
          newRegistrant.phonenumber = props.data.registrants[i].phoneNumber;
          postData.users.push(newRegistrant);
        }

        if (props.data.companyData.isPostalAddressDifferent) {
          postData.partner.mailaddressCountryKey =
            props.data.companyData.postalAddressCountry.value;
          postData.partner.mailaddressZipcode =
            props.data.companyData.postalAddressZipCode;
          postData.partner.mailaddressCity =
            props.data.companyData.postalAddressCity;
          postData.partner.mailaddressAddress =
            props.data.companyData.postalAddressAddress;
        }

        createNewPartner(postData)
          .then(() => {
            props.changePage();
          })
          .catch(err => {
            console.log("error happen when registering new partner");
            props.setToastError({
              status: true,
              message: err?.response?.data?.errorMessage
            });
          });
      }
    }
  };

  return (
    <div
      className="d-flex flex-column flex-row-fluid position-relative form-area"
      style={{ overflowY: "auto" }}
    >
      <div className="d-flex flex-column-fluid align-items-stretch justify-content-start">
        <div className="login-form login-signin d-flex align-items-stretch justify-content-start flex-column mt-10">
          <div className="mb-10">
            <FormHeader
              title={intl.formatMessage({ id: "CARRIER_REGISTER_FORM_TITLE" })}
              desc={
                <p className="form-head-desc">
                  {intl.formatMessage({
                    id: "CARRIER_REGISTRATION_PAGE_OVERVIEW_DESC"
                  })}
                </p>
              }
              icon={<BackIcon />}
              iconOnClick={props.iconOnClick}
            />
            <div
              style={{ justifySelf: "flex-start" }}
              id="registration-page-div"
            >
              <CarrierRegisterPageStepIcons step={3} />
            </div>
          </div>
          <div className="container p-0">
            <h5 className="form-title">
              {intl.formatMessage({ id: "COMPANY_DATA" })}
            </h5>
            <hr className="mb-10" />
            <div className="row mb-10">
              <div className="col-md-12 mb-10">
                <div className="mb-5">
                  <span className="form-title light">
                    {intl.formatMessage({ id: "COMPANY_TYPE" })}
                  </span>
                  <span style={{ color: "red", marginLeft: ".5em" }}>*</span>
                </div>
                <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <div className="d-flex form-control-text flex-center">
                    <div className="checkbox-inline">
                      <label className="checkbox checkbox-primary">
                        <input
                          type="checkbox"
                          checked={
                            props.data.companyData.companyType === "carrier"
                              ? true
                              : false
                          }
                          onChange={e => {
                            e.target.checked
                              ? _companyDataOnChange({
                                  target: {
                                    name: "companyType",
                                    value: "carrier"
                                  }
                                })
                              : _companyDataOnChange({
                                  target: {
                                    name: "companyType",
                                    value: ""
                                  }
                                });
                          }}
                        />
                        <span className="ml-2"></span>
                        {intl.formatMessage({ id: "CARRIER_COMPANY" })}
                      </label>
                    </div>
                    <TooltipComponent
                      tooltipText={intl.formatMessage({
                        id: "CARRIER_COMPANY_TOOLTIP"
                      })}
                    />
                  </div>

                  <div className="d-flex form-control-text flex-center">
                    <div className="checkbox-inline">
                      <label className="checkbox checkbox-primary">
                        <input
                          type="checkbox"
                          checked={
                            props.data.companyData.companyType === "lsp"
                              ? true
                              : false
                          }
                          onChange={e => {
                            e.target.checked
                              ? _companyDataOnChange({
                                  target: {
                                    name: "companyType",
                                    value: "lsp"
                                  }
                                })
                              : _companyDataOnChange({
                                  target: {
                                    name: "companyType",
                                    value: ""
                                  }
                                });
                          }}
                        />
                        <span className="ml-2"></span>
                        {intl.formatMessage({ id: "LSP_COMPANY" })}
                      </label>
                    </div>
                    <TooltipComponent
                      tooltipText={intl.formatMessage({
                        id: "LSP_COMPANY_TOOLTIP"
                      })}
                    />
                  </div>

                  <div className="d-flex form-control-text flex-center">
                    <div className="checkbox-inline">
                      <label className="checkbox checkbox-primary">
                        <input
                          type="checkbox"
                          checked={
                            props.data.companyData.companyType === "both"
                              ? true
                              : false
                          }
                          onChange={e => {
                            e.target.checked
                              ? _companyDataOnChange({
                                  target: {
                                    name: "companyType",
                                    value: "both"
                                  }
                                })
                              : _companyDataOnChange({
                                  target: {
                                    name: "companyType",
                                    value: ""
                                  }
                                });
                          }}
                        />
                        <span className="ml-2"></span>
                        {intl.formatMessage({ id: "BOTH" })}
                      </label>
                    </div>
                    <TooltipComponent
                      tooltipText={intl.formatMessage({
                        id: "BOTH_COMPANY_TOOLTIP"
                      })}
                    />
                  </div>
                </div>
                {props.errors.companyData.companyType.status && (
                  <div className="form-error-text mt-2 mb-0">
                    {props.errors.companyData.companyType.message}
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <FormInput
                  title={intl.formatMessage({ id: "COMPANY_NAME" })}
                  inputType={"text"}
                  inputName={"companyName"}
                  placeHolder={intl.formatMessage({ id: "COMPANY_NAME" })}
                  isRequired
                  error={props.errors.companyData.companyName}
                  value={props.data.companyData.companyName}
                  onChange={e => _companyDataOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-6">
                <FormInput
                  title={intl.formatMessage({ id: "CENTRAL_EMAIL" })}
                  inputType={"text"}
                  inputName={"centralEmail"}
                  placeHolder={intl.formatMessage({ id: "CENTRAL_EMAIL" })}
                  questionMark
                  isRequired
                  toolTipTitle={intl.formatMessage({
                    id: "CENTRAL_EMAIL_TOOLTIP"
                  })}
                  error={props.errors.companyData.centralEmail}
                  value={props.data.companyData.centralEmail}
                  onChange={e => _companyDataOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-6">
                <FormInput
                  title={intl.formatMessage({
                    id: "COMPANY_REGISTRATION_NUMBER"
                  })}
                  inputType={"text"}
                  inputName={"registrationNumber"}
                  placeHolder={intl.formatMessage({
                    id: "COMPANY_REGISTRATION_NUMBER"
                  })}
                  isRequired
                  questionMark
                  toolTipTitle={intl.formatMessage({
                    id: "REGISTRATION_NUMBER_TOOLTIP"
                  })}
                  error={props.errors.companyData.registrationNumber}
                  value={props.data.companyData.registrationNumber}
                  onChange={e => _companyDataOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-6">
                <VatInput
                  title={intl.formatMessage({ id: "VAT_NUMBER" })}
                  inputType={"text"}
                  inputName={"VATNumber"}
                  placeHolder={intl.formatMessage({ id: "VAT_NUMBER" })}
                  isRequired
                  questionMark
                  error={props.errors.companyData.VATNumber}
                  selectError={props.errors.companyData.VATCountry}
                  countryValue={props.data.companyData.VATCountry}
                  value={props.data.companyData.VATNumber}
                  onChange={_companyDataOnChange}
                  headerClassName={"light"}
                  onCountryChange={country =>
                    _companyDataOnChange({
                      target: {
                        value: country,
                        name: "VATCountry"
                      }
                    })
                  }
                />
              </div>
              <div className="col-md-6">
                <FormInput
                  title={intl.formatMessage({ id: "REPRESENTATIVE_NAME" })}
                  inputType={"text"}
                  inputName={"nameRepresentative"}
                  placeHolder={intl.formatMessage({
                    id: "REPRESENTATIVE_NAME"
                  })}
                  isRequired
                  error={props.errors.companyData.nameRepresentative}
                  value={props.data.companyData.nameRepresentative}
                  onChange={e => _companyDataOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-6">
                <FormInput
                    title={intl.formatMessage({id: "REPRESENTATIVE_POSITION"})}
                    inputType={"text"}
                    inputName={"positionRepresentative"}
                    value={props.data.companyData.positionRepresentative}
                    onChange={(e) => _companyDataOnChange(e)}
                    error={props.errors.positionRepresentative}
                    placeHolder={intl.formatMessage({id: "REPRESENTATIVE_POSITION"})}
                    headerClassName={"light"}
                />
              </div>
              <div className="col-md-4">
                <CarrierSelect
                  title={intl.formatMessage({ id: "COUNTRY" })}
                  options={props.allCountries}
                  value={props.data.companyData.country}
                  onChange={country =>
                    _companyDataOnChange({
                      target: {
                        name: "country",
                        value: country
                      }
                    })
                  }
                  placeholder={intl.formatMessage({ id: "PLEASE_SELECT" })}
                  error={props.errors.companyData.country}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  title={intl.formatMessage({ id: "ZIP_CODE" })}
                  inputType={"text"}
                  inputName={"zipCode"}
                  placeHolder={intl.formatMessage({ id: "ZIP_CODE" })}
                  isRequired
                  error={props.errors.companyData.zipCode}
                  value={props.data.companyData.zipCode}
                  onChange={e => _companyDataOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  title={intl.formatMessage({ id: "CITY" })}
                  inputType={"text"}
                  inputName={"city"}
                  placeHolder={intl.formatMessage({ id: "CITY" })}
                  isRequired
                  error={props.errors.companyData.city}
                  value={props.data.companyData.city}
                  onChange={e => _companyDataOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-12">
                <FormInput
                  title={intl.formatMessage({ id: "ADDRESS" })}
                  inputType={"text"}
                  inputName={"address"}
                  placeHolder={intl.formatMessage({ id: "ADDRESS" })}
                  isRequired
                  error={props.errors.companyData.address}
                  value={props.data.companyData.address}
                  onChange={e => _companyDataOnChange(e)}
                  headerClassName={"light"}
                />
              </div>

              <div className="col-md-12 mb-3">
                <div className="checkbox-inline">
                  <label className="checkbox checkbox-primary">
                    <input
                      type="checkbox"
                      checked={props.data.companyData.isPostalAddressDifferent}
                      onChange={e => {
                        _companyDataOnChange({
                          target: {
                            name: "isPostalAddressDifferent",
                            value: e.target.checked
                          }
                        });
                      }}
                    />
                    <span className="ml-0"></span>
                    <div className="mb-0 form-title light">
                      <span
                        style={{
                          fontWeight: "400",
                          marginRight: 0
                        }}
                      >
                        {intl.formatMessage({
                          id: "POSTAL_ADDRESS_DIFFERENT_CHECKBOX"
                        })}
                      </span>
                    </div>
                  </label>
                </div>
              </div>

              {props.data.companyData.isPostalAddressDifferent && (
                <>
                  <div className="col-md-4">
                    <CarrierSelect
                      title={intl.formatMessage({ id: "POSTAL_COUNTRY" })}
                      options={props.allCountries}
                      value={props.data.companyData.postalAddressCountry}
                      onChange={country =>
                        _companyDataOnChange({
                          target: {
                            name: "postalAddressCountry",
                            value: country
                          }
                        })
                      }
                      placeholder={intl.formatMessage({ id: "PLEASE_SELECT" })}
                      error={props.errors.companyData.postalAddressCountry}
                      headerClassName={"light"}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormInput
                      title={intl.formatMessage({ id: "POSTAL_ZIP_CODE" })}
                      inputType={"text"}
                      inputName={"postalAddressZipCode"}
                      placeHolder={intl.formatMessage({
                        id: "POSTAL_ZIP_CODE"
                      })}
                      isRequired
                      error={props.errors.companyData.postalAddressZipCode}
                      value={props.data.companyData.postalAddressZipCode}
                      onChange={e => _companyDataOnChange(e)}
                      headerClassName={"light"}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormInput
                      title={intl.formatMessage({ id: "POSTAL_CITY" })}
                      inputType={"text"}
                      inputName={"postalAddressCity"}
                      placeHolder={intl.formatMessage({ id: "POSTAL_CITY" })}
                      isRequired
                      error={props.errors.companyData.postalAddressCity}
                      value={props.data.companyData.postalAddressCity}
                      onChange={e => _companyDataOnChange(e)}
                      headerClassName={"light"}
                    />
                  </div>
                  <div className="col-md-12">
                    <FormInput
                      title={intl.formatMessage({ id: "POSTAL_ADDRESS" })}
                      inputType={"text"}
                      inputName={"postalAddressAddress"}
                      placeHolder={intl.formatMessage({ id: "POSTAL_ADDRESS" })}
                      isRequired
                      error={props.errors.companyData.postalAddressAddress}
                      value={props.data.companyData.postalAddressAddress}
                      onChange={e => _companyDataOnChange(e)}
                      headerClassName={"light"}
                    />
                  </div>
                </>
              )}

              <div className="col-md-12">
                <FormInput
                  title={intl.formatMessage({ id: "IBAN_NUMBER" })}
                  inputType={"text"}
                  inputName={"ibanNumber"}
                  placeHolder={intl.formatMessage({ id: "IBAN_NUMBER" })}
                  isRequired
                  error={props.errors.companyData.ibanNumber}
                  value={props.data.companyData.ibanNumber}
                  onChange={e =>
                    ibanController(e.target.value, iban =>
                      _companyDataOnChange({
                        target: {
                          name: "ibanNumber",
                          value: iban
                        }
                      })
                    )
                  }
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-12">
                <FormInput
                  title={intl.formatMessage({ id: "EMAIL_INVOICING" })}
                  inputType={"text"}
                  inputName={"invoiceEmail"}
                  isRequired
                  placeHolder={intl.formatMessage({ id: "EMAIL_INVOICING" })}
                  questionMark
                  toolTipTitle={intl.formatMessage({
                    id: "EMAIL_INVOICING_TOOLTIP"
                  })}
                  error={props.errors.companyData.invoiceEmail}
                  value={props.data.companyData.invoiceEmail}
                  onChange={e => _companyDataOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-12">
                <div className="d-flex flex-wrap">
                  <div className="d-flex form-title flex-center">
                    <div className="checkbox-inline">
                      <label className="checkbox checkbox-primary">
                        <input
                          type="checkbox"
                          checked={
                            props.data.companyData.payment === "byUs"
                              ? true
                              : false
                          }
                          onChange={e => {
                            e.target.checked
                              ? _companyDataOnChange({
                                  target: {
                                    name: "payment",
                                    value: "byUs"
                                  }
                                })
                              : _companyDataOnChange({
                                  target: {
                                    name: "payment",
                                    value: ""
                                  }
                                });
                          }}
                        />
                        <span className="ml-0"></span>
                        <div className="mb-0 form-title light">
                          <span
                            style={{
                              fontWeight: "400",
                              marginRight: 0
                            }}
                          >
                            {intl.formatMessage({
                              id: "REGISTRATION_SERVICES_WILL_BE"
                            })}
                          </span>
                          <span>
                            {intl.formatMessage({ id: "PAID_BY_US" })}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex flex-center">
                    <div className="checkbox-inline">
                      <label className="checkbox checkbox-primary">
                        <input
                          type="checkbox"
                          checked={
                            props.data.companyData.payment ===
                            "byAnotherCompany"
                              ? true
                              : false
                          }
                          onChange={e => {
                            e.target.checked
                              ? _companyDataOnChange({
                                  target: {
                                    name: "payment",
                                    value: "byAnotherCompany"
                                  }
                                })
                              : _companyDataOnChange({
                                  target: {
                                    name: "payment",
                                    value: ""
                                  }
                                });
                          }}
                        />
                        <span className="ml-0"></span>
                        <div className="mb-0 form-title light">
                          <span
                            style={{
                              fontWeight: "400",
                              marginRight: 0
                            }}
                          >
                            {intl.formatMessage({
                              id: "REGISTRATION_SERVICES_WILL_BE"
                            })}
                          </span>
                          <span>
                            {intl.formatMessage({
                              id: "PAID_BY_ANOTHER_COMPANY"
                            })}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                {props.errors.companyData.payment.status && (
                  <div className="form-error-text mt-2 mb-0">
                    {props.errors.companyData.payment.message}
                  </div>
                )}
              </div>
              {props.data.companyData.payment === "byAnotherCompany" && (
                <div className="col-md-12">
                  <FormInput
                    title={intl.formatMessage({ id: "INVOICE_PAYER_COMPANY" })}
                    inputType={"text"}
                    inputName={"invoicePayerCompany"}
                    isRequired
                    placeHolder={intl.formatMessage({
                      id: "INVOICE_PAYER_COMPANY"
                    })}
                    error={props.errors.companyData.invoicePayerCompany}
                    value={props.data.companyData.invoicePayerCompany}
                    onChange={e => _companyDataOnChange(e)}
                    headerClassName={"light"}
                  />
                </div>
              )}
            </div>

            <h5 className="form-title">
              {intl.formatMessage({ id: "PERSONAL_CONTACT_DETAILS" })}
            </h5>
            <hr className="mb-10" />
            <div className="row mb-10">
              <div className="col-md-6">
                <FormInput
                  title={intl.formatMessage({ id: "YOUR_NAME" })}
                  inputType={"text"}
                  inputName={"name"}
                  placeHolder={intl.formatMessage({ id: "YOUR_NAME" })}
                  isRequired
                  error={props.errors.contactDetails.name}
                  value={props.data.contactDetails.name}
                  onChange={e => _contactDataOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-6">
                <CarrierSelect
                  title={intl.formatMessage({ id: "YOUR_POSITION" })}
                  options={props.allPositions}
                  value={props.data.contactDetails.position}
                  onChange={position =>
                    _contactDataOnChange({
                      target: {
                        value: position,
                        name: "position"
                      }
                    })
                  }
                  error={props.errors.contactDetails.position}
                  placeholder={intl.formatMessage({ id: "PLEASE_SELECT" })}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-12">
                <FormInput
                  title={intl.formatMessage({ id: "YOUR_EMAIL_ADDRESS" })}
                  inputType={"email"}
                  inputName={"email"}
                  placeHolder={intl.formatMessage({ id: "YOUR_EMAIL_ADDRESS" })}
                  isRequired
                  error={props.errors.contactDetails.email}
                  value={props.data.contactDetails.email}
                  onChange={e => _contactDataOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <PhoneInputComponent
                title={intl.formatMessage({ id: "PHONE_NUMBER" })}
                value={props.data.contactDetails.phoneNumber}
                error={props.errors.contactDetails.phoneNumber}
                countryCodeError={props.errors.contactDetails.countryCode}
                countryCode={props.data.contactDetails.countryCode}
                setCountryCode={countryCode =>
                  _contactDataOnChange({
                    target: {
                      name: "countryCode",
                      value: countryCode
                    }
                  })
                }
                onChange={phone =>
                  _contactDataOnChange({
                    target: {
                      name: "phoneNumber",
                      value: phone
                    }
                  })
                }
                headerClassName={"light"}
              />
            </div>
            <h5 className="form-title">
              {intl.formatMessage({ id: "REGISTRANTS" })}
            </h5>
            <hr className="mb-10" />

            {props.data.registrants
              .sort(function(a, b) {
                return -(a.id - b.id);
              })
              .map((registrant, index) => (
                <div key={index}>
                  <h5 className="form-title mb-5">
                    {intl.formatMessage({ id: "REGISTRANT" })} #{index + 1}
                  </h5>
                  <div className="row mb-10">
                    <div className="col-md-6">
                      <FormInput
                        title={intl.formatMessage({ id: "NAME" })}
                        inputType={"text"}
                        inputName={"name"}
                        placeHolder={intl.formatMessage({ id: "NAME" })}
                        isRequired
                        error={
                          registrantErrors[registrant.id]
                            ? registrantErrors[registrant.id].name
                            : {
                                status: false,
                                message: ""
                              }
                        }
                        value={registrant.name}
                        onChange={e => _registrantOnChange(e, registrant.id)}
                        headerClassName={"light"}
                      />
                    </div>
                    <div className="col-md-6">
                      <CarrierSelect
                        title={intl.formatMessage({ id: "POSITION" })}
                        options={props.allPositions}
                        value={registrant.position}
                        onChange={position =>
                          _registrantOnChange(
                            {
                              target: {
                                value: position,
                                name: "position"
                              }
                            },
                            registrant.id
                          )
                        }
                        error={
                          registrantErrors[registrant.id]
                            ? registrantErrors[registrant.id].position
                            : {
                                status: false,
                                message: ""
                              }
                        }
                        placeholder={intl.formatMessage({
                          id: "PLEASE_SELECT"
                        })}
                        headerClassName={"light"}
                      />
                    </div>
                    <div className="col-md-12">
                      <FormInput
                        title={intl.formatMessage({ id: "EMAIL" })}
                        inputType={"text"}
                        inputName={"email"}
                        placeHolder={intl.formatMessage({ id: "EMAIL" })}
                        isRequired
                        error={
                          registrantErrors[registrant.id]
                            ? registrantErrors[registrant.id]
                            : {
                                status: false,
                                message: ""
                              }
                        }
                        value={registrant.email}
                        onChange={e => _registrantOnChange(e, registrant.id)}
                        headerClassName={"light"}
                      />
                    </div>
                    <PhoneInputComponent
                      title={intl.formatMessage({ id: "PHONE_NUMBER" })}
                      value={registrant.phoneNumber}
                      phonePrefixes={props.phonePrefixes}
                      error={
                        registrantErrors[registrant.id]
                          ? registrantErrors[registrant.id].phoneNumber
                          : {
                              status: false,
                              message: ""
                            }
                      }
                      countryCodeError={
                        registrantErrors[registrant.id]
                          ? registrantErrors[registrant.id].countryCode
                          : {
                              status: false,
                              message: ""
                            }
                      }
                      countryCode={registrant.countryCode}
                      setCountryCode={countryCode =>
                        _registrantOnChange(
                          {
                            target: {
                              name: "countryCode",
                              value: countryCode
                            }
                          },
                          registrant.id
                        )
                      }
                      onChange={phone =>
                        _registrantOnChange(
                          {
                            target: {
                              name: "phoneNumber",
                              value: phone
                            }
                          },
                          registrant.id
                        )
                      }
                      headerClassName={"light"}
                    />
                  </div>
                </div>
              ))}

            <div className="mb-10 d-flex align-items-center">
              <div className="checkbox-inline">
                <label className="checkbox checkbox-primary mr-0">
                  <input
                    type="checkbox"
                    onChange={e => {
                      e.target.checked
                        ? setButtonDisabled(false)
                        : setButtonDisabled(true);
                    }}
                    className="mr-3"
                  />
                  <span className="ml-2"></span>
                </label>
                  <span
                      className="link-text"
                      onClick={() =>
                          window.open(
                              locale === "hu"
                                  ? "https://carrier.didb.eu/wp-content/uploads/2023/02/Adatkezelesi_tajekozato_honlap_202212_links.pdf"
                                  : "https://carrier.didb.eu/wp-content/uploads/2023/02/Privacy_Policy_websites_2023_EN-002_link.pdf",
                              "_blank"
                          )
                      }
                  >
                    {intl.formatMessage({ id: "I_HAVE_READ_TERMS_CONDITIONS" })}
                  </span>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <FormButton
              icon={<ButtonBackIcon />}
              title={intl.formatMessage({ id: "BACK" })}
              onClick={props.iconOnClick}
              contentClassName={"flex-row-reverse"}
            />
            <FormButton
              title={intl.formatMessage({ id: "FINALIZE" })}
              icon={<ButtonArrowIcon />}
              onClick={_finalizeForm}
              isDisabled={buttonDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(null, auth.actions)(CarrierRegistrationOverview)
);
