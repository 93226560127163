import React, {useState} from "react";
import DashboardModal from "./DashboardModal";
import LogoInformationWidget from "../widgets/lists/LogoInformationWidget";
import DocumentsWidget from "../widgets/lists/DocumentsWidget";
import {
    DashboardLicenseIcon,
    DashboardNationalIdIcon,
    DashboardPassportIcon,
    DashboardWarningTimesIcon
} from "../../_assets/icons";
//import FreightsWidget from "../widgets/lists/FreightsWidget";
import EmailVerifyWidget from "../widgets/lists/EmailVerifyWidget";
import ProfileInformationWidget from "../widgets/lists/ProfileInformationWidget";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Totp from "../Components/Totp";
import moment from "moment";

function Demo1Dashboard() {
    const intl = useIntl();
    const [modal, setModal] = useState(false);
    const [agreementWarning, setAgreementWarning] = useState(true);
    const {user} = useSelector(state => state.auth);

    const calculateDateLeft = () => {
        const updatedValue = moment(user?.membershipDeclarationValid, "YYYY-MM-DD");
        if (!moment(user?.membershipDeclarationValid, "YYYY-MM-DD").isValid()) {
            return null;
        }
        return Math.ceil(Math.abs(moment.duration(moment().diff(updatedValue)).asDays())).toString();
    };

    return (
        <div className="row">
            {modal && <DashboardModal closeModal={() => setModal(false)}/>}

            {agreementWarning && user?.membershipDeclarationStatus === "waiting" && (
                <div className="col-12 dashboard-warning mb-5 position-relative">
                    <p className="dashboard-warning-title mb-0">
                        {intl.formatMessage({id: "AGREEMENT_WARNING_TITLE"})}
                    </p>
                    <p className="dashboard-warning-desc mb-0">
                        {intl.formatMessage(
                            {id: "AGREEMENT_WARNING_DESC"},
                            {days: calculateDateLeft()}
                        )}
                        <Link to="/settings/agreement" className="more-info-text">
                            {intl.formatMessage({id: "MORE_INFO"})}
                        </Link>
                    </p>
                    <div
                        style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            cursor: "pointer"
                        }}
                        onClick={() => setAgreementWarning(false)}
                    >
                        <DashboardWarningTimesIcon/>
                    </div>
                </div>
            )}

            <div className="col-md-6 col-xl-8">
                <div className="row">
                    <div className="col-lg-12 col-xl-6">
                        <LogoInformationWidget
                            driverName={user.nameCardname}
                            driverCode={user.didbId}
                            expiryDate={user.membershipValid}
                            status={user.membershipValidStatus}
                            statusDisplay={user.membershipValidStatusDisplay.toUpperCase()}
                        />
                    </div>

                    <div className="col-lg-12 col-xl-6">
                        <DocumentsWidget
                            documents={[
                                {
                                    icon: <DashboardLicenseIcon/>,
                                    title: "Driving License",
                                    documentNo: user.documents.drivinglicense.number,
                                    expiryDate: user.documents.drivinglicense.expiry
                                },
                                {
                                    icon: <DashboardPassportIcon/>,
                                    title: "Passport",
                                    documentNo: user.documents.passport.number,
                                    expiryDate: user.documents.passport.expiry
                                },
                                {
                                    icon: <DashboardNationalIdIcon/>,
                                    title: "National ID",
                                    documentNo: user.documents.idcard.number,
                                    expiryDate: user.documents.idcard.expiry
                                }
                            ]}
                        />
                    </div>

                    {!user.contactinfo.emailVerified && (
                        <div className="col-lg-12 col-xl-6">
                            <EmailVerifyWidget/>
                        </div>
                    )}

                    {/*
                        FREIGHTS WIDGET COMMENTED ON MVP VERSION

                        <div className="col-lg-12 col-xl-6">
                        <FreightsWidget
                            freights={[
                                {
                                    hour: "08.42",
                                    color: '#663259',
                                    title: "Pickup from Newyork city"
                                },
                                {
                                    hour: "10.00",
                                    color: '#1BC5BD',
                                    title: "Drop-off at California"
                                },
                                {
                                    hour: "14.37",
                                    color: '#F64E60',
                                    title: "Make deposit USD 700 to ESL"
                                }
                            ]}
                        />
                    </div>
                         */}

                    <Totp from={"dashboard"}/>
                </div>
            </div>

            <div className="col-md-6 col-xl-4">
                <ProfileInformationWidget
                    name={user.nameCardname}
                    country={user.citizenship}
                    dateOfBirth={user.birthDate}
                    countryOfBirth={user.birthCountry}
                    cityOfBirth={user.birthCity}
                    mothersName={user.parentNameMother}
                    email={user.contactinfo.email || "email@email.com"}
                    phoneNumber={
                        user.contactinfo.phoneprefix + " " + user.contactinfo.phonenumber
                    }
                />
            </div>
        </div>
    );
}

export default Demo1Dashboard;
