import React from "react";
import propTypes from "prop-types";
import {Logo} from "../../../../_metronic/_assets/icons";
import LanguageDropdown from "./LanguageDropdown";
import accountSelector from "../../../../_metronic/_assets/img/accountSelector.png";
import idInformation from "../../../../_metronic/_assets/img/idInformation.png";
import phoneVerification from "../../../../_metronic/_assets/img/phoneVerification.png";
import generatingPassword from "../../../../_metronic/_assets/img/generatingPassword.png";
import securityQuestion from "../../../../_metronic/_assets/img/securityQuestion.png";
import carrierCompanyData from "../../../../_metronic/_assets/img/carrierCompanyData.png";
import companyContactInformation from "../../../../_metronic/_assets/img/companyContactInformation.png";
import carrierRegistrantsData from "../../../../_metronic/_assets/img/carrierRegistrantsData.png";
import carrierOverview from "../../../../_metronic/_assets/img/carrierOverview.png";

function LeftMenu(props) {
    return (
        <div
            id={"menu"}
            className="login-aside d-none d-lg-flex flex-column align-items-stretch justify-content-between"
        >
            <div className={`d-flex align-items-center ${props?.logoDisabled ? "justify-content-end" : "justify-content-between"}`}>
                {
                    !props?.logoDisabled &&
                    <Logo/>
                }
                {props.languageMenu && (
                    <LanguageDropdown langs={props.availableLangs}/>
                )}
            </div>
            <div className="d-flex justify-content-end align-items-center">
                {props?.imageType === "accountSelector" || props?.imageType === "login" ? (
                    <img
                        src={accountSelector}
                        alt="login_image"
                        style={{width: "100%", height: "100%", paddingBottom: "15vh"}}
                    />
                ) : props?.imageType === "idInformation" ? (
                    <img
                        src={idInformation}
                        alt="idInformation"
                        style={{width: "100%", height: "100%", paddingBottom: "15vh"}}
                    />
                ) : props?.imageType === "phoneVerification" ? (
                    <img
                        src={phoneVerification}
                        alt="phoneVerification"
                        style={{width: "100%", height: "100%", paddingBottom: "15vh"}}
                    />
                ) : props?.imageType === "generatingPassword" ? (
                    <img
                        src={generatingPassword}
                        alt="generatingPassword"
                        style={{width: "100%", height: "100%", paddingBottom: "15vh"}}
                    />
                ) : props?.imageType === "securityQuestion" ? (
                    <img
                        src={securityQuestion}
                        alt="securityQuestion"
                        style={{width: "100%", height: "100%", paddingBottom: "15vh"}}
                    />
                ) : props?.imageType === "carrierCompanyData" ? (
                    <img
                        src={carrierCompanyData}
                        alt="carrierCompanyData"
                        style={{width: "100%", height: "100%", paddingBottom: "15vh"}}
                    />
                ) : props?.imageType === "companyContactInformation" ? (
                    <img
                        src={companyContactInformation}
                        alt="companyContactInformation"
                        style={{width: "100%", height: "100%", paddingBottom: "15vh"}}
                    />
                ) : props?.imageType === "carrierRegistrantsData" ? (
                    <img
                        src={carrierRegistrantsData}
                        alt="carrierRegistrantsData"
                        style={{width: "100%", height: "100%", paddingBottom: "15vh"}}
                    />
                ) :  props?.imageType === "carrierOverview" ? (
                    <img
                        src={carrierOverview}
                        alt="carrierOverview"
                        style={{width: "100%", height: "100%", paddingBottom: "15vh"}}
                    />
                ) : (
                    <img
                        src={accountSelector}
                        alt="login_image"
                        style={{width: "100%", height: "100%", paddingBottom: "15vh"}}
                    />
                )}
            </div>
        </div>
    );
}

LeftMenu.propTypes = {
    languageMenu: propTypes.bool,
    availableLangs: propTypes.array,
    imageType: propTypes.string
};

export default LeftMenu;
