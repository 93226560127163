import React from 'react';
import DashboardFormHeader from "../Components/DashboardFormHeader";
import DashboardLeftMenu from "../Components/DashboardLeftMenu";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

function Documents() {
    const intl = useIntl();
    const {user} = useSelector(state => state.auth);
    const documentNoFormatter = (docNo) => {
        if(docNo) {
            let formattedDocumentNo = docNo.match(/.{1,4}/g);
            return formattedDocumentNo.join(' ');
        } else {
            return "";
        }
    }

    return (
        <div className="bg-white w-100 h-100 card card-custom">

            <div className="row h-100">

                <DashboardLeftMenu/>

                <div className="col-lg-9 m-0 p-0">
                    <div className="container">
                        <DashboardFormHeader
                            title={intl.formatMessage({id:"DRIVING_LICENSE"})}
                        />

                        <div className="row mt-10 document-edit-container">

                            <div className="col-lg-6 mb-5">
                                <p className="personal-data-form-title">{intl.formatMessage({id:"NUMBER"})}</p>
                                <p className="personal-data-form-value">{documentNoFormatter(user.documents.drivinglicense.number)}</p>
                            </div>

                            <div className="col-lg-6 mb-5">
                                <p className="personal-data-form-title">{intl.formatMessage({id:"EXPIRY_DATE"})}</p>
                                <p className="personal-data-form-value">{user.documents.drivinglicense.expiry}</p>
                            </div>

                        </div>


                        <div className="mt-10">
                            <DashboardFormHeader
                                title={intl.formatMessage({id:"PASSPORT"})}
                            />

                            <div className="row mt-10 document-edit-container">

                                <div className="col-lg-6 mb-5">
                                    <p className="personal-data-form-title">{intl.formatMessage({id:"NUMBER"})}</p>
                                    <p className="personal-data-form-value">{documentNoFormatter(user.documents.passport.number)}</p>
                                </div>

                                <div className="col-lg-6 mb-5">
                                    <p className="personal-data-form-title">{intl.formatMessage({id:"EXPIRY_DATE"})}</p>
                                    <p className="personal-data-form-value">{user.documents.passport.expiry}</p>
                                </div>

                            </div>

                        </div>


                        <div className="my-10 pb-20">
                            <DashboardFormHeader
                                title={intl.formatMessage({id:"NATIONAL_ID_CARD"})}
                            />

                            <div className="row mt-10 document-edit-container">

                                <div className="col-lg-6 mb-5">
                                    <p className="personal-data-form-title">{intl.formatMessage({id:"NUMBER"})}</p>
                                    <p className="personal-data-form-value">{documentNoFormatter(user.documents.idcard.number)}</p>
                                </div>

                                <div className="col-lg-6 mb-5">
                                    <p className="personal-data-form-title">{intl.formatMessage({id:"EXPIRY_DATE"})}</p>
                                    <p className="personal-data-form-value">{user.documents.idcard.expiry}</p>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Documents;
