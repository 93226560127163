import React from 'react';
import propTypes from 'prop-types';
import {
    IdIconRegister,
    LockIconRegister,
    PhoneVerificationRegister,
    SecurityIconRegister
} from "../../../../_metronic/_assets/icons";
import {useIntl} from "react-intl";

RegisterPageStepIcons.propTypes = {
    step: propTypes.number.isRequired
}


function RegisterPageStepIcons(props) {
    const intl = useIntl();

    return (
        <div className="registration-step-container">

            <div className={`register-icon-container ${props.step === 0 ? "register-icon-active" : ""}`}>
                <div className="d-flex align-items-center">
                    <div
                        className={"registration-icon-background " + (props.step === 0 ? "step-active" : props.step > 0 ? "step-passed" : "step-passive")}>
                        <IdIconRegister step={props.step}/>
                    </div>
                    <div className="registration-step-title ml-2" style={{color: '#464E5F'}}>
                        {intl.formatMessage({id: "ID"})} <br/>{intl.formatMessage({id: "INFORMATION"})}
                    </div>
                </div>
                <div className="registration-line"/>
            </div>

            <div className={`register-icon-container ${props.step === 1 ? "register-icon-active" : ""}`}>
                <div className="d-flex align-items-center">
                    <div
                        className={"registration-icon-background " + (props.step === 1 ? "step-active" : props.step > 1 ? "step-passed" : "step-passive")}>
                        <PhoneVerificationRegister step={props.step}/>
                    </div>
                    <div className="registration-step-title ml-2"
                         style={props.step >= 1 ? {color: '#464E5F'} : {color: '#B5B5C3'}}>
                        {intl.formatMessage({id: "PHONE"})} <br/>{intl.formatMessage({id: "VERIFICATION"})}
                    </div>
                </div>
                <div className="registration-line"/>
            </div>

            <div className={`register-icon-container ${props.step === 2 ? "register-icon-active" : ""}`}>
                <div className="d-flex align-items-center">
                    <div
                        className={"registration-icon-background " + (props.step === 2 ? "step-active" : props.step > 2 ? "step-passed" : "step-passive")}>
                        <LockIconRegister step={props.step}/>
                    </div>
                    <div className="registration-step-title ml-2"
                         style={props.step >= 2 ? {color: '#464E5F'} : {color: '#B5B5C3'}}>
                        {intl.formatMessage({id: "PASSWORD_INPUT_TITLE"})} <br/>{intl.formatMessage({id: "GENERATING"})}
                    </div>
                </div>
                <div className="registration-line"/>
            </div>

            <div className={`register-icon-container ${props.step === 3 ? "register-icon-active" : ""}`}>
                <div className="d-flex align-items-center">
                    <div
                        className={"registration-icon-background " + (props.step === 3 ? "step-active" : props.step > 3 ? "step-passed" : "step-passive")}>
                        <SecurityIconRegister step={props.step}/>
                    </div>
                    <div className="registration-step-title ml-2"
                         style={props.step >= 3 ? {color: '#464E5F'} : {color: '#B5B5C3'}}>
                        {intl.formatMessage({id: "SETTING_UP_SECURITY"})} <br/>{intl.formatMessage({id: "QUESTIONS"})}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default RegisterPageStepIcons;
