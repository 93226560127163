import React, {useEffect, useState} from 'react';
import propTypes from 'prop-types';
import {passwordStrengthController} from "./InputController";
import {useIntl} from "react-intl";

PasswordValidationTexts.propTypes = {
    password : propTypes.string,
    setPasswordStrength : propTypes.func,
    setPassword : propTypes.func,
    setPasswordRetry : propTypes.func
}

/*
This component includes 8-12 passwords, uppercase lowercase etc. text for validation steps of new password or change password.
This component should be attached on position-relative component. It will work responsive for mobile and tablets too.

This component takes password value of parent component's input as props.
On every password value changes it controls password strength with passwordStrengthController func. After than sending strength index value to parent component.
-- On errors --
This component takes setPassword and setPasswordRetry props for error handling. If any error happen when calculating strength index its set password and password retry inputs to initial value automatically.
*/



function PasswordValidationTexts(props) {
    const intl = useIntl();
    const [valids, setValids] = useState({
        length: null,
        letters: null,
        numbers: null,
    })

    const passwordIndexGenerator = () =>{
        passwordStrengthController(props.password, (strength) => props.setPasswordStrength(strength))
            .then(data => setValids(data))
            .catch(err => {
                setValids({
                    length: null,
                    letters: null,
                    numbers: null,
                })
                props.setPassword("");
                props.setPasswordRetry("");
            })
    }

    useEffect(passwordIndexGenerator, [props.password]);

    return (
        <div className="passwordValidation">
            <div className="d-flex">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 4.5L6.75 12.75L3 9"
                          stroke={valids.length === null ? "#BEBEBE" : valids.length ? "rgba(0, 194, 51, 0.65)" : "#F64E60"}
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className="passwordValidationText ml-4">{intl.formatMessage({id:"PASSWORD_8_12_CHARS"})}</p>
            </div>
            <div className="d-flex">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 4.5L6.75 12.75L3 9"
                          stroke={valids.letters === null ? "#BEBEBE" : valids.letters ? "rgba(0, 194, 51, 0.65)" : "#F64E60"}
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className="passwordValidationText ml-4">{intl.formatMessage({id:"PASSWORD_SMALL_CAPITAL_LETTERS"})}</p>
            </div>
            <div className="d-flex">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 4.5L6.75 12.75L3 9"
                          stroke={valids.numbers === null ? "#BEBEBE" : valids.numbers ? "rgba(0, 194, 51, 0.65)" : "#F64E60"}
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p className="passwordValidationText ml-4">{intl.formatMessage({id:"PASSWORD_NUMBERS"})}</p>
            </div>
        </div>
    );
}

export default PasswordValidationTexts;
