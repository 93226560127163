import React from 'react';
import DashboardLeftMenu from "../Components/DashboardLeftMenu";
import DashboardFormHeader from "../Components/DashboardFormHeader";
import DashboardInput from "../Components/DashboardInput";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";


function Settings(props) {
    const intl = useIntl();
    const {user} = useSelector(state => state.auth);

    return (
        <div className="bg-white w-100 h-100 card card-custom">
            <div className="row h-100">
                <DashboardLeftMenu/>
                <div className="col-lg-9 m-0 p-0">
                    <div className="container">
                        <DashboardFormHeader
                            title={intl.formatMessage({id:"SECURITY_QUESTIONS"})}
                            buttonTitle={intl.formatMessage({id:"UPDATE_QUESTIONS"})}
                            onButtonClick={() => props.history.push('/settings/update-questions')}
                        />

                        <div className="row mt-10 document-edit-container">
                            {
                                user.securityQuestions.map((item, index) =>
                                    <div className="col-lg-12" key={index}>
                                        <p className="personal-data-form-title">{intl.formatMessage({id:"QUESTION_INDEX_SETTINGS"},{questionIndex:index+1})}</p>
                                        <p className="dashboard-input">{item.question}</p>
                                    </div>
                                )
                            }
                        </div>

                        <div className="mt-10">
                            <DashboardFormHeader
                                title={intl.formatMessage({id:"PASSWORD_INPUT_TITLE"})}
                                buttonTitle={intl.formatMessage({id:"UPDATE_PASSWORD"})}
                                onButtonClick={() => props.history.push('/settings/change-password')}
                            />

                            <div className="row mt-10 form-verify-container">
                                <div className="col-lg-3">
                                    <p className="personal-data-form-title">{intl.formatMessage({id:"YOUR_PASSWORD"})}</p>
                                    <DashboardInput inputType="text" value="••••••••"/>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>

    );
}

export default Settings;
