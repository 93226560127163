import React from 'react';
import FormHeader from "./FormHeader";
import FormInput from "./FormInput";
import {ButtonArrowIcon} from "../../../../_metronic/_assets/icons";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../_redux/authRedux";
import {useIntl} from "react-intl";
import FormButton from "./FormButton";
import CarrierRegisterPageStepIcons from "./CarrierRegisterPageStepIcons";
import VatInput from "./VATInput";
import CarrierSelect from "./CarrierSelect";
import {ibanController} from "./InputController";
import TooltipComponent from "../../../../_metronic/_partials/Components/TooltipComponent";

function CarrierRegistrationCompanyData(props) {
    const intl = useIntl();

    const _inputOnChange = (e) => {
        let newData = Object.assign({}, props.data);
        newData.companyData[e.target.name] = e.target.value;
        props.setData(newData);
    }

    return (
        <div className="d-flex flex-column flex-row-fluid position-relative form-area" style={{overflowY: 'auto'}}>
            <div className="d-flex flex-column-fluid align-items-stretch justify-content-start">
                <div
                    className="login-form login-signin d-flex align-items-stretch justify-content-start flex-column mt-10">
                    <div>
                        <FormHeader
                            title={intl.formatMessage({id: "CARRIER_REGISTER_FORM_TITLE"})}
                            desc={
                                <p className="form-head-desc">{intl.formatMessage({id: "CARRIER_REGISTRATION_DESC"})}</p>
                            }
                        />
                        <div style={{justifySelf: 'flex-start'}} id="registration-page-div">
                            <CarrierRegisterPageStepIcons step={0}/>
                        </div>
                    </div>
                    <div className="container p-0 mb-10">

                        <h3 className="form-title">{intl.formatMessage({id: "COMPANY_INFORMATION"})}</h3>
                        <hr className="mb-20"/>
                        <div className="row">
                            <div className="col-md-12 mb-10">
                                <div className="mb-5">
                                    <span className="form-title light">{intl.formatMessage({id: "COMPANY_TYPE"})}</span>
                                    <span style={{color: "red", marginLeft: ".5em"}}>*</span>
                                </div>
                                <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                                    <div className="d-flex form-control-text flex-center">
                                        <div className="checkbox-inline">
                                            <label className="checkbox checkbox-primary">
                                                <input
                                                    type="checkbox"
                                                    checked={props.data.companyData.companyType === "carrier" ? true : false}
                                                    onChange={(e) => {
                                                        e.target.checked ? _inputOnChange({
                                                            target: {
                                                                name: 'companyType',
                                                                value: 'carrier'
                                                            }
                                                        }) : _inputOnChange({target: {name: 'companyType', value: ''}})
                                                    }}
                                                />
                                                <span className="ml-2"></span>
                                                {intl.formatMessage({id: "CARRIER_COMPANY"})}
                                            </label>
                                        </div>
                                        <TooltipComponent
                                            tooltipText={intl.formatMessage({id: "CARRIER_COMPANY_TOOLTIP"})}/>
                                    </div>


                                    <div className="d-flex form-control-text flex-center">
                                        <div className="checkbox-inline">
                                            <label className="checkbox checkbox-primary">
                                                <input
                                                    type="checkbox"
                                                    checked={props.data.companyData.companyType === "lsp" ? true : false}
                                                    onChange={(e) => {
                                                        e.target.checked ? _inputOnChange({
                                                            target: {
                                                                name: 'companyType',
                                                                value: 'lsp'
                                                            }
                                                        }) : _inputOnChange({target: {name: 'companyType', value: ''}})
                                                    }}
                                                />
                                                <span className="ml-2"></span>
                                                {intl.formatMessage({id: "LSP_COMPANY"})}
                                            </label>
                                        </div>
                                        <TooltipComponent
                                            tooltipText={intl.formatMessage({id: "LSP_COMPANY_TOOLTIP"})}/>
                                    </div>


                                    <div className="d-flex form-control-text flex-center">
                                        <div className="checkbox-inline">
                                            <label className="checkbox checkbox-primary">
                                                <input
                                                    type="checkbox"
                                                    checked={props.data.companyData.companyType === "both" ? true : false}
                                                    onChange={(e) => {
                                                        e.target.checked ? _inputOnChange({
                                                            target: {
                                                                name: 'companyType',
                                                                value: 'both'
                                                            }
                                                        }) : _inputOnChange({target: {name: 'companyType', value: ''}})
                                                    }}
                                                />
                                                <span className="ml-2"></span>
                                                {intl.formatMessage({id: "BOTH"})}
                                            </label>
                                        </div>
                                        <TooltipComponent
                                            tooltipText={intl.formatMessage({id: "BOTH_COMPANY_TOOLTIP"})}/>
                                    </div>

                                </div>
                                {
                                    props.errors.companyType.status &&
                                    <div className="form-error-text mt-2 mb-0">{props.errors.companyType.message}</div>
                                }
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    title={intl.formatMessage({id: "COMPANY_NAME"})}
                                    inputType={"text"}
                                    inputName={"companyName"}
                                    placeHolder={intl.formatMessage({id: "COMPANY_NAME"})}
                                    isRequired
                                    error={props.errors.companyName}
                                    value={props.data.companyData.companyName}
                                    onChange={(e) => _inputOnChange(e)}
                                    headerClassName={"light"}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    title={intl.formatMessage({id: "CENTRAL_EMAIL"})}
                                    inputType={"text"}
                                    inputName={"centralEmail"}
                                    placeHolder={intl.formatMessage({id: "CENTRAL_EMAIL"})}
                                    questionMark
                                    isRequired
                                    toolTipTitle={intl.formatMessage({id: "CENTRAL_EMAIL_TOOLTIP"})}
                                    error={props.errors.centralEmail}
                                    value={props.data.companyData.centralEmail}
                                    onChange={(e) => _inputOnChange(e)}
                                    headerClassName={"light"}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    title={intl.formatMessage({id: "COMPANY_REGISTRATION_NUMBER"})}
                                    inputType={"text"}
                                    inputName={"registrationNumber"}
                                    placeHolder={intl.formatMessage({id: "COMPANY_REGISTRATION_NUMBER"})}
                                    isRequired
                                    questionMark
                                    toolTipTitle={intl.formatMessage({id: "REGISTRATION_NUMBER_TOOLTIP"})}
                                    error={props.errors.registrationNumber}
                                    value={props.data.companyData.registrationNumber}
                                    onChange={(e) => _inputOnChange(e)}
                                    headerClassName={"light"}
                                />
                            </div>
                            <div className="col-md-6">
                                <VatInput
                                    title={intl.formatMessage({id: "VAT_NUMBER"})}
                                    inputType={"text"}
                                    inputName={"VATNumber"}
                                    placeHolder={intl.formatMessage({id: "VAT_NUMBER"})}
                                    isRequired
                                    questionMark
                                    error={props.errors.VATNumber}
                                    selectError={props.errors.VATCountry}
                                    countryValue={props.data.companyData.VATCountry}
                                    value={props.data.companyData.VATNumber}
                                    onChange={_inputOnChange}
                                    headerClassName={"light"}
                                    onCountryChange={(country) => _inputOnChange({
                                        target: {
                                            value: country,
                                            name: "VATCountry"
                                        }
                                    })}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    title={intl.formatMessage({id: "REPRESENTATIVE_NAME"})}
                                    inputType={"text"}
                                    inputName={"nameRepresentative"}
                                    placeHolder={intl.formatMessage({id: "REPRESENTATIVE_NAME"})}
                                    isRequired
                                    error={props.errors.nameRepresentative}
                                    value={props.data.companyData.nameRepresentative}
                                    onChange={(e) => _inputOnChange(e)}
                                    headerClassName={"light"}
                                />
                            </div>
                            <div className="col-md-6">
                                <FormInput
                                    title={intl.formatMessage({id: "REPRESENTATIVE_POSITION"})}
                                    inputType={"text"}
                                    inputName={"positionRepresentative"}
                                    value={props.data.companyData.positionRepresentative}
                                    onChange={(e) => _inputOnChange(e)}
                                    error={props.errors.positionRepresentative}
                                    placeHolder={intl.formatMessage({id: "REPRESENTATIVE_POSITION"})}
                                    headerClassName={"light"}
                                />
                            </div>
                        </div>


                        <h3 className="form-title mt-10">{intl.formatMessage({id: "SEATED_ADDRESS"})}</h3>
                        <hr className="mb-20"/>
                        <div className="row">
                            <div className="col-md-4">
                                <CarrierSelect
                                    title={intl.formatMessage({id: "COUNTRY"})}
                                    options={props.allCountries}
                                    value={props.data.companyData.country}
                                    onChange={(country) => _inputOnChange({
                                        target: {
                                            name: "country",
                                            value: country
                                        }
                                    })}
                                    placeholder={intl.formatMessage({id: "PLEASE_SELECT"})}
                                    error={props.errors.country}
                                    headerClassName={"light"}
                                />
                            </div>
                            <div className="col-md-4">
                                <FormInput
                                    title={intl.formatMessage({id: "ZIP_CODE"})}
                                    inputType={"text"}
                                    inputName={"zipCode"}
                                    placeHolder={intl.formatMessage({id: "ZIP_CODE"})}
                                    isRequired
                                    error={props.errors.zipCode}
                                    value={props.data.companyData.zipCode}
                                    onChange={(e) => _inputOnChange(e)}
                                    headerClassName={"light"}
                                />
                            </div>
                            <div className="col-md-4">
                                <FormInput
                                    title={intl.formatMessage({id: "CITY"})}
                                    inputType={"text"}
                                    inputName={"city"}
                                    placeHolder={intl.formatMessage({id: "CITY"})}
                                    isRequired
                                    error={props.errors.city}
                                    value={props.data.companyData.city}
                                    onChange={(e) => _inputOnChange(e)}
                                    headerClassName={"light"}
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInput
                                    title={intl.formatMessage({id: "ADDRESS"})}
                                    inputType={"text"}
                                    inputName={"address"}
                                    placeHolder={intl.formatMessage({id: "ADDRESS"})}
                                    isRequired
                                    error={props.errors.address}
                                    value={props.data.companyData.address}
                                    onChange={(e) => _inputOnChange(e)}
                                    headerClassName={"light"}
                                />
                            </div>


                            <div className="col-md-12">
                                <div className="checkbox-inline">
                                    <label className="checkbox checkbox-primary">
                                        <input
                                            type="checkbox"
                                            checked={props.data.companyData.isPostalAddressDifferent}
                                            onChange={(e) => {
                                                _inputOnChange({
                                                    target: {
                                                        name: 'isPostalAddressDifferent',
                                                        value: e.target.checked
                                                    }
                                                })
                                            }}
                                        />
                                        <span className="ml-0"></span>
                                        <div className="mb-0 form-title light">
                                            <span
                                                style={{
                                                    fontWeight: '400',
                                                    marginRight: 0
                                                }}>{intl.formatMessage({id: "POSTAL_ADDRESS_DIFFERENT_CHECKBOX"})}</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>


                        {
                            props.data.companyData.isPostalAddressDifferent &&
                            <>
                                <h3 className="form-title mt-10">{intl.formatMessage({id: "POSTAL_ADDRESS_UPPERCASE"})}</h3>
                                <hr className="mb-20"/>
                                <div className="row">
                                    <div className="col-md-4">
                                        <CarrierSelect
                                            title={intl.formatMessage({id: "COUNTRY"})}
                                            options={props.allCountries}
                                            value={props.data.companyData.postalAddressCountry}
                                            onChange={(country) => _inputOnChange({
                                                target: {
                                                    name: "postalAddressCountry",
                                                    value: country
                                                }
                                            })}
                                            placeholder={intl.formatMessage({id: "PLEASE_SELECT"})}
                                            error={props.errors.postalAddressCountry}
                                            headerClassName={"light"}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            title={intl.formatMessage({id: "ZIP_CODE"})}
                                            inputType={"text"}
                                            inputName={"postalAddressZipCode"}
                                            placeHolder={intl.formatMessage({id: "ZIP_CODE"})}
                                            isRequired
                                            error={props.errors.postalAddressZipCode}
                                            value={props.data.companyData.postalAddressZipCode}
                                            onChange={(e) => _inputOnChange(e)}
                                            headerClassName={"light"}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <FormInput
                                            title={intl.formatMessage({id: "CITY"})}
                                            inputType={"text"}
                                            inputName={"postalAddressCity"}
                                            placeHolder={intl.formatMessage({id: "CITY"})}
                                            isRequired
                                            error={props.errors.postalAddressCity}
                                            value={props.data.companyData.postalAddressCity}
                                            onChange={(e) => _inputOnChange(e)}
                                            headerClassName={"light"}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <FormInput
                                            title={intl.formatMessage({id: "ADDRESS"})}
                                            inputType={"text"}
                                            inputName={"postalAddressAddress"}
                                            placeHolder={intl.formatMessage({id: "ADDRESS"})}
                                            isRequired
                                            error={props.errors.postalAddressAddress}
                                            value={props.data.companyData.postalAddressAddress}
                                            onChange={(e) => _inputOnChange(e)}
                                            headerClassName={"light"}
                                        />
                                    </div>
                                </div>
                            </>
                        }


                        <h3 className="form-title mt-10">{intl.formatMessage({id: "FINANCIAL_DATA"})}</h3>
                        <hr className="mb-20"/>
                        <div className="row">
                            <div className="col-md-12">
                                <FormInput
                                    title={intl.formatMessage({id: "IBAN_NUMBER"})}
                                    inputType={"text"}
                                    inputName={"ibanNumber"}
                                    placeHolder={intl.formatMessage({id: "IBAN_NUMBER"})}
                                    isRequired
                                    error={props.errors.ibanNumber}
                                    value={props.data.companyData.ibanNumber}
                                    onChange={(e) => ibanController(e.target.value, (iban) => _inputOnChange({
                                        target: {
                                            name: "ibanNumber",
                                            value: iban
                                        }
                                    }))}
                                    headerClassName={"light"}
                                />
                            </div>
                            <div className="col-md-12">
                                <FormInput
                                    title={intl.formatMessage({id: "EMAIL_INVOICING"})}
                                    inputType={"text"}
                                    inputName={"invoiceEmail"}
                                    placeHolder={intl.formatMessage({id: "EMAIL_INVOICING"})}
                                    error={props.errors.invoiceEmail}
                                    value={props.data.companyData.invoiceEmail}
                                    isRequired
                                    questionMark
                                    toolTipTitle={intl.formatMessage({id: "EMAIL_INVOICING_TOOLTIP"})}
                                    onChange={(e) => _inputOnChange(e)}
                                    headerClassName={"light"}
                                />
                            </div>
                            <div className="col-md-12 mb-10">
                                <div className="mb-5">
                                    <span className="form-title light">{intl.formatMessage({id: "PAYMENT"})}</span>
                                    <span style={{color: "red", marginLeft: ".5em"}}>*</span>
                                </div>
                                <div className="d-flex flex-wrap">
                                    <div className="d-flex form-title flex-center">
                                        <div className="checkbox-inline">
                                            <label className="checkbox checkbox-primary">
                                                <input
                                                    type="checkbox"
                                                    checked={props.data.companyData.payment === "byUs" ? true : false}
                                                    onChange={(e) => {
                                                        e.target.checked ? _inputOnChange({
                                                            target: {
                                                                name: 'payment',
                                                                value: 'byUs'
                                                            }
                                                        }) : _inputOnChange({target: {name: 'payment', value: ''}})
                                                    }}
                                                />
                                                <span className="ml-0"></span>
                                                <div className="mb-0 form-title light">
                                            <span
                                                style={{
                                                    fontWeight: '400',
                                                    marginRight: 0
                                                }}>{intl.formatMessage({id: "REGISTRATION_SERVICES_WILL_BE"})}</span>
                                                    <span>{intl.formatMessage({id: "PAID_BY_US"})}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-center">

                                        <div className="checkbox-inline">
                                            <label className="checkbox checkbox-primary">
                                                <input
                                                    type="checkbox"
                                                    checked={props.data.companyData.payment === "byAnotherCompany" ? true : false}
                                                    onChange={(e) => {
                                                        e.target.checked ? _inputOnChange({
                                                            target: {
                                                                name: 'payment',
                                                                value: 'byAnotherCompany'
                                                            }
                                                        }) : _inputOnChange({target: {name: 'payment', value: ''}})
                                                    }}
                                                />
                                                <span className="ml-0"></span>
                                                <div className="mb-0 form-title light">
                                            <span
                                                style={{
                                                    fontWeight: '400',
                                                    marginRight: 0
                                                }}>{intl.formatMessage({id: "REGISTRATION_SERVICES_WILL_BE"})}</span>
                                                    <span>{intl.formatMessage({id: "PAID_BY_ANOTHER_COMPANY"})}</span>
                                                </div>
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                {
                                    props.errors.payment.status &&
                                    <div className="form-error-text mt-2 mb-0">{props.errors.payment.message}</div>
                                }
                            </div>
                            {
                                props.data.companyData.payment === "byAnotherCompany" &&
                                <div className="col-md-12">
                                    <FormInput
                                        title={intl.formatMessage({id: "INVOICE_PAYER_COMPANY"})}
                                        inputType={"text"}
                                        inputName={"invoicePayerCompany"}
                                        isRequired
                                        placeHolder={intl.formatMessage({id: "INVOICE_PAYER_COMPANY"})}
                                        error={props.errors.invoicePayerCompany}
                                        value={props.data.companyData.invoicePayerCompany}
                                        onChange={(e) => _inputOnChange(e)}
                                        headerClassName={"light"}
                                    />
                                </div>
                            }
                        </div>


                    </div>

                    <FormButton
                        icon={<ButtonArrowIcon/>}
                        title={intl.formatMessage({id: "NEXT_BUTTON"})}
                        onClick={() => {
                            if (props.pageController()) {
                                props.changePage();
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(CarrierRegistrationCompanyData));
