import React, {useEffect, useState} from 'react';
import {BackIcon, ButtonArrowIcon} from "../../_assets/icons";
import TooltipComponent from "./TooltipComponent";
import propTypes from 'prop-types';
import DashboardSelect from "./DashboardSelect";
import DashboardInput from "./DashboardInput";
import FormButton from "../../../app/modules/Auth/Components/FormButton";
import {injectIntl, useIntl} from "react-intl";
import {
    commitUpdateSecurityQuestions,
    getAvailableQuestionsUpdateSecuriyQuestions, getUserByToken,
    setSecurityQuestionsUpdateSecurityQuestions
} from "../../../app/modules/Auth/_redux/authCrud";
import {connect} from "react-redux";
import * as auth from "../../../app/modules/Auth/_redux/authRedux";

DashboardChangeQuestions.propTypes = {
    iconOnClick : propTypes.func
}

function DashboardChangeQuestions(props) {
    const intl = useIntl();
    const [questionIndex,setQuestionIndex] = useState(1);
    const [allQuestions,setAllQuestions] = useState(null);
    const [selectedQuestion,setSelectedQuestion] = useState(null);
    const [availableQuestions,setAvailableQuestions] = useState(null);
    const [buttonDisable,setButtonDisable] = useState(true);
    const [answerInput,setAnswerInput] = useState("");
    const [inputError,setInputError] = useState({status:false,message:''});
    const [answers,setAnswers] = useState([]);

    const getQuestions = () => {
        getAvailableQuestionsUpdateSecuriyQuestions()
            .then(({data})=>{
                setAllQuestions(data.securityQuestions);
                setAvailableQuestions(data.securityQuestions);
                setSelectedQuestion({label:data.securityQuestions[0].question,value:data.securityQuestions[0].id});
            })
            .catch(err => {
                console.log("error happen when getting available questions at update security questions")
                props.setToastMenu({
                    status: true,
                    descText: err?.response?.data?.errorMessage,
                    headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                    isWarning : true
                })
            })
    }

    const nextQuestion = () => {
        //Code below mostly same with register flow but diffirences comes from react-select packages option props.
        let dataToServer = {
            securityQuestionId: selectedQuestion.value,
            answer: answerInput
        };
        setAnswers([...answers,dataToServer]);
        setAnswerInput('');
        let filterQuestions = availableQuestions.filter(item => {
            return item.id !== selectedQuestion.value
        });
        setAvailableQuestions(filterQuestions);
        setSelectedQuestion({label:filterQuestions[0].question,value:filterQuestions[0].id});
        setButtonDisable(true);
        if( questionIndex < 3 ) {
            setQuestionIndex(questionIndex+1)
        } else {
            setSecurityQuestionsUpdateSecurityQuestions([...answers,dataToServer])
                .then(()=>{
                    commitUpdateSecurityQuestions()
                        .then(() => {
                            getUserByToken()
                                .then(({data: user}) => {
                                    props.fulfillUser(user);
                                })
                                .catch(err => {
                                    console.log('error when getting new user data on update security questions');
                                })
                                .finally(() => {
                                    props.changePage()
                                })
                        })
                        .catch(err => {
                            console.log("error happen when commit changes at update email")
                            props.setToastMenu({
                                status: true,
                                descText: err?.response?.data?.errorMessage,
                                headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                                isWarning : true
                            })
                        })
                })
                .catch(err => {
                    console.log("error happen when setting security questions at update email")
                    props.setToastMenu({
                        status: true,
                        descText: err?.response?.data?.errorMessage,
                        headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                        isWarning : true
                    })
                })
        }
    }

    const backButton = () => {
        setButtonDisable(true);
        if (questionIndex === 1) {
            props.iconOnClick()
        } else {
            let lastQuestionId = answers[answers.length-1].securityQuestionId;
            let lastQuestion = allQuestions.filter(item => {
                return item.id === lastQuestionId
            })
            setAvailableQuestions([lastQuestion[0],...availableQuestions]);
            let filterAnswers = answers.filter(item => {
                return item.securityQuestionId !== lastQuestionId
            });
            setAnswers(filterAnswers);
            setQuestionIndex(questionIndex-1);
            setSelectedQuestion({label:lastQuestion[0].question,value:lastQuestion[0].id});
            setButtonDisable(false);
        }
    }

    const _inputHandler = (e) => {
        let numbers = /[0-9]/g;
        let inputValue = e.target.value.trim();
        let findSelectedQuestion = allQuestions.filter(item => {
            return item.id === selectedQuestion.value
        })
        const {answerValueMin, answerValueMax} = findSelectedQuestion[0];
        const formattedValue = parseInt(inputValue);
        const formattedMinValue = parseInt(answerValueMin);
        const formattedMaxValue = parseInt(answerValueMax);
        if (!formattedValue) {
            setAnswerInput('');
            return;
        }
        if (inputValue[inputValue.length - 1].match(numbers)) {
            setAnswerInput(e.target.value);
            if (formattedValue <= formattedMaxValue && formattedValue >= formattedMinValue) {
                setButtonDisable(false);
                setInputError({status: false, message: ''})
            } else {
                setButtonDisable(true);
                setInputError({
                    status: true,
                    message: intl.formatMessage({id:"ANSWER_VALUE_BETWEEN"},{formattedMinValue,formattedMaxValue})
                })
            }
        }
    }


    useEffect(getQuestions,[]);

    return (
        <div className="dashboard-form-area">
            <div className="d-flex align-items-start">
                <div onClick={backButton} className="cursor-pointer">
                    <BackIcon/>
                </div>
                <div className="mt-1">
                    <h3 className="dashboard-form-title ml-2 mb-0">
                        {intl.formatMessage({id:"UPDATE_SECURITY_QUESTIONS_TITLE"},{questionIndex})}
                    </h3>
                    <p className="widget-freights-desc-text mt-5 ml-2" style={{fontWeight: '500'}}>
                        {intl.formatMessage({id:"UPDATE_SECURITY_QUESTIONS_TITLE_DESC"})}
                    </p>
                </div>
            </div>
            <div className="row mt-10 ml-5">
                <div className="col-lg-12">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id:"SECURITY_QUESTION"})} <span
                            style={{color: 'red'}}>*</span></p>
                        <TooltipComponent tooltipText={"This is tooltip text in one line"}/>
                    </div>
                    <DashboardSelect
                        value={selectedQuestion && selectedQuestion}
                        onChange={setSelectedQuestion}
                        options={
                            availableQuestions &&
                                availableQuestions.map(item => {
                                    return {label:item.question,value:item.id}
                                })
                        }
                    />
                </div>
            </div>
            <div className="row mt-10 ml-5">
                <div className="col-lg-5">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id:"ANSWER"})}</p>
                    </div>
                    <DashboardInput
                        error={inputError}
                        value={answerInput}
                        onChange={(e)=>_inputHandler(e)}
                        maxLength={5}
                    />
                </div>
            </div>


            <div className="d-flex mt-10 ml-5 justify-content-end">
                <FormButton
                    title={questionIndex < 3 ? intl.formatMessage({id:"NEXT_BUTTON"}) : intl.formatMessage({id:"FINISH_BUTTON"})}
                    icon={<ButtonArrowIcon/>}
                    onClick={nextQuestion}
                    isDisabled={buttonDisable}
                    isPassive={buttonDisable}
                />
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(DashboardChangeQuestions));
