import axios from "axios";
//import {toAbsoluteUrl} from "../../../../_metronic/_helpers";


export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/register`;
export const GENERATE_OTP_URL = `${process.env.REACT_APP_API_URL}/register/generate-otp`;
export const OTP_VERIFICATION_URL = `${process.env.REACT_APP_API_URL}/register/otp`;
export const CREATE_PASSWORD_REGISTER_URL = `${process.env.REACT_APP_API_URL}/register/password`;
export const GET_SECURITY_QUESTIONS_REGISTER_URL = `${process.env.REACT_APP_API_URL}/register/available-security-questions`;
export const SET_SECURITY_QUESTIONS_REGISTER_URL = `${process.env.REACT_APP_API_URL}/register/security-questions`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/passwordreset`;
export const GENERATE_OTP_RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/passwordreset/generate-otp`;
export const VERIFY_OTP_RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/passwordreset/otp`;
export const GET_SECURITY_QUESTION_RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/passwordreset/security-question`;
export const ANSWER_SECURITY_QUESTION_RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/passwordreset/security-question`;
export const CHANGE_PASSWORD_RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/passwordreset/password`;
export const LOGOUT_URL = `${process.env.REACT_APP_API_URL}/auth/logout`;
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;
export const DASHBOARD_INFORMATION = `${process.env.REACT_APP_API_URL}/dashboard/information`;
export const REFRESH_TOKEN_URL = `${process.env.REACT_APP_API_URL}/auth/refresh`;
export const SEND_VERIFICATION_MAIL_URL = `${process.env.REACT_APP_API_URL}/dashboard/verification-email/send-email`;
export const AGREEMENT_ACCEPT_URL = `${process.env.REACT_APP_API_URL}/dashboard/membership-declaration/accept`;
export const DECLINE_ACCEPT_URL = `${process.env.REACT_APP_API_URL}/dashboard/membership-declaration/decline`;
export const START_UPDATE_SECURITY_QUESTIONS_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-security-questions`;
export const GENERATE_OTP_UPDATE_SECURITY_QUESTIONS_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-security-questions/generate-otp`;
export const VERIFY_OTP_UPDATE_SECURITY_QUESTIONS_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-security-questions/otp`;
export const AVAILABLE_QUESTIONS_UPDATE_SECURITY_QUESTIONS_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-security-questions/available-security-questions`;
export const SET_ANSWERS_UPDATE_SECURITY_QUESTIONS_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-security-questions/set-security-questions`;
export const COMMIT_UPDATE_SECURITY_QUESTIONS_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-security-questions/commit`;
export const START_UPDATE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-password`;
export const GET_QUESTIONS_UPDATE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-password/security-question`;
export const ANSWER_QUESTION_UPDATE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-password/security-question`;
export const GENERATE_OTP_UPDATE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-password/generate-otp`;
export const VERIFY_OTP_UPDATE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-password/otp`;
export const CHANGE_PASSWORD_UPDATE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-password/set-password`;
export const COMMIT_UPDATE_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-password/commit`;
export const START_UPDATE_EMAIL_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-email`;
export const GET_SECURITY_QUESTIONS_UPDATE_EMAIL_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-email/security-question`;
export const ANSWER_SECURITY_QUESTIONS_UPDATE_EMAIL_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-email/security-question`;
export const GENERATE_OTP_UPDATE_EMAIL_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-email/generate-otp`;
export const VERIFY_OTP_UPDATE_EMAIL_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-email/otp`;
export const SET_EMAIL_UPDATE_EMAIL_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-email/set-email`;
export const VALIDATION_GENERATE_OTP_UPDATE_EMAIL_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-email/validation-generate-otp`;
export const VALIDATION_VERIFY_OTP_UPDATE_EMAIL_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-email/validation-otp`;
export const COMMIT_UPDATE_EMAIL_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-email/commit`;
export const START_UPDATE_PHONE_NUMBER_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-phonenumber`;
export const GET_QUESTIONS_UPDATE_PHONE_NUMBER_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-phonenumber/security-question`;
export const ANSWER_QUESTIONS_UPDATE_PHONE_NUMBER_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-phonenumber/security-question`;
export const GENERATE_OTP_UPDATE_PHONE_NUMBER_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-phonenumber/generate-otp`;
export const VERIFY_OTP_UPDATE_PHONE_NUMBER_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-phonenumber/otp`;
export const SET_PHONE_UPDATE_PHONE_NUMBER_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-phonenumber/set-phonenumber`;
export const VALIDATION_GENERATE_OTP_UPDATE_PHONE_NUMBER_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-phonenumber/validation-generate-otp`;
export const VALIDATION_VERIFY_OTP_UPDATE_PHONE_NUMBER_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-phonenumber/validation-otp`;
export const COMMIT_UPDATE_PHONE_NUMBER_URL = `${process.env.REACT_APP_API_URL}/dashboard/update-phonenumber/commit`;
export const GET_DRIVER_LANGUAGE = `${process.env.REACT_APP_API_URL}/settings/language`;
export const AVAILABLE_LANGUAGES = `${process.env.REACT_APP_API_URL}/settings/languages`;
export const SET_DRIVER_LANGUAGE = `${process.env.REACT_APP_API_URL}/settings/language`;
export const GET_TOTP_SECRET_KEY = `${process.env.REACT_APP_API_URL}/settings/totp`;
export const GET_FAQ_CONTENT = `${process.env.REACT_APP_API_URL}/dashboard/faq`;
export const GET_TERMS_OF_USE = `${process.env.REACT_APP_API_URL}/dashboard/policydocument/termsofuse`;
export const GET_PRIVACY_POLICY = `${process.env.REACT_APP_API_URL}/dashboard/policydocument/privacypolicy`;
export const GET_DRIVER_IMAGE = `${process.env.REACT_APP_API_URL}/dashboard/driver-image`;
export const GET_SERVICE_DOC = `${process.env.REACT_APP_API_URL}/dashboard/servicedocument`;

//LSP ENDPOINTS
export const GET_ALL_COUNTRIES = `${process.env.REACT_APP_LSP_API_URL}/countries`;
export const GET_ALL_POSITIONS = `${process.env.REACT_APP_LSP_API_URL}/partner-contact-position`;
export const CREATE_NEW_PARTNER = `${process.env.REACT_APP_LSP_API_URL}/carrier`;
export const GET_PHONE_PREFIXES = `${process.env.REACT_APP_LSP_API_URL}/phone-prefixes`;
export const GET_VAT_COUNTRIES = `${process.env.REACT_APP_LSP_API_URL}/vat-number-prefixes`;


export function login(didbId, password, captchaResponse) {
    return axios.post(LOGIN_URL, {didbId, password, captchaResponse});
}

export function register(didbId, securityCode, captchaResponse) {
    return axios.post(REGISTER_URL, {didbId, securityCode, captchaResponse, privacypolicy : 1, termsofuse : 1});
}

export function generateRegisterOtp() {
    return axios.post(GENERATE_OTP_URL, {});
}

export function verifyRegisterOtp(otp) {
    return axios.post(OTP_VERIFICATION_URL, {otp});
}

export function createPasswordRegister(password, passwordConfirmation) {
    return axios.post(CREATE_PASSWORD_REGISTER_URL, {password, passwordConfirmation})
}

export function getSecurityQuestionsRegister() {
    return axios.get(GET_SECURITY_QUESTIONS_REGISTER_URL)
}

export function setSecurityQuestionsRegister(questionsList) {
    return axios.post(SET_SECURITY_QUESTIONS_REGISTER_URL, {securityQuestions: questionsList})
}

export function passwordReset(didbId) {
    return axios.post(RESET_PASSWORD_URL, {didbId})
}

export function generateOTPPasswordReset() {
    return axios.post(GENERATE_OTP_RESET_PASSWORD_URL, {});
}

export function verifyOTPPasswordReset(otp) {
    return axios.post(VERIFY_OTP_RESET_PASSWORD_URL, {otp})
}

export function getSecurityQuestionPasswordReset() {
    return axios.get(GET_SECURITY_QUESTION_RESET_PASSWORD_URL)
}

export function answerSecurityQuestionPasswordReset(answer) {
    return axios.post(ANSWER_SECURITY_QUESTION_RESET_PASSWORD_URL, {answer})
}

export function changePasswordPasswordReset(password, passwordConfirmation) {
    return axios.post(CHANGE_PASSWORD_RESET_PASSWORD_URL, {password, passwordConfirmation})
}

export function logout() {
    return axios.post(LOGOUT_URL)
}

export function authUser() {
    return axios.get(ME_URL);
}

export function getUserByToken() {
    return axios.get(DASHBOARD_INFORMATION);
}

export function sendVerificationMail() {
    return axios.post(SEND_VERIFICATION_MAIL_URL)
}

export function agreementAccept() {
    return axios.post(AGREEMENT_ACCEPT_URL,{accept:1})
}

export function agreementDecline() {
    return axios.post(DECLINE_ACCEPT_URL,{decline:1})
}

export function startUpdateSecurityQuestions() {
    return axios.post(START_UPDATE_SECURITY_QUESTIONS_URL)
}

export function generateOtpUpdateSecurityQuestions() {
    return axios.post(GENERATE_OTP_UPDATE_SECURITY_QUESTIONS_URL);
}

export function verifyUpdateSecurityQuestionsOtp(otp) {
    return axios.post(VERIFY_OTP_UPDATE_SECURITY_QUESTIONS_URL, {otp});
}

export function getAvailableQuestionsUpdateSecuriyQuestions() {
    return axios.get(AVAILABLE_QUESTIONS_UPDATE_SECURITY_QUESTIONS_URL)
}

export function setSecurityQuestionsUpdateSecurityQuestions(answers) {
    return axios.post(SET_ANSWERS_UPDATE_SECURITY_QUESTIONS_URL, {securityQuestions: answers})
}

export function commitUpdateSecurityQuestions() {
    return axios.post(COMMIT_UPDATE_SECURITY_QUESTIONS_URL)
}

export function startUpdatePassword() {
    return axios.post(START_UPDATE_PASSWORD_URL)
}

export function getSecurityQuestionsUpdatePassword() {
    return axios.get(GET_QUESTIONS_UPDATE_PASSWORD_URL)
}

export function answerSecurityQuestionUpdatePassword(answer) {
    return axios.post(ANSWER_QUESTION_UPDATE_PASSWORD_URL, {answer})
}

export function generateOtpUpdatePassword() {
    return axios.post(GENERATE_OTP_UPDATE_PASSWORD_URL)
}

export function verifyOtpUpdatePassword(otp) {
    return axios.post(VERIFY_OTP_UPDATE_PASSWORD_URL, {otp})
}

export function setPasswordUpdatePassword(password) {
    return axios.post(CHANGE_PASSWORD_UPDATE_PASSWORD_URL, {password, passwordConfirmation: password})
}

export function commitUpdatePassword() {
    return axios.post(COMMIT_UPDATE_PASSWORD_URL)
}

export function startUpdateEmail() {
    return axios.post(START_UPDATE_EMAIL_URL)
}

export function getSecuriyQuestionsUpdateEmail() {
    return axios.get(GET_SECURITY_QUESTIONS_UPDATE_EMAIL_URL)
}

export function answerSecurityQuestionsUpdateEmail(answer) {
    return axios.post(ANSWER_SECURITY_QUESTIONS_UPDATE_EMAIL_URL, {answer})
}

export function generateOtpUpdateEmail() {
    return axios.post(GENERATE_OTP_UPDATE_EMAIL_URL)
}

export function verifyOtpUpdateEmail(otp) {
    return axios.post(VERIFY_OTP_UPDATE_EMAIL_URL, {otp})
}

export function setEmailUpdateEmail(email) {
    return axios.post(SET_EMAIL_UPDATE_EMAIL_URL, {email})
}

export function validationGenerateOtpUpdateEmail() {
    return axios.post(VALIDATION_GENERATE_OTP_UPDATE_EMAIL_URL)
}

export function validationVerifyOtpUpdateEmail(otp) {
    return axios.post(VALIDATION_VERIFY_OTP_UPDATE_EMAIL_URL, {otp})
}

export function commitUpdateEmail() {
    return axios.post(COMMIT_UPDATE_EMAIL_URL)
}

export function startUpdatePhone() {
    return axios.post(START_UPDATE_PHONE_NUMBER_URL)
}

export function getSecurityQuestionsUpdatePhone() {
    return axios.get(GET_QUESTIONS_UPDATE_PHONE_NUMBER_URL)
}

export function answerSecurityQuestionsUpdatePhone(answer) {
    return axios.post(ANSWER_QUESTIONS_UPDATE_PHONE_NUMBER_URL, {answer})
}

export function generateOtpUpdatePhone() {
    return axios.post(GENERATE_OTP_UPDATE_PHONE_NUMBER_URL)
}

export function verifyOtpUpdatePhone(otp) {
    return axios.post(VERIFY_OTP_UPDATE_PHONE_NUMBER_URL, {otp})
}

export function setPhoneUpdatePhone(phoneprefix, phonenumber) {
    return axios.post(SET_PHONE_UPDATE_PHONE_NUMBER_URL, {phoneprefix, phonenumber})
}

export function validationGenerateOtpUpdatePhone() {
    return axios.post(VALIDATION_GENERATE_OTP_UPDATE_PHONE_NUMBER_URL)
}

export function validationVerifyOtpUpdatePhone(otp) {
    return axios.post(VALIDATION_VERIFY_OTP_UPDATE_PHONE_NUMBER_URL, {otp})
}

export function commitUpdatePhone() {
    return axios.post(COMMIT_UPDATE_PHONE_NUMBER_URL)
}

export function getDriverLanguage() {
    return axios.get(GET_DRIVER_LANGUAGE)
}

export function getAvailableLanguages() {
    return axios.get(AVAILABLE_LANGUAGES)
}

export function setDriverLanguage(language) {
    return axios.post(SET_DRIVER_LANGUAGE,{language})
}

export function getTotpSecretKey() {
    return axios.get(GET_TOTP_SECRET_KEY)
}

export function getFaqContent() {
    return axios.post(GET_FAQ_CONTENT,{platform:'web'})
}

export function getTermsOfUse() {
    return axios.get(GET_TERMS_OF_USE)
}

export function getPrivacyPolicy() {
    return axios.get(GET_PRIVACY_POLICY)
}

export function getDriverImage() {
    return axios.get(GET_DRIVER_IMAGE)
}

export function getServiceDocument(id) {
    return axios.get(`${GET_SERVICE_DOC}/${id}`)
}

//LSP API CALLS

export function getAllCountries() {
    return axios.get(GET_ALL_COUNTRIES)
}

export function getAllPositions() {
    return axios.get(GET_ALL_POSITIONS)
}

export function createNewPartner(data) {
    return axios.post(CREATE_NEW_PARTNER,data)
}

export function getPhonePrefixes(){
    return axios.get(GET_PHONE_PREFIXES)
}

export function getVatCountries(){
    return axios.get(GET_VAT_COUNTRIES)
}
