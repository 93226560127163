/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useMemo, useLayoutEffect, useEffect} from "react";
import objectPath from "object-path";
import {
    getBreadcrumbsAndTitle,
    useSubheader
} from "../../_core/MetronicSubheader";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {useLocation} from "react-router-dom";
import {useIntl} from "react-intl";

/*
import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
*/

export function SubHeader() {
    const uiService = useHtmlClassService();
    const location = useLocation();
    const subheader = useSubheader();
    const intl = useIntl();

    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            subheaderMobileToggle: objectPath.get(
                uiService.config,
                "subheader.mobile-toggle"
            ),
            subheaderCssClasses: uiService.getClasses("subheader", true),
            subheaderContainerCssClasses: uiService.getClasses(
                "subheader_container",
                true
            )
        };
    }, [uiService]);

    useLayoutEffect(() => {
        const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
        const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
        const breadcrumbs =
            aside && aside.breadcrumbs.length > 0
                ? aside.breadcrumbs
                : header.breadcrumbs;
        subheader.setBreadcrumbs(breadcrumbs);
        subheader.setTitle(
            aside && aside.title && aside.title.length > 0
                ? aside.title
                : header.title
        );
        // eslint-disable-next-line
    }, [location.pathname]);

    // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
    useEffect(() => {
    }, [subheader]);


    return (
        <div
            id="kt_subheader"
            className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
        >
            <div
                className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
            >
                {/* Info */}
                <div className="d-flex align-items-center flex-wrap mr-1">
                    {layoutProps.subheaderMobileToggle && (
                        <button
                            className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                            id="kt_subheader_mobile_toggle"
                        >
                            <span/>
                        </button>
                    )}

                    <div className="d-flex align-items-baseline mr-5">
                        <h5 className="subheaderText">
                            <>{
                                location.pathname === "/dashboard" ? intl.formatMessage({id: "DASHBOARD"}) :
                                    location.pathname === "/personal-data" ? intl.formatMessage({id: "PERSONAL_DATA"}) :
                                        location.pathname === "/settings" ? intl.formatMessage({id: "SETTINGS"}) :
                                            location.pathname === "/documents" ? intl.formatMessage({id: "DOCUMENTS"}) :
                                                location.pathname === "/settings/update-questions" ? intl.formatMessage({id: "UPDATE_SECURITY_QUESTIONS"}) :
                                                    location.pathname === "/settings/change-password" ? intl.formatMessage({id: "CHANGE_PASSWORD"}) :
                                                        location.pathname === "/settings/email-verify" ? intl.formatMessage({id: "EMAIL_VERIFY"}) :
                                                            location.pathname === "/settings/agreement" ? intl.formatMessage({id: "AGREEMENT"}) :
                                                                location.pathname === "/settings/change-phone" ? intl.formatMessage({id: "CHANGE_PHONE"}) :
                                                                    location.pathname === "/settings/change-email" ? intl.formatMessage({id: "CHANGE_EMAIL"}) :
                                                                        location.pathname === "/faq" ? intl.formatMessage({id: "FAQS"}) :
                                                                            location.pathname === "/document/terms-of-use" ? intl.formatMessage({id: "TERMS_OF_USE"}) :
                                                                                location.pathname === "/document/privacy-policy" ? intl.formatMessage({id: "PRIVACY_POLICY"}) :
                                                                                    "SubMenuHeader"}</>
                            {/*<small></small>*/}
                        </h5>
                    </div>

                </div>
            </div>
        </div>
    );
}
