import React from 'react';
import propTypes from 'prop-types';
import {
    CompanyRegisterCarrierIcon,
    IdIconLSP,
    OverviewCarrierRegisterIcon,
    RegistrantCarrierRegisterIcon
} from "../../../../_metronic/_assets/icons";
import {useIntl} from "react-intl";

CarrierRegisterPageStepIcons.propTypes = {
    step: propTypes.number.isRequired
}


function CarrierRegisterPageStepIcons(props) {
    const intl = useIntl();

    return (
        <div className="registration-step-container">


            <div className={`register-icon-container ${props.step === 0 ? "register-icon-active" : ""}`}>
                <div className="d-flex align-items-center">
                    <div
                        className={"registration-icon-background " + (props.step === 0 ? "step-active" : props.step > 0 ? "step-passed" : "step-passive")}>
                        <CompanyRegisterCarrierIcon step={props.step}/>
                    </div>
                    <div className="registration-step-title ml-2" style={{color: '#464E5F'}}>
                        {intl.formatMessage({id:"COMPANY"})} <br/>{intl.formatMessage({id: "DATA"})}
                    </div>
                </div>
                <div className="registration-line"/>
            </div>

            <div className={`register-icon-container ${props.step === 1 ? "register-icon-active" : ""}`}>
                <div className="d-flex align-items-center">
                    <div
                        className={"registration-icon-background " + (props.step === 1 ? "step-active" : props.step > 1 ? "step-passed" : "step-passive")}>
                        <IdIconLSP step={props.step}/>
                    </div>
                    <div className="registration-step-title ml-2" style={props.step >= 1 ? {color: '#464E5F'} : {color:'#B5B5C3'}}>
                        {intl.formatMessage({id: "CONTACT"})} <br/>{intl.formatMessage({id:"INFORMATION"})}
                    </div>
                </div>
                <div className="registration-line"/>
            </div>

            <div className={`register-icon-container ${props.step === 2 ? "register-icon-active" : ""}`}>
                <div className="d-flex align-items-center">
                    <div
                        className={"registration-icon-background " + (props.step === 2 ? "step-active" : props.step > 2 ? "step-passed" : "step-passive")}>
                        <RegistrantCarrierRegisterIcon step={props.step} />
                    </div>
                    <div className="registration-step-title ml-2" style={props.step >= 2 ? {color: '#464E5F'} : {color:'#B5B5C3'}}>
                        {intl.formatMessage({id: "REGISTRANTS"})} <br/>{intl.formatMessage({id: "DATA"})}
                    </div>
                </div>
                <div className="registration-line"/>
            </div>

            <div className={`register-icon-container ${props.step === 3 ? "register-icon-active" : ""}`}>
                <div className="d-flex align-items-center">
                    <div
                        className={"registration-icon-background " + (props.step === 3 ? "step-active" : props.step > 3 ? "step-passed" : "step-passive")}>
                        <OverviewCarrierRegisterIcon step={props.step}/>
                    </div>
                    <div className="registration-step-title ml-2" style={props.step >= 3 ? {color: '#464E5F'} : {color:'#B5B5C3'}}>
                        {intl.formatMessage({id:"OVERVIEW"})}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarrierRegisterPageStepIcons;
