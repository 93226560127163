import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import PersonalData from "../_metronic/_partials/Pages/PersonalData";
import Documents from "../_metronic/_partials/Pages/Documents";
import Settings from "../_metronic/_partials/Pages/Settings";
import SettingsUpdateQuestions from "../_metronic/_partials/Pages/SettingsUpdateQuestions";
import SettingsChangePassword from "../_metronic/_partials/Pages/SettingsChangePassword";
import Agreement from "../_metronic/_partials/Pages/Agreement";
import SettingsChangePhoneEmail from "../_metronic/_partials/Pages/SettingsChangePhoneEmail";
import Faqs from "../_metronic/_partials/Pages/Faqs";
import TermsOfUse from "../_metronic/_partials/Pages/TermsOfUse";
import PrivacyPolicy from "../_metronic/_partials/Pages/PrivacyPolicy";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/personal-data" component={PersonalData} />
        <ContentRoute path="/documents" component={Documents} />
        <ContentRoute path="/faq" component={Faqs} />
        <ContentRoute path="/settings" component={Settings} exact />
        <ContentRoute path="/settings/update-questions" component={SettingsUpdateQuestions} />
        <ContentRoute path="/settings/change-password" component={SettingsChangePassword} />
        <ContentRoute path="/settings/change-phone" component={SettingsChangePhoneEmail} />
        <ContentRoute path="/settings/change-email" component={SettingsChangePhoneEmail} />
        <ContentRoute path="/document/terms-of-use" component={TermsOfUse} />
        <ContentRoute path="/document/privacy-policy" component={PrivacyPolicy} />
        <ContentRoute path="/settings/agreement" component={Agreement} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
