import React, {useEffect, useState} from 'react';
import DashboardSettingsHeader from "./DashboardSettingsHeader";
import {BackIcon, ButtonArrowIcon} from "../../_assets/icons";
import DashboardInput from "./DashboardInput";
import FormButton from "../../../app/modules/Auth/Components/FormButton";
import propTypes from 'prop-types';
import {useIntl} from "react-intl";
import {setEmailUpdateEmail} from "../../../app/modules/Auth/_redux/authCrud";

DashboardChangeEmail.propTypes = {
    iconOnClick : propTypes.func.isRequired,
    changePage : propTypes.func.isRequired
}

function DashboardChangeEmail(props) {
    const intl = useIntl();
    const [buttonDisable, setButtonDisable] = useState(true);
    const [email,setEmail] = useState("");
    const [emailError,setEmailError] = useState({status:false,message:''});

    const _emailChecker = () => {
        const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
        if(email.match(emailRegex) && email.length > 0) {
            setEmailError({status:false,message:''});
            setButtonDisable(false);
        } else if (!email.match(emailRegex) && email.length > 0 ) {
            setButtonDisable(true);
            setEmailError({status:true,message:intl.formatMessage({id:"EMAIL_ERROR"})})
        }
    };

    useEffect(_emailChecker,[email]);

    const _submit = () => {
        setButtonDisable(true);
        setEmailUpdateEmail(email)
            .then(data => {
                props.changePage();
            })
            .catch(err => {
                console.log("error happen when setting new email on update email");
                setEmailError({status:true,message:err?.response?.data?.errorMessage})
                setButtonDisable(false);
            })
    }


    return (
        <div className="dashboard-form-area">
            <DashboardSettingsHeader
                title={intl.formatMessage({id: "CHANGE_EMAIL"})}
                icon={<BackIcon/>}
                iconOnClick={props.iconOnClick}
                desc={
                    <p className="widget-freights-desc-text mt-5 ml-2" style={{fontWeight: '500'}}>
                        {intl.formatMessage({id: "CHANGE_EMAIL_DESC"})}
                    </p>
                }
            />

            <div className="row mt-10 ml-5 position-relative">
                <div className="col-12" id="password-container">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id: "NEW_EMAIL"})} <span
                            style={{color: 'red'}}>*</span></p>
                    </div>
                    <DashboardInput
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        inputType={"text"}
                        error={emailError}
                        placeholder={intl.formatMessage({id: "NEW_EMAIL"})}
                    />
                </div>


            </div>

            <div className="d-flex justify-content-end mt-10 ml-5">
                <FormButton
                    icon={<ButtonArrowIcon/>}
                    title={"Next"}
                    isDisabled={buttonDisable}
                    isPassive={buttonDisable}
                    onClick={_submit}
                />
            </div>

        </div>
    );
}

export default DashboardChangeEmail;
