import React, {useState} from 'react';
import propTypes from 'prop-types';
import {EyeIcon, EyeInvisibleIcon} from "../../_assets/icons";

DashboardInput.propTypes = {
    value : propTypes.string,
    onChange : propTypes.func,
    inputType : propTypes.string,
    maxLength : propTypes.number,
    placeholder : propTypes.string,
    error : propTypes.object
}

DashboardInput.defaultProps = {
    error : {status:false,message:''},
    inputType : 'text'
}

function DashboardInput(props) {
    const [passwordInputType,setPasswordInputType] = useState("password");

    const _passwordTypeHandler = () => {
        if (passwordInputType === "password") {
            setPasswordInputType("text")
        } else {
            setPasswordInputType("password")
        }
    }

    return (
        <div className="position-relative">
        <input
            type={props.inputType === "password" ? passwordInputType : props.inputType}
            value={props.value}
            onChange={e => props.onChange(e)}
            className="dashboard-input"
            maxLength={props.maxLength && props.maxLength}
            placeholder={props.placeholder ? props.placeholder : ""}
        />
            {
                (props.error.message && props.error.status) &&
                <div className="form-error-text mt-3 mb-0">{props.error.message}</div>
            }

            {
                props.inputType === "password" &&
                <div className={"dashboard-form-eye-icon"} onClick={()=>_passwordTypeHandler()}>
                    {
                        passwordInputType === "password" ?
                            <EyeInvisibleIcon/> : <EyeIcon/>
                    }
                </div>
            }
        </div>
    );
}

export default DashboardInput;
