import React, { useState, useMemo } from "react";
import TooltipComponent from "../../../../_metronic/_partials/Components/TooltipComponent";
import { useIntl } from "react-intl";
import CarrierSelect from "./CarrierSelect";
import { getVatCountries } from "../_redux/authCrud";

function VatInput(props) {
  const intl = useIntl();
  const [vatCountries, setVatCountries] = useState([]);
  useMemo(() => {
    getVatCountries()
      .then(({ data }) => {
        if (data?.vatNumberPrefixes?.length) {
          setVatCountries(
            data.vatNumberPrefixes.map(item => ({
              value: item.key,
              label: `${item.key} - ${item.value}`
            }))
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  return (
    <div
      className={
        "form-group fv-plugins-icon-container " +
        (props.className ? props.className : "")
      }
    >
      <div
        className={
          "d-flex form-title " +
          (props.headerClassName ? props.headerClassName : "")
        }
      >
        {props.title}
        {props.isRequired && (
          <span style={{ color: "red", marginLeft: ".5em" }}>*</span>
        )}
        {props.questionMark && (
          <TooltipComponent
            tooltipText={intl.formatMessage({ id: "VAT_NUMBER_TOOLTIP" })}
          />
        )}
      </div>

      <div
        className={
          "d-flex position-relative " +
          (props.error.status || props.selectError.status ? " form-error" : "")
        }
        style={{ backgroundColor: "#F3F6F9", borderRadius: ".42rem" }}
      >
        <div className="w-75">
          <CarrierSelect
            options={vatCountries}
            isRequired={false}
            marginBottom={false}
            placeholder={intl.formatMessage({ id: "PLEASE_SELECT" })}
            value={props.countryValue}
            onChange={country => {
              props.onCountryChange(country);
            }}
          />
        </div>

        <input
          placeholder={props.placeHolder}
          type={props.inputType}
          className={"form-control form-control-solid h-100"}
          name={props.inputName}
          value={props.value}
          style={{ borderRadius: "0 0.42rem 0.42rem 0" }}
          onChange={e => props.onChange(e)}
          maxLength={props.maxLength}
          readOnly={props.readOnly}
        />
      </div>

      {props.selectError.message && props.selectError.status && (
        <div className="form-error-text mt-2 mb-0">
          {props.selectError.message}
        </div>
      )}

      {props.error.message && props.error.status && (
        <div className="form-error-text mt-2 mb-0">{props.error.message}</div>
      )}
    </div>
  );
}

export default VatInput;
