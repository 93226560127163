import React, {useState} from 'react';
import DashboardFormHeader from "../Components/DashboardFormHeader";
import {
    DashboardVerifyMailIcon,
    DashboardVerifyPhoneIcon, EditIconDashboard, ResendIconDashboard,
} from "../../_assets/icons";
import DashboardLeftMenu from "../Components/DashboardLeftMenu";
import {useIntl} from "react-intl";
import ToastComponent from "../../../app/modules/Auth/Components/ToastComponent";
import {Link} from 'react-router-dom';
import {useSelector} from "react-redux";
import {sendVerificationMail} from "../../../app/modules/Auth/_redux/authCrud";

function PersonalData(props) {
    const intl = useIntl();
    const [toastMenu, setToastMenu] = useState({status: false, descText: '', headerText: '', isWarning: false});
    const {user} = useSelector(state => state.auth);


    const homeAdress = [
        {
            title: intl.formatMessage({id: "COUNTRY"}),
            value: ["country"]
        },
        {
            title: intl.formatMessage({id: "CITY"}),
            value: ["city"]
        },
        {
            title: intl.formatMessage({id: "STREET"}),
            value: ["address"]
        },
        {
            title: intl.formatMessage({id: "ZIP_CODE"}),
            value: ["zipcode"]
        }
    ];
    const postalAdress = [
        {
            title: intl.formatMessage({id: "COUNTRY"}),
            value: ["country"]
        },
        {
            title: intl.formatMessage({id: "CITY"}),
            value: ["city"]
        },
        {
            title: intl.formatMessage({id: "STREET"}),
            value: ["address"]
        },
        {
            title: intl.formatMessage({id: "ZIP_CODE"}),
            value: ["zipcode"]
        }
    ];
    const personalData = [
        {
            title: intl.formatMessage({id: "NAME"}),
            value: ["nameCardname"],
        },
        {
            title: intl.formatMessage({id: "BIRTH_NAME"}),
            value: ["name"],
        },
        {
            title: intl.formatMessage({id: "DATE_OF_BIRTH"}),
            value: ["birthDate"],
        },
        {
            title: intl.formatMessage({id: "COUNTRY_OF_BIRTH"}),
            value: ["birthCountry"],
        },
        {
            title: intl.formatMessage({id: "CITY_OF_BIRTH"}),
            value: ["birthCity"]
        },
        {
            title: intl.formatMessage({id: "ORIGINAL_CITIZENSHIP"}),
            value: ["citizenship"]
        },
        {
            title: intl.formatMessage({id: "ADDITIONAL_CITIZENSHIP"}),
            value: ["citizenship2"]
        },
        {
            title: intl.formatMessage({id: "MOTHER_OR_FATHER_NAME"}),
            value: [
                "parentNameMother",
                "parentNameFather"
            ]
        }
    ];


    const _sendVerificationMail = () => {
        sendVerificationMail()
            .then(({data}) => {
                setToastMenu({
                    status: true,
                    descText: data.message,
                    headerText: intl.formatMessage({id: "SUCCESSFULLY_SENT"}),
                    isWarning: false
                })
            })
            .catch(err => {
                console.log("error happen when sending verification mail")
                setToastMenu({
                    status: true,
                    descText: err?.response?.data?.errorMessage,
                    headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                    isWarning: true
                })
            })
    }

    const dataGenerator = (values) => {
        for (let i = 0; i < values.length; i++) {
            if (user[values[i]]) {
                return <p className="personal-data-form-value">{user[values[i]]}</p>
            }
        }
    }

    return (
        <div className="bg-white w-100 h-100 card card-custom position-relative">
            <div className="row h-100">

                <DashboardLeftMenu/>

                <ToastComponent
                    headerText={toastMenu.headerText}
                    descText={toastMenu.descText}
                    show={toastMenu.status}
                    onClose={() => {
                        setToastMenu({...toastMenu, status: false})
                    }}
                    isWarning={toastMenu.isWarning}
                />

                <div className="col-lg-9 m-0 p-0">
                    <div className="container">

                        <div className="row mt-10 form-edit-container">
                            {
                                personalData.map((data, index) =>
                                    <div className="col-md-4 mb-5" key={index}>
                                        <p className="personal-data-form-title">{data.title}</p>
                                        {dataGenerator(data.value)}
                                    </div>
                                )
                            }
                        </div>

                        <div className="row mt-5 form-verify-container position-relative">

                            <div className="col-lg-12 col-xl-6">
                                <div className="d-flex align-items-center justify-content-start">
                                    <DashboardVerifyMailIcon/>
                                    <div className="ml-4 d-flex align-items-center justify-content-between w-100">
                                        <div style={{lineHeight: '13px'}}>
                                            <p className="personal-data-form-title m-0">{intl.formatMessage({id: "EMAIL"})}</p>
                                            <p className="personal-data-verify-value">{user.contactinfo.email}</p>
                                        </div>
                                        {
                                            !user.contactinfo.emailVerified ?
                                                <div
                                                    className={"personal-data-verify-button cursor-pointer"}>
                                                    {intl.formatMessage({id: "PENDING"})}
                                                </div> :
                                                <div className="personal-data-verified-button">
                                                    {intl.formatMessage({id: "VERIFIED"})}
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="mt-3 d-flex align-items-center justify-content-between"
                                     style={{paddingLeft: 40}}>
                                    <Link to={"/settings/change-email"}>
                                        <EditIconDashboard/>
                                        <span className="dashboard-change-button-text ml-2">
                                            {intl.formatMessage({id: "CHANGE"})}
                                        </span>
                                    </Link>
                                    {
                                        !user.contactinfo.emailVerified &&
                                        <div className="cursor-pointer" onClick={_sendVerificationMail}>
                                        <span className="dashboard-resend-button-text mr-2">
                                            {intl.formatMessage({id: "RESEND_LINK"})}
                                        </span>
                                            <ResendIconDashboard/>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-lg-12 col-xl-5">
                                <div className="d-flex align-items-center justify-content-start">
                                    <DashboardVerifyPhoneIcon/>
                                    <div className="ml-4 d-flex align-items-center justify-content-between w-100">
                                        <div style={{lineHeight: '13px'}}>
                                            <p className="personal-data-form-title m-0">{intl.formatMessage({id: "PHONE"})}</p>
                                            <p className="personal-data-verify-value">{user.contactinfo.phoneprefix + " " + user.contactinfo.phonenumber}</p>
                                        </div>
                                        <div className="personal-data-verified-button">
                                            {intl.formatMessage({id: "VERIFIED"})}
                                        </div>
                                    </div>
                                </div>

                                <Link
                                    to={"/settings/change-phone"}
                                    className="mt-3 d-flex align-items-center justify-content-start"
                                    style={{paddingLeft: 40}}>
                                    <div className="cursor-pointer">
                                        <EditIconDashboard/>
                                        <span className="dashboard-change-button-text ml-2">
                                            {intl.formatMessage({id: "CHANGE"})}
                                        </span>
                                    </div>
                                </Link>
                            </div>

                        </div>


                        <div className="mt-10">
                            <DashboardFormHeader
                                title={intl.formatMessage({id: "HOME_ADDRESS"})}
                                //buttonTitle={intl.formatMessage({id:"EDIT_DETAILS"})}
                                //activeButtonTitle={intl.formatMessage({id:"SAVE_CHANGES"})}
                                //isActive={editMode.editAddress}
                                //onButtonClick={()=>_editModeHandler("editAddress")}
                            />

                            <div className="row mt-10 form-edit-container">
                                {
                                    homeAdress.map((data, index) =>
                                        <div className="col-md-4 mb-5" key={index}>
                                            <p className="personal-data-form-title">{data.title}</p>
                                            <p className="personal-data-form-value">{user.homeaddress[data.value]}</p>
                                        </div>
                                    )
                                }
                            </div>

                        </div>


                        <div className="mt-10">
                            <DashboardFormHeader
                                title={intl.formatMessage({id: "POSTAL_ADDRESS"})}
                                //buttonTitle={intl.formatMessage({id:"EDIT_DETAILS"})}
                                //activeButtonTitle={intl.formatMessage({id:"SAVE_CHANGES"})}
                                //isActive={editMode.editPostal}
                                //onButtonClick={()=>_editModeHandler("editPostal")}
                            />

                            <div className="row mt-10 form-edit-container">
                                {
                                    postalAdress.map((data, index) =>
                                        <div className="col-md-4 mb-5" key={index}>
                                            <p className="personal-data-form-title">{data.title}</p>
                                            <p className="personal-data-form-value">{user.mailaddress[data.value]}</p>
                                        </div>
                                    )
                                }
                            </div>

                        </div>

                        {
                            /*
                            <div className="dashboard-warning mb-20 position-relative" style={{marginRight: '2.5em'}}>
                            <p className="dashboard-warning-title mb-0">{intl.formatMessage({id: "YOUR_DATA_CHANGED"})}</p>
                            <p className="dashboard-warning-desc mb-0">{intl.formatMessage({id: "YOUR_DATA_CHANGED_DESC"})}</p>
                        </div>
                            */
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonalData;


/*

import DashboardInput from "../Components/DashboardInput";
import DashboardSelect from "../Components/DashboardSelect";
import DashboardDatePicker from "../Components/DashboardDatePicker";

import {countriesData} from "../Constants";

const [editMode, setEditMode] = useState({editContact: false, editAddress: false, editPostal: false});

const _editModeHandler = (section) => {
    let newMode = Object.assign({}, editMode);
    newMode[section] = !editMode[section];
    setEditMode(newMode);
}

editMode
{
    editMode.editContact ?
        data.type === "date" ?
            <DashboardDatePicker/>
            :
            data.type === "select" ?
                <DashboardSelect options={countriesData}/>
                :
                <DashboardInput
                    value={data.value}
                    onChange={(e) => _inputHandler(e)}
                /> :
        <p className="personal-data-form-value">{data.value}</p>
}

{
    editMode.editAddress ?
        data.type === "date" ?
            <DashboardDatePicker/>
            :
            data.type === "select" ?
                <DashboardSelect options={countriesData}/>
                :
                <DashboardInput
                    value={data.value}
                    onChange={(e) => _inputHandler(e)}
                /> :
        <p className="personal-data-form-value">{data.value}</p>
}
{
    editMode.editPostal ?
        data.type === "date" ?
            <DashboardDatePicker/>
            :
            data.type === "select" ?
                <DashboardSelect options={countriesData}/>
                :
                <DashboardInput
                    value={data.value}
                    onChange={(e) => _inputHandler(e)}
                /> :
        <p className="personal-data-form-value">{data.value}</p>
}

 */
