import React, {useEffect, useState} from 'react';
import {BackIcon} from "../../_assets/icons";
import DashboardOtp from "../Components/DashboardOTP";
import DashboardChangeQuestions from "../Components/DashboardChangeQuestions";
import FinalForm from "../../../app/modules/Auth/Components/FinalForm";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import ToastComponent from "../../../app/modules/Auth/Components/ToastComponent";

function SettingsUpdateQuestions(props) {
    const intl = useIntl();
    const [page, setPage] = useState(0);
    const [toastMenu, setToastMenu] = useState({status: false, descText: '', headerText: '', isWarning:false});


    const _redirectLogin = () => {
        if(page === 3) {
            setTimeout(()=>{
                props.history.push('/dashboard')
            },6000)
        }
    }

    useEffect(_redirectLogin,[page])

    return (
        <div className="bg-white w-100 h-100 card card-custom p-10">
            <ToastComponent
                headerText={toastMenu.headerText}
                descText={toastMenu.descText}
                show={toastMenu.status}
                onClose={() => {
                    setToastMenu({...toastMenu, status: false})
                }}
                isWarning={toastMenu.isWarning}
            />
            {
                page === 0 ?
                    <DashboardOtp
                        headerTitle={intl.formatMessage({id: "UPDATE_SECURITY_QUESTIONS"})}
                        headerIcon={<BackIcon/>}
                        iconOnClick={() => props.history.push('/settings')}
                        changePage={() => setPage(1)}
                        from={"dashboardUpdateSecurity"}
                        setToastMenu={setToastMenu}
                    /> :
                    page === 1 ?
                        <DashboardChangeQuestions
                            iconOnClick={() => setPage(0)}
                            changePage={() => setPage(2)}
                            setToastMenu={setToastMenu}
                        /> :
                        <FinalForm
                            text={
                                <>
                                    <div
                                        className="finalFormText">{intl.formatMessage({id: "UPDATE_SECURITY_QUESTIONS_COMPLETE_TEXT"})}</div>
                                    <Link
                                        to="/dashboard"
                                        className="text-dark-50 text-hover-primary my-3 mr-2 mt-5"
                                    >
                                        <u style={{color: "#3699FF", marginLeft: 5}}>
                                            {intl.formatMessage({id: "GO_TO_DASHBOARD"})}
                                        </u>
                                    </Link>
                                </>
                            }/>
            }
        </div>
    );
}

export default SettingsUpdateQuestions;
