import React from 'react';
import propTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import parsePhoneNumber from "libphonenumber-js";
import DashboardSelect from "../../../../_metronic/_partials/Components/DashboardSelect";
import {useIntl} from "react-intl";

PhoneInputComponent.propTypes = {
    title: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    error: propTypes.object
}

PhoneInputComponent.defaultProps = {
    countryCodeError : {status:false,message:''}
}


function PhoneInputComponent(props) {
    const intl = useIntl();

    const _numberHandler = async (phone) => {
        const numberRegex = /[0-9]/g;
        if (phone[phone.length - 1] && !phone[phone.length - 1].match(numberRegex)) {
            return;
        }
        if (phone !== "" && phone.length > 3 && phone.match(numberRegex) === phone.length) {
            const newPhoneNumber = parsePhoneNumber(props.countryCode.label + phone);
            const formatPhoneNumber = newPhoneNumber.formatInternational();
            let turnArray = formatPhoneNumber.split(" ");
            props.onChange(turnArray.slice(1, 99).join(' '));
        } else {
            props.onChange(phone)
        }
    }

    return (
        <>

                <div className="col-md-3">
                    <div className="d-flex align-items-center mb-2">
                        <p className={"form-title mb-0 " + (props.headerClassName ? props.headerClassName : "")}>
                            {intl.formatMessage({id: "COUNTRY_CODE"})}
                            <span style={{color: 'red'}}> *</span>
                        </p>
                    </div>
                    <DashboardSelect
                        value={props.countryCode}
                        options={props.phonePrefixes}
                        onChange={e => props.setCountryCode(e)}
                        backgroundColor={"#F3F6F9"}
                        minHeight={50}
                        error={props.countryCodeError.status}
                        menuWidth={"auto"}
                    />
                    {
                        (props.countryCodeError.message && props.countryCodeError.status) &&
                        <div className="form-error-text mt-2 mb-0">{props.countryCodeError.message}</div>
                    }
                </div>
                <div className="col-md-9">
                    <div className="d-flex align-items-center mb-2">
                        <p className={"form-title mb-0 " + (props.headerClassName ? props.headerClassName : "")}>
                            {intl.formatMessage({id: "YOUR_PHONE_NUMBER"})} <span
                            style={{color: 'red'}}>*</span></p>
                    </div>
                    <input
                        placeholder={intl.formatMessage({id: "YOUR_PHONE_NUMBER"})}
                        type={"text"}
                        className={"form-control form-control-solid " + (props.error.status ? " form-error" : "")}
                        value={props.value}
                        onChange={(e) => _numberHandler(e.target.value)}
                        maxLength={17}
                    />
                    {
                        (props.error.message && props.error.status) &&
                        <div className="form-error-text mt-2 mb-0">{props.error.message}</div>
                    }
                </div>

        </>

    );
}

export default PhoneInputComponent;
