import React, {useState} from 'react';
import DashboardAgreementFirst from "../Components/DashboardAgreementFirst";
import FinalForm from "../../../app/modules/Auth/Components/FinalForm";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

function Agreement(props) {
    const intl = useIntl();
    const {user} = useSelector(state => state.auth);
    const _initialPage = () => {
        if(user.membershipDeclarationStatus === "accepted") {
            return 1;
        } else {
            return 0;
        }
    }
    const [page,setPage] = useState(_initialPage());
    return (
        <div className="bg-white w-100 h-100 card card-custom p-8">
            {
                page === 0 ?
                    <DashboardAgreementFirst history={props.history} changePage={()=>setPage(1)}/>
                    :
                    <FinalForm
                        text={
                            <>
                                <div className="finalFormText" style={{whiteSpace:'break-spaces',maxWidth:'60%'}}>
                                    {intl.formatMessage({id: "AGREEMENT_PAGE_COMPLETE_TEXT"})}
                                </div>
                                <Link
                                    to="/dashboard"
                                    className="text-dark-50 text-hover-primary my-3 mr-2 mt-5"
                                >
                                    <u style={{color: "#3699FF", marginLeft: 5}}>
                                        {intl.formatMessage({id:"GO_TO_DASHBOARD"})}
                                    </u>
                                </Link>
                            </>
                        }
                        disableDownloadApp
                    />
            }
        </div>
    );
}

export default Agreement;
