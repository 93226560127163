import React, { useState } from "react";
import FormHeader from "./FormHeader";
import FormInput from "./FormInput";
import {
  BackIcon,
  ButtonArrowIcon,
  ButtonBackIcon,
  ButtonTimesIcon,
  EditIconDashboard
} from "../../../../_metronic/_assets/icons";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { useIntl } from "react-intl";
import CarrierRegisterPageStepIcons from "./CarrierRegisterPageStepIcons";
import PhoneInputComponent from "./PhoneInputComponent";
import CarrierSelect from "./CarrierSelect";
import TooltipComponent from "../../../../_metronic/_partials/Components/TooltipComponent";

function CarrierRegistrationRegistrants(props) {
  const intl = useIntl();
  const [inputs, setInputs] = useState({
    name: "",
    position: "",
    email: "",
    phoneNumber: "",
    countryCode: { label: "", value: "" }
  });
  const [errors, setErrors] = useState({
    name: { status: false, message: "" },
    position: { status: false, message: "" },
    email: { status: false, message: "" },
    phoneNumber: { status: false, message: "" },
    registrant: { status: false, message: "" },
    countryCode: { status: false, message: "" }
  });

  const _inputOnChange = e => {
    let newData = Object.assign({}, inputs);
    newData[e.target.name] = e.target.value;
    setInputs(newData);
  };

  const _editRegistrants = registrant => {
    setInputs(registrant);
  };

  const _errorHandler = () => {
    let passedFields = 0;
    let newError = {
      name: { status: false, message: "" },
      position: { status: false, message: "" },
      email: { status: false, message: "" },
      phoneNumber: { status: false, message: "" },
      countryCode: { status: false, message: "" }
    };

    const _numberController = () => {
      const numberRegex = /[0-9]/g;
      if (
        inputs?.phoneNumber?.length > 0 &&
        inputs?.phoneNumber?.match(numberRegex)?.length ===
          inputs?.phoneNumber?.length
      ) {
        return true;
      } else {
        return false;
      }
    };

    const regexString3Chars = /(([^0-9-!@$%^&*()_+|~#=`{}[/\]:";'<>?,])){3}/g; //Its accepts just strings and length equal or over than 5
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

    let fieldControl = [
      {
        fieldName: "name",
        fieldControl: regexString3Chars,
        message: intl.formatMessage({ id: "NAME_ERROR" })
      },
      {
        fieldName: "email",
        fieldControl: emailRegex,
        message: intl.formatMessage({ id: "EMAIL_ERROR" })
      }
    ];

    for (let i = 0; i < fieldControl.length; i++) {
      if (
        inputs[fieldControl[i].fieldName].match(fieldControl[i].fieldControl)
      ) {
        newError[fieldControl[i].fieldName] = { status: false, message: "" };
        passedFields++;
      } else {
        newError[fieldControl[i].fieldName] = {
          status: true,
          message: fieldControl[i].message
        };
      }
    }

    //Position Controller
    if (inputs.position !== "" && inputs.position.label !== "") {
      passedFields++;
    } else {
      newError.position = {
        status: true,
        message: intl.formatMessage({ id: "SELECT_ERROR" })
      };
    }

    //Phone Controller
    if (_numberController() && inputs.phoneNumber.length > 0) {
      newError.phoneNumber = { status: false, message: "" };
      passedFields++;
    } else if (!_numberController()) {
      newError.phoneNumber = {
        status: true,
        message: intl.formatMessage({ id: "PHONE_ERROR" })
      };
    }

    //Country Code Controller
    if (inputs.countryCode.label === "") {
      newError.countryCode = {
        status: true,
        message: intl.formatMessage({ id: "SELECT_ERROR" })
      };
    } else {
      newError.countryCode = { status: false, message: "" };
    }

    if (passedFields === 4) {
      setErrors({
        name: { status: false, message: "" },
        position: { status: false, message: "" },
        email: { status: false, message: "" },
        phoneNumber: { status: false, message: "" }
      });
      return true;
    } else {
      setErrors(newError);
      return false;
    }
  };

  const _addRegistrant = () => {
    if (_errorHandler()) {
      let newRegistrant = {
        name: inputs.name,
        position: inputs.position,
        email: inputs.email,
        phoneNumber: inputs.phoneNumber,
        id: inputs.id || props.data.registrants.length + 1,
        countryCode: {
          label: inputs.countryCode.label,
          value: inputs.countryCode.value
        }
      };
      let newData = Object.assign({}, props.data);
      if (inputs.id) {
        let filterArray = newData.registrants.filter(item => {
          return item.id !== newRegistrant.id;
        });
        let newArray = [newRegistrant, ...filterArray];
        let sortItems = newArray.sort(function(a, b) {
          return a.id - b.id;
        });
        newData.registrants = sortItems;
      } else {
        newData.registrants.push(newRegistrant);
      }
      props.setData(newData);
      setErrors({
        name: { status: false, message: "" },
        position: { status: false, message: "" },
        email: { status: false, message: "" },
        phoneNumber: { status: false, message: "" },
        registrant: { status: false, message: "" }
      });
      setInputs({
        name: "",
        position: "",
        email: "",
        phoneNumber: "",
        countryCode: { label: "", value: "" }
      });
    }
  };

  const _deleteRegistrant = registrant => {
    let newRegistrants = Object.assign({}, props.data);
    let filteredRegistrants = props.data.registrants.filter(item => {
      return item.id !== registrant.id;
    });
    newRegistrants.registrants = filteredRegistrants;
    props.setData(newRegistrants);
  };

  const _nextController = () => {
    let inputControls =
      inputs?.name === "" &&
      inputs?.position === "" &&
      inputs?.email === "" &&
      inputs?.phoneNumber === "" &&
      inputs?.countryCode?.label === "";
    if (!inputControls) {
      let newError = Object.assign({}, errors);
      newError.registrant = {
        status: true,
        message: intl.formatMessage({
          id: "ADD_REGISTRANT_PLEASE_COMPLETE_FORM"
        })
      };
      setErrors(newError);
      return;
    }
    if (props.data.registrants.length > 0) {
      props.changePage();
    } else {
      let newError = Object.assign({}, errors);
      newError.registrant = {
        status: true,
        message: intl.formatMessage({ id: "ADD_REGISTRANT_ERROR" })
      };
      setErrors(newError);
    }
  };

  return (
    <div
      className="d-flex flex-column flex-row-fluid position-relative form-area"
      style={{ overflowY: "auto" }}
    >
      <div className="d-flex flex-column-fluid align-items-stretch justify-content-start">
        <div className="login-form login-signin d-flex align-items-stretch justify-content-center flex-column">
          <div className="mb-10">
            <FormHeader
              title={intl.formatMessage({ id: "CARRIER_REGISTER_FORM_TITLE" })}
              desc={
                <p className="form-head-desc">
                  {intl.formatMessage({ id: "REGISTER_FORM_DESC" })}
                </p>
              }
              icon={<BackIcon />}
              iconOnClick={props.iconOnClick}
            />

            <div
              style={{ justifySelf: "flex-start" }}
              id="registration-page-div"
            >
              <CarrierRegisterPageStepIcons step={2} />
            </div>
          </div>
          {props.data.registrants.length > 0 && (
            <div className="container p-0 mb-10">
              <div className="d-flex align-items-center justify-content-start">
                <h5 className="form-title">Added Registrants</h5>
                <TooltipComponent
                  tooltipText={intl.formatMessage({
                    id: "ADDED_REGISTRANTS_TOOLTIP"
                  })}
                />
              </div>
              <div className="row pb-5 mt-5">
                {props.data.registrants.map((registrant, index) => (
                  <div className="col-md-4" key={index} id="registrant">
                    <div
                      className={
                        "btn form-button btn-primary w-100 d-flex justify-content-between align-items-center"
                      }
                    >
                      <span>{registrant.name}</span>
                      <div className="d-flex align-items-center">
                        <div
                          onClick={e => {
                            e.stopPropagation();
                            _editRegistrants(registrant);
                          }}
                        >
                          <EditIconDashboard />
                        </div>
                        <div
                          className="ml-1"
                          onClick={e => {
                            e.stopPropagation();
                            _deleteRegistrant(registrant);
                          }}
                        >
                          <ButtonTimesIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="container p-0 mb-10">
            <div className="row">
              <div className="col-md-6">
                <FormInput
                  title={intl.formatMessage({ id: "NAME" })}
                  inputType={"text"}
                  inputName={"name"}
                  placeHolder={intl.formatMessage({ id: "NAME" })}
                  isRequired
                  error={errors.name}
                  value={inputs.name}
                  onChange={e => _inputOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-6">
                <CarrierSelect
                  title={intl.formatMessage({ id: "POSITION" })}
                  options={props.allPositions}
                  value={inputs.position}
                  onChange={position =>
                    _inputOnChange({
                      target: {
                        value: position,
                        name: "position"
                      }
                    })
                  }
                  error={errors.position}
                  placeholder={intl.formatMessage({ id: "PLEASE_SELECT" })}
                  headerClassName={"light"}
                />
              </div>
              <div className="col-md-12">
                <FormInput
                  title={intl.formatMessage({ id: "EMAIL" })}
                  inputType={"text"}
                  inputName={"email"}
                  placeHolder={intl.formatMessage({ id: "EMAIL" })}
                  isRequired
                  error={errors.email}
                  value={inputs.email}
                  onChange={e => _inputOnChange(e)}
                  headerClassName={"light"}
                />
              </div>
              <PhoneInputComponent
                title={intl.formatMessage({ id: "PHONE_NUMBER" })}
                value={inputs.phoneNumber}
                error={errors.phoneNumber}
                countryCodeError={errors.countryCode}
                phonePrefixes={props.phonePrefixes}
                countryCode={inputs.countryCode}
                setCountryCode={countryCode =>
                  _inputOnChange({
                    target: {
                      name: "countryCode",
                      value: countryCode
                    }
                  })
                }
                onChange={phone =>
                  _inputOnChange({
                    target: {
                      name: "phoneNumber",
                      value: phone
                    }
                  })
                }
                headerClassName={"light"}
              />
              <div className="col-md-12">
                {errors.registrant && errors.registrant.status && (
                  <div className="form-error-text mt-2 mb-0">
                    {errors.registrant.message}
                  </div>
                )}
              </div>
              <div className="col-md-12 d-flex justify-content-end w-100">
                <button
                  className={`create-account-button red mt-4`}
                  style={{ margin: "0" }}
                  onClick={_addRegistrant}
                >
                  {props.leftButtonIcon && props.leftButtonIcon}
                  {inputs.id > -1 ? (
                    <span>
                      {intl.formatMessage({ id: "UPDATE_REGISTRANT" })}
                    </span>
                  ) : (
                    <span>{intl.formatMessage({ id: "ADD_REGISTRANT" })}</span>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <button
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              onClick={props.iconOnClick}
            >
              <div className="d-flex flex-center">
                <ButtonBackIcon />
                <span>{intl.formatMessage({ id: "BACK" })}</span>
              </div>
            </button>

            <div className="d-flex flex-center">
              <button
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                onClick={_nextController}
              >
                <div className="d-flex flex-center">
                  <span>{intl.formatMessage({ id: "NEXT_BUTTON" })}</span>
                  <ButtonArrowIcon />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(
  connect(null, auth.actions)(CarrierRegistrationRegistrants)
);
