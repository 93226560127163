import React, {useEffect, useState} from 'react';
import Accordion from "../Components/Accordion";
import {getFaqContent} from "../../../app/modules/Auth/_redux/authCrud";

function Faqs(props) {
    const [currentIndex,setCurrentIndex] = useState(-1);
    const [faqList,setFaqList] = useState([]);
    const [loading,setLoading] = useState(true);

    const _getFaq = () => {
        getFaqContent()
            .then(({data}) => {
                setFaqList(data);
                setLoading(false);
            })
            .catch(err => {
                console.log("error happen when getting faq content")
                setLoading(false);
            })
    }

    useEffect(_getFaq,[]);

    return (
        <div className="bg-white w-100 h-100 card card-custom">
            {
                !loading &&
                <div className="container p-10">
                    {
                        faqList.map((faq,index) =>
                            <Accordion
                                key={index}
                                title={faq.title}
                                desc={faq.content}
                                accordionIndex={index}
                                currentIndex={currentIndex}
                                setCurrentIndex={(index)=>setCurrentIndex(index)}
                            />
                        )
                    }
                </div>
            }
        </div>
    );
}

export default Faqs;
