import React from 'react';
import propTypes from 'prop-types';

PasswordStrengthBar.propTypes = {
    percentage : propTypes.string.isRequired
}

const colors = {
    weak : '#FF4B55',
    strong : '#00C233A6'
}

function PasswordStrengthBar(props) {
    return (
        <div className="mb-5">
            <div className="passwordBar">
                <div className="strengthBar" style={{width : (props.percentage+"%"),backgroundColor:(Number(props.percentage) === 100 ? colors.strong : colors.weak)}}/>
            </div>
            {
                Number(props.percentage) !== 0 &&
                <div className="d-flex align-items-center justify-content-between mt-2">
                    {
                        Number(props.percentage) === 100 ?
                            <p style={{color:colors.strong}}>Strong Password</p> :
                                <p style={{color:colors.weak}}>Weak Password</p>
                    }
                </div>
            }
        </div>
    );
}

export default PasswordStrengthBar;
