import React, {useState} from 'react';
import FinalForm from "../../../app/modules/Auth/Components/FinalForm";
import {Link} from "react-router-dom";
import DashboardOtp from "../Components/DashboardOTP";
import {BackIcon} from "../../_assets/icons";
import {useIntl} from "react-intl";
import DashboardChangePhoneFirstPage from "../Components/DashboardChangePhoneFirstPage";
import DashboardChangePhone from "../Components/DashboardChangePhone";
import DashboardChangeEmail from "../Components/DashboardChangeEmail";
import ToastComponent from "../../../app/modules/Auth/Components/ToastComponent";

function SettingsChangePhoneEmail(props) {
    const intl = useIntl();
    const [page, setPage] = useState(0);
    const [toastMenu, setToastMenu] = useState({status: false, descText: '', headerText: '', isWarning:false});
    const order = () => {
        if(props.location.pathname === "/settings/change-phone") {
            return "phone";
        } else if (props.location.pathname === "/settings/change-email") {
            return "email";
        }
    }

    return (
        <div className="bg-white w-100 h-100 card card-custom p-10">
            <ToastComponent
                headerText={toastMenu.headerText}
                descText={toastMenu.descText}
                show={toastMenu.status}
                onClose={() => {
                    setToastMenu({...toastMenu, status: false})
                }}
                isWarning={toastMenu.isWarning}
            />
            {
                page === 0 ?
                    <DashboardChangePhoneFirstPage
                        iconOnClick={() => props.history.push('/settings')}
                        changePage={() => setPage(1)}
                        order={order()}
                        setToastMenu={setToastMenu}
                    /> :
                    page === 1 ?
                        <DashboardOtp
                            headerTitle={intl.formatMessage({id: "ENTER_VERIFICATION_CODE"})}
                            headerIcon={<BackIcon/>}
                            iconOnClick={() => setPage(0)}
                            changePage={() => setPage(2)}
                            from={order() === "email" ? "dashboardUpdateEmail" : "dashboardUpdatePhone"}
                            setToastMenu={setToastMenu}
                        /> :
                        page === 2 ?
                            order() === "phone" ?
                                <DashboardChangePhone
                                    iconOnClick={() => setPage(1)}
                                    changePage={() => setPage(3)}
                                /> :
                                <DashboardChangeEmail
                                    iconOnClick={() => setPage(1)}
                                    changePage={() => setPage(3)}
                                /> :
                            page === 3 ?
                                <DashboardOtp
                                    headerTitle={intl.formatMessage({id: "ENTER_VERIFICATION_CODE"})}
                                    headerIcon={<BackIcon/>}
                                    headerDesc={
                                        <p className="widget-freights-desc-text mt-5 ml-2" style={{fontWeight: '500'}}>
                                            We’ve just sent a varification code to <b style={{fontWeight: '600'}}>+36 3x
                                            x23x11x</b>
                                        </p>
                                    }
                                    iconOnClick={() => setPage(2)}
                                    changePage={() => setPage(4)}
                                    from={order() === "email" ? "dashboardValidationUpdateEmail" : "dashboardValidationUpdatePhone"}
                                /> :
                                <FinalForm
                                    text={
                                        <>
                                            {
                                                order() === "phone" ?
                                                    <div className="finalFormText" style={{whiteSpace:'pre-line'}}>
                                                        {intl.formatMessage({id: "CHANGE_PHONE_COMPLETE_TEXT"})}
                                                    </div> :
                                                    <div className="finalFormText" style={{whiteSpace:'pre-line'}}>
                                                        {intl.formatMessage({id: "CHANGE_EMAIL_COMPLETE_TEXT"})}
                                                    </div>
                                            }
                                            <Link
                                                to="/dashboard"
                                                className="text-dark-50 text-hover-primary my-3 mr-2 mt-5"
                                            >
                                                <u style={{color: "#3699FF", marginLeft: 5}}>
                                                    {intl.formatMessage({id: "GO_TO_DASHBOARD"})}
                                                </u>
                                            </Link>
                                        </>
                                    }
                                />
            }
        </div>
    );
}

export default SettingsChangePhoneEmail;
