import React from 'react';
import DashboardButton from "./DashboardButton";
import propTypes from 'prop-types';

DashboardFormHeader.propTypes = {
    title : propTypes.string.isRequired,
    activeButtonTitle : propTypes.string,
    buttonTitle : propTypes.string,
    onButtonClick : propTypes.func,
    isActive : propTypes.bool
}
DashboardFormHeader.defaultProps = {
    isActive : false
}
function DashboardFormHeader(props) {
    return (
        <div className="personal-data-header-container">
            <h3 className="widget-header-text">{props.title}</h3>
            {
                props.buttonTitle &&
                <DashboardButton
                    buttonTitle={props.buttonTitle}
                    onButtonClick={props.onButtonClick}
                    isActive={props.isActive}
                    activeButtonTitle={props.activeButtonTitle}
                />
            }
        </div>
    );
}

export default DashboardFormHeader;
