import React, {useEffect, useState} from 'react';
//import {Dropdown} from "react-bootstrap";
//import {DropdownCustomToggler, DropdownMenu2} from "../../dropdowns";
//import {WidgetMenuIcon} from "../../../_assets/icons";
import {
    DashboardProfileArrowIcon,
    DashboardProfileCheckIcon,
} from "../../../_assets/icons";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import propTypes from 'prop-types';
import {getDriverImage} from "../../../../app/modules/Auth/_redux/authCrud";

ProfileInformationWidget.propTypes = {
    name: propTypes.string.isRequired,
    country: propTypes.string.isRequired,
    dateOfBirth: propTypes.string.isRequired,
    countryOfBirth: propTypes.string.isRequired,
    cityOfBirth: propTypes.string.isRequired,
    mothersName: propTypes.string,
    email: propTypes.string.isRequired,
    phoneNumber: propTypes.string.isRequired
}

function ProfileInformationWidget(props) {
    const intl = useIntl();
    const [driverPhoto,setDriverPhoto] = useState(null);

    const _getProfilePhoto = () => {
        getDriverImage()
            .then(({data}) => {
                setDriverPhoto(data.fileData);
            })
            .catch(err => console.log(err.response))
    }

    useEffect(_getProfilePhoto,[]);

    return (
        <div className={`card card-custom widget-container card-stretch gutter-b`}>
            {/* Header */}
            <div className="d-flex align-items-center justify-content-between widget-header">
                <h3 className="widget-header-text">{intl.formatMessage({id: "PERSONAL_DATA"})}</h3>
                {
                    /*
                    //DROPDOWN MENU COMMENTED MVP VERSION

                    <div className="card-toolbar">
                    <Dropdown className="dropdown-inline" alignRight>
                        <Dropdown.Toggle
                            variant="transparent"
                            id="dropdown-toggle-top"
                            className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            as={DropdownCustomToggler}
                        >
                            <WidgetMenuIcon/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <DropdownMenu2/>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                    */
                }
            </div>
            {/* Body */}
            <div className="card-body p-0 d-flex flex-column justify-content-between" style={{height: 'auto'}}>
                <div className="d-flex flex-column flex-center">
                    <img
                        src={`data:image/jpeg;base64,${driverPhoto}`}
                        alt="driver"
                        className="driverImage"
                        style={{display: driverPhoto ? 'block' : "none"}}
                    />
                </div>


                <div className="w-100">

                    <div>
                        <hr style={{borderWidth: 1, borderColor: '#E5EAEE'}}/>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mb-5">
                        <p className="widget-profile-desc-text">{intl.formatMessage({id: "EMAIL"})}</p>
                        <p className="widget-profile-text">{props.email}</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <p className="widget-profile-desc-text">{intl.formatMessage({id: "PHONE_NUMBER"})}</p>
                        <div className="d-flex flex-center">
                            <p className="widget-profile-text mr-2">{props.phoneNumber}</p>
                            <DashboardProfileCheckIcon/>
                        </div>
                    </div>

                </div>

                <Link to={"/personal-data"} className="d-flex flex-center my-5">
                    <p className="widget-profile-text mr-2">{intl.formatMessage({id: "VIEW_FULL_PROFILE"})}</p>
                    <DashboardProfileArrowIcon/>
                </Link>


            </div>

        </div>
    );
}

export default ProfileInformationWidget;

/*
<div className="w-100">

                    <div className="d-flex align-items-center justify-content-between">
                        <p className="widget-profile-desc-text">{intl.formatMessage({id:"DATE_OF_BIRTH"})}</p>
                        <p className="widget-profile-text">{props.dateOfBirth}</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <p className="widget-profile-desc-text">{intl.formatMessage({id:"COUNTRY_OF_BIRTH"})}</p>
                        <p className="widget-profile-text">{props.countryOfBirth}</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <p className="widget-profile-desc-text">{intl.formatMessage({id:"CITY_OF_BIRTH"})}</p>
                        <p className="widget-profile-text">{props.cityOfBirth}</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <p className="widget-profile-desc-text">{intl.formatMessage({id:"MOTHERS_NAME"})}</p>
                        <p className="widget-profile-text">{props.mothersName}</p>
                    </div>

                </div>

                <div>
                    <hr style={{borderWidth: 1, borderColor: '#E5EAEE'}}/>
                </div>
 */
