import React from 'react';
import propTypes from 'prop-types';

FormHeader.propTypes = {
    icon: propTypes.any,
    iconOnClick: propTypes.func,
    title: propTypes.string.isRequired,
    desc: propTypes.any
}

function FormHeader(props) {
    return (
        <div className="d-flex">
            {
                props.icon &&
                <div className="mt-2 pointer" onClick={props.iconOnClick && props.iconOnClick}>
                    {props.icon}
                </div>
            }
            <div className={`text-start mb-5 mb-lg-5 ${props.icon ? "ml-2" : ""}`}>
                <h3 className="form-head-text">
                    {props.title}
                </h3>
                    {props.desc}
            </div>
        </div>
    );
}

export default FormHeader;
