import React, {useState, useEffect} from 'react';
import FormHeader from "./FormHeader";
import FormInput from "./FormInput";
import FormButton from "./FormButton";
import PasswordStrengthBar from "./PasswordStrengthBar";
import propTypes from 'prop-types';
import {BackIcon, ButtonArrowIcon} from "../../../../_metronic/_assets/icons";
import PasswordValidationTexts from "./PasswordValidationTexts";
import {changePasswordPasswordReset, createPasswordRegister} from "../_redux/authCrud";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../_redux/authRedux";
import {useIntl} from "react-intl";
import RegisterPageStepIcons from "./RegisterPageStepIcons";

GeneratePassword.propTypes = {
    title: propTypes.string.isRequired,
    desc: propTypes.any,
    iconOnClick: propTypes.func,
    changePage: propTypes.func.isRequired,
    from: propTypes.string.isRequired
}

function GeneratePassword(props) {
    const intl = useIntl();
    const [password, setPassword] = useState('');
    const [passwordRetry, setPasswordRetry] = useState('');
    const [passwordError, setPasswordError] = useState({status: false, message: ''});
    const [buttonDisable, setButtonDisable] = useState(true);
    const [passwordStrength, setPasswordStrength] = useState(0);

    const passwordChecker = () => {
        if (password === passwordRetry && passwordStrength === 100) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
        if (passwordRetry.length > 1 && passwordRetry !== password) {
            setPasswordError({status: true, message: intl.formatMessage({id: "PASSWORD_NOT_MATCH"})})
        } else {
            setPasswordError({status: false, message: ''})
        }
    }

    useEffect(passwordChecker, [password, passwordRetry]);

    const _submitForm = () => {
        setButtonDisable(true);
        switch (props.from) {
            case "register" :
                createPasswordRegister(password, passwordRetry)
                    .then(({data}) => {
                        props.changePage();
                    })
                    .catch(err => {
                        console.log("error happen when creating password");
                        setPasswordError({status: true, message: err?.response?.data?.errorMessage});
                        setButtonDisable(false);
                    })
                break;
            case "forgotPassword" :
                changePasswordPasswordReset(password, passwordRetry)
                    .then(({data}) => {
                        props.changePage();
                    })
                    .catch(err => {
                        console.log("error happen when changing password on password reset");
                        setPasswordError({status: true, message: err?.response?.data?.errorMessage});
                        setButtonDisable(false);
                    })
                break;
            default :
                console.log('from props doesnt sended');
        }

    }

    return (
        <div className="d-flex flex-column flex-row-fluid position-relative form-area">
            {
                props.from === "register" &&
                <div style={{justifySelf:'flex-start'}} id="registration-page-div">
                    <RegisterPageStepIcons step={2}/>
                </div>
            }
            <div className="d-flex flex-column-fluid align-items-stretch justify-content-center w-100">
                <div
                    className="login-form login-signin d-flex align-items-stretch justify-content-center flex-column"
                    >

                    <div className="mb-5">
                        <FormHeader
                            title={props.title}
                            icon={<BackIcon/>}
                            desc={props.desc}
                            iconOnClick={props.iconOnClick && props.iconOnClick}
                        />
                    </div>

                    <div className="position-relative" id="generatePassword">
                        <PasswordValidationTexts
                            password={password}
                            setPasswordStrength={setPasswordStrength}
                            setPasswordRetry={setPasswordRetry}
                            setPassword={setPassword}
                        />
                        <FormInput
                            title={intl.formatMessage({id: "NEW_PASSWORD_INPUT"})}
                            inputName={"password"}
                            inputType={"password"}
                            error={{status: false}}
                            placeHolder={intl.formatMessage({id: "NEW_PASSWORD_INPUT_PLACEHOLDER"})}
                            isRequired
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            maxLength={12}
                        />

                        <PasswordStrengthBar percentage={passwordStrength.toString()}/>
                        <FormInput
                            title={intl.formatMessage({id: "CONFIRM_PASSWORD_INPUT"})}
                            inputName={"password"}
                            inputType={"password"}
                            error={passwordError}
                            placeHolder={intl.formatMessage({id: "NEW_PASSWORD_INPUT_PLACEHOLDER"})}
                            isRequired
                            value={passwordRetry}
                            onChange={(e) => setPasswordRetry(e.target.value)}
                            maxLength={12}
                        />
                        <FormButton
                            title={intl.formatMessage({id: "NEXT_BUTTON"})}
                            isDisabled={buttonDisable}
                            isPassive={buttonDisable}
                            icon={<ButtonArrowIcon/>}
                            onClick={_submitForm}
                        />
                    </div>



                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(GeneratePassword));
