import React from 'react';
import propTypes from 'prop-types';

Accordion.propTypes = {
    title : propTypes.string,
    desc : propTypes.string,
    accordionIndex : propTypes.number,
    setCurrentIndex : propTypes.func,
    currentIndex : propTypes.number
}

function Accordion(props) {
    const _indexController = () => {
        if(props.accordionIndex === props.currentIndex) {
            props.setCurrentIndex(-1)
        } else {
            props.setCurrentIndex(props.accordionIndex)
        }
    }
    return (
        <div className="mb-5">
            <div className="accordion-header user-select-none" onClick={_indexController}>
                <div className={`accordion-title ${props.accordionIndex === props.currentIndex ? "active" : ""}`}>
                    {props.title}
                </div>
                <div className={`accordion-title ${props.accordionIndex === props.currentIndex ? "active" : ""}`}>
                    {
                        props.accordionIndex === props.currentIndex ?  "-" : "+"
                    }
                </div>
            </div>
            {
                props.accordionIndex === props.currentIndex &&
                <div className="accordion-desc">{props.desc}</div>
            }
        </div>
    );
}

export default Accordion;
