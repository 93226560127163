import React, {useState} from 'react';
import DashboardSettingsHeader from "./DashboardSettingsHeader";
import OTPControl from "../../../app/modules/Auth/Components/OTPControl";
import propTypes from 'prop-types';

DashboardOtp.propTypes = {
    iconOnClick : propTypes.func.isRequired,
    changePage : propTypes.func.isRequired,
    headerTitle : propTypes.string,
    headerIcon : propTypes.any,
}

function DashboardOtp(props) {
    const [headerDesc,setHeaderDesc] = useState("");
    return (
        <div className="dashboard-form-area">
            <DashboardSettingsHeader
                title={props.headerTitle}
                icon={props.headerIcon}
                iconOnClick={props.iconOnClick}
                desc={
                    <p className="widget-freights-desc-text mt-5 ml-2" style={{fontWeight: '500'}}>
                        {headerDesc}
                    </p>
                }
            />
            <div className="row mt-10 ml-5">
                <OTPControl
                    onVerified={props.changePage}
                    from={props.from || "dashboard"}
                    setToastMenu={props.setToastMenu}
                    setHeaderDesc={setHeaderDesc}
                />
            </div>
        </div>
    );
}

export default DashboardOtp;
