/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {NavLink} from "react-router-dom";
import {FaqIconDashboard} from "../../../../_assets/icons";
import {useIntl} from "react-intl";

export function HeaderMenu({layoutProps}) {
    const intl = useIntl();
    return (
        <div
            id="kt_header_menu"
            className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}
        >
            {/*begin::Header Nav*/}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                {
                    /*

                    HELP CENTER LINK

                    <li
                    className={`menu-item menu-item-rel menu-item-active`}
                >
                    <div className="menu-link" onClick={()=>window.open('http://sectran.eu/redirects/help-center','_blank')}>
                        <div className="d-flex">
                            <CallIcon/>
                            <span className="menu-text ml-2">{intl.formatMessage({id:"HELP_CENTER"})}</span>
                        </div>
                        {layoutProps.rootArrowEnabled && <i className="menu-arrow"/>}
                    </div>
                </li>
                     */
                }
                {/*end::1 Level*/}

                {/*Classic submenu*/}
                {/*begin::1 Level*/}
                <li
                    className={`menu-item menu-item-submenu menu-item-rel`}
                >
                    <NavLink className="menu-link" to="/faq">
                        <div className="d-flex">
                            <FaqIconDashboard/>
                            <span className="menu-text ml-2">{intl.formatMessage({id:"FAQS"})}</span>
                        </div>
                        <i className="menu-arrow"></i>
                    </NavLink>
                </li>
            </ul>
            {/*end::Header Nav*/}
        </div>
    );
}
