import React, {useState, useEffect} from 'react';
import {ButtonArrowIcon} from "../../_assets/icons";
import {injectIntl, useIntl} from "react-intl";
import DeclineModal from "./DeclineModal";
import {
    agreementAccept,
    agreementDecline,
    getServiceDocument,
    getUserByToken
} from "../../../app/modules/Auth/_redux/authCrud";
import {connect, useSelector} from "react-redux";
import * as auth from "../../../app/modules/Auth/_redux/authRedux";

function DashboardAgreementFirst(props) {
    const {user} = useSelector(state => state.auth);
    const intl = useIntl();
    const [checkbox, setCheckbox] = useState(false);
    const [declineModal, setDeclineModal] = useState(false);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(true);

    const getFile = () => {
        if (user?.serviceDocuments?.entryform?.id) {
            getServiceDocument(user.serviceDocuments.entryform.id)
                .then(({data}) => {
                    setFile(`data:${data.mimeType};base64,${data.fileData}#toolbar=0&navpanes=0&scrollbar=0`);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                })
        } else if (user?.serviceDocuments?.membershipform?.id) {
            getServiceDocument(user.serviceDocuments.membershipform.id)
                .then(({data}) => {
                    setFile(`data:${data.mimeType};base64,${data.fileData}#toolbar=0&navpanes=0&scrollbar=0`);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    useEffect(getFile, []);


    const _acceptAgreement = () => {
        agreementAccept()
            .then((data) => {
                getUserByToken()
                    .then(({data: user}) => {
                        props.fulfillUser(user);
                    })
                    .catch(err => {
                        console.log('error when getting new user data on agreement page');
                    })
                    .finally(() => {
                        props.changePage()
                    })
            })
            .catch(err => {
                console.log(err, "error when accepting agreement")
            })
    }

    const _declineAgreement = () => {
        agreementDecline()
            .then((data) => {
                console.log(data);
                props.history.push("/logout");
            })
            .catch(err => {
                console.log(err, "error when removing account")
            })
    }

    return (
        <div className="h-100 d-flex flex-column">
            {
                declineModal &&
                <DeclineModal closeModal={() => setDeclineModal(false)} declineAgreement={_declineAgreement}/>
            }

            {
                !loading &&
                <div className="h-100">
                    <h3 className="agreement-header-text">{intl.formatMessage({id: "AGREEMENT"})}</h3>
                    <iframe
                        title={"agreementfile"}
                        src={file}
                        className={"pdfiFrame"}
                        style={{height: "calc(100% - 30px)"}}
                    />
                </div>
            }
            <hr/>
            <div className="d-flex align-items-center justify-content-start">

                <div className="checkbox-inline">
                    <label className="checkbox checkbox-primary">
                        <input
                            type="checkbox"
                            className="mr-3"
                            value={checkbox}
                            onChange={(e) => setCheckbox(e.target.checked)}
                            style={{height: '20px', width: '20px'}}
                        />
                        <span className="ml-2"></span>
                        <div className="agreement-checkbox">
                            {intl.formatMessage({id: "I_HAVE_READ_AGREEMENT"})}
                        </div>
                    </label>
                </div>

            </div>
            <div className="mt-5 d-flex align-items-center justify-content-end mr-10">
                <button
                    onClick={() => setDeclineModal(true)}
                    className="create-account-button passive"
                    style={{padding: '.8em 1.7em'}}>
                    {intl.formatMessage({id: "DECLINE"})}
                </button>
                <button
                    disabled={!checkbox}
                    onClick={_acceptAgreement}
                    className="btn btn-primary ml-3" style={{padding: '.8em .9em'}}>
                    {intl.formatMessage({id: "ACCEPTED"})}
                    <ButtonArrowIcon/>
                </button>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(DashboardAgreementFirst));
