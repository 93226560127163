import React from 'react';
import propTypes from 'prop-types';

DashboardButton.propTypes = {
    buttonTitle : propTypes.string.isRequired,
    activeButtonTitle : propTypes.string,
    onButtonClick : propTypes.func,
    isActive : propTypes.bool
}


function DashboardButton(props) {
    return (
        <div className={`dashboard-button ${props.isActive ? "active" : ""}`} onClick={props.onButtonClick}>
            {props.isActive ? props.activeButtonTitle : props.buttonTitle}
        </div>
    );
}

export default DashboardButton;
