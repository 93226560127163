import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import LeftMenu from "../Components/LeftMenu";
import ForgotPasswordFirst from "../Components/ForgotPasswordFirst";
import FinalForm from "../Components/FinalForm";
import {Link} from "react-router-dom";
import GeneratePassword from "../Components/GeneratePassword";
import ForgotPasswordSecurityQuestion from "../Components/ForgotPasswordSecurityQuestion";
import OTPScreen from "../Components/OTPScreen";
import {useIntl} from "react-intl";


function ForgotPassword(props) {
    const intl = useIntl();
    const [page, setPage] = useState(0);
    const [membership, setMembership] = useState("");

    const _redirectLogin = () => {
        if(page === 4) {
            setTimeout(()=>{
                props.history.push('/auth/login')
            },6000)
        }
    }

    useEffect(_redirectLogin,[page])


    return (
        <div className="d-flex flex-column flex-root form-background">
            <div className="login login-1 login-signin-on d-flex flex-column">
                <div className="d-flex flex-column flex-lg-row h-100 form-container">
                    {
                        page === 0 ?
                            <ForgotPasswordFirst
                                membership={membership}
                                setMembership={setMembership}
                                changePage={() => setPage(1)}
                                iconOnClick={() => props.history.push('/auth/login')}
                            /> :
                            page === 1 ?
                                <OTPScreen
                                    from="forgotPassword"
                                    iconOnClick={() => setPage(0)}
                                    changePage={() => setPage(2)}
                                /> :
                                page === 2 ?
                                    <ForgotPasswordSecurityQuestion
                                        iconOnClick={() => setPage(1)}
                                        changePage={() => setPage(3)}
                                    /> :
                                    page === 3 ?
                                        <GeneratePassword
                                            title={intl.formatMessage({id: "GENERATING_NEW_PASSWORD"})}
                                            desc={
                                                <p className="form-head-desc">{intl.formatMessage({id: "CREATE_PASSWORD_DESC"})}</p>
                                            }
                                            iconOnClick={() => setPage(2)}
                                            changePage={() => setPage(4)}
                                            from={"forgotPassword"}
                                        />
                                        :
                                        <FinalForm
                                            from={"forgotPassword"}
                                            text={
                                                <>
                                                    <div
                                                        className="finalFormText">{intl.formatMessage({id: "FORGOT_PASSWORD_COMPLETE_TEXT"})}</div>
                                                    <Link
                                                        to={"/auth/login"}
                                                        className="text-dark-50 text-hover-primary my-3 mr-2 mt-5"
                                                    >
                                                        <u style={{
                                                            color: "#3699FF",
                                                            marginLeft: 5
                                                        }}>
                                                            {intl.formatMessage({id: "GO_TO_LOGIN"})}
                                                        </u>
                                                    </Link>
                                                </>

                                            }
                                        />

                    }
                    <LeftMenu
                        imageType={page === 0 ? "idInformation" : page === 1 ? "phoneVerification" : page === 2 ? "securityQuestion" :  page === 3 ? "generatingPassword" : ""}
                    />
                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));


/*
import {ExpiryAnimation, IdCardIcon, PhoneIcon} from "../../../../_metronic/_assets/icons";
const page1boxes = [
        {
            icon: (
                <IdCardIcon/>
            ),
            title: intl.formatMessage({id: "DIDB_MEMBERSHIP_INPUT_TITLE"}),
            desc: "xxx - xxx"
        }
    ];

const timerRef = useRef();

const startTimer = () => {
        timerRef.current.changeTimer(300);
    }


    useEffect(() => {
        if (page === 1) {
            if (resendOTP === false) {
                //Code generated so timer start until timer finish resend button will be passive
                startTimer();
            }
        }
    }, [page, resendOTP]);
    const otpBoxes = [
        {
            icon: (
                <PhoneIcon/>
            ),
            title: intl.formatMessage({id: "PLEASE_CHECK_EMAIL_PHONE"}),
            desc: "xx-xx-xx"
        },
        {
            icon: (
                <ExpiryAnimation min={5} ref={timerRef} setResendButton={() => setResendOTP(true)}/>
            ),
            title: intl.formatMessage({id: "OTP_EXPIRY"}),
            desc: intl.formatMessage({id: "OTP_EXPIRY_DESC"}),
            timer: true
        }
    ]
 */
