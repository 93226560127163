import React, {useEffect, useState} from 'react';
import DashboardSettingsHeader from "./DashboardSettingsHeader";
import {BackIcon, ButtonArrowIcon} from "../../_assets/icons";
import TooltipComponent from "./TooltipComponent";
import DashboardInput from "./DashboardInput";
import DashboardSelect from "./DashboardSelect";
import FormButton from "../../../app/modules/Auth/Components/FormButton";
import propTypes from 'prop-types';
import {useIntl} from "react-intl";
import {
    answerSecurityQuestionsUpdateEmail, answerSecurityQuestionsUpdatePhone, getSecurityQuestionsUpdatePhone,
    getSecuriyQuestionsUpdateEmail,
    startUpdateEmail, startUpdatePhone
} from "../../../app/modules/Auth/_redux/authCrud";

DashboardChangePhoneFirstPage.propTypes = {
    iconOnClick: propTypes.func.isRequired,
    changePage: propTypes.func.isRequired,
    order: propTypes.string.isRequired
}

function DashboardChangePhoneFirstPage(props) {
    const intl = useIntl();
    const [answer, setAnswer] = useState("");
    const [securityQuestion,setSecurityQuestion] = useState([]);
    const [answerError,setAnswerError] = useState({status:false,message:''});
    const [buttonDisabled,setButtonDisabled] = useState(true);

    const _startProgress = () => {
        if(props.order === "email") {
            startUpdateEmail()
                .then(data => {
                    getSecuriyQuestionsUpdateEmail()
                        .then(({data})=>{
                            let newData = {label:data.securityQuestion.question,value:data.securityQuestion.question,...data.securityQuestion}
                            setSecurityQuestion([...securityQuestion,newData])
                        })
                        .catch(err => {
                            console.log(err,"error happen when getting security question on update email.");
                            props.setToastMenu({
                                status: true,
                                descText: err?.response?.data?.errorMessage,
                                headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                                isWarning : true
                            })
                        })
                })
                .catch(err => {
                    console.log(err,"error happen when starting update email progress")
                    props.setToastMenu({
                        status: true,
                        descText: err?.response?.data?.errorMessage,
                        headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                        isWarning : true
                    })
                })
        } else if (props.order === "phone") {
            startUpdatePhone()
                .then(data => {
                    getSecurityQuestionsUpdatePhone()
                        .then(({data})=>{
                            let newData = {label:data.securityQuestion.question,value:data.securityQuestion.question,...data.securityQuestion}
                            setSecurityQuestion([...securityQuestion,newData])
                        })
                        .catch(err => {
                            console.log(err,"error happen when getting security question on update email.")
                            props.setToastMenu({
                                status: true,
                                descText: err?.response?.data?.errorMessage,
                                headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                                isWarning : true
                            })
                        })
                })
                .catch(err => {
                    console.log(err,"error happen when starting update phone progress")
                    props.setToastMenu({
                        status: true,
                        descText: err?.response?.data?.errorMessage,
                        headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                        isWarning : true
                    })
                })
        }
    }

    useEffect(_startProgress,[]);

    const _inputHandler = (e) => {
        let numbers = /[0-9]/g;
        let inputValue = e.target.value.trim();
        const {answerValueMin, answerValueMax} = securityQuestion[0];
        const formattedValue = parseInt(inputValue);
        const formattedMinValue = parseInt(answerValueMin);
        const formattedMaxValue = parseInt(answerValueMax);
        if (!formattedValue) {
            setAnswer('');
            return;
        }
        if (inputValue[inputValue.length - 1].match(numbers)) {
            setAnswer(e.target.value);
            if (formattedValue <= formattedMaxValue && formattedValue >= formattedMinValue) {
                setButtonDisabled(false);
                setAnswerError({status: false, message: ''})
            } else {
                setButtonDisabled(true);
                setAnswerError({
                    status: true,
                    message: intl.formatMessage({id:"ANSWER_VALUE_BETWEEN"},{formattedMinValue,formattedMaxValue})
                })
            }
        }
    }

    const _submit = () => {
        if(props.order === "email") {
            answerSecurityQuestionsUpdateEmail(answer)
                .then(data => {
                    console.log(data)
                    props.changePage();
                })
                .catch(err => {
                    console.log(err, "error when answer sending to server on update mail...")
                    setAnswerError({status:true,message:err?.response?.data?.errorMessage});
                })
        } else if (props.order === "phone") {
            answerSecurityQuestionsUpdatePhone(answer)
                .then(data => {
                    console.log(data)
                    props.changePage();
                })
                .catch(err => {
                    console.log(err, "error when answer sending to server on update mail...")
                    setAnswerError({status:true,message:err?.response?.data?.errorMessage});
                })
        }
    }

    return (
        <div className="dashboard-form-area">
            <DashboardSettingsHeader
                icon={<BackIcon/>}
                iconOnClick={props.iconOnClick}
                title={
                    props.order === "phone" ?
                        intl.formatMessage({id: "CHANGE_PHONE"}) :
                        intl.formatMessage({id: "CHANGE_EMAIL"})
                }
            />

            <div className="row mt-10 ml-5">

                <div className="col-12">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id: "SECURITY_QUESTION"})} <span
                            style={{color: 'red'}}>*</span></p>
                        <TooltipComponent tooltipText={"This is tooltip text in one line"}/>
                    </div>
                    <DashboardSelect options={securityQuestion}/>
                </div>

            </div>

            <div className="row mt-10 ml-5">

                <div className="col-6">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id: "ANSWER"})}</p>
                    </div>
                    <DashboardInput
                        value={answer}
                        onChange={(e) => _inputHandler(e)}
                        error={answerError}
                    />
                </div>

            </div>

            <div className="d-flex mt-10 ml-5 justify-content-end">
                <FormButton
                    title={props.order === "phone" ? intl.formatMessage({id: "UPDATE_PHONE_NUMBER"}) : intl.formatMessage({id: "UPDATE_EMAIL"}) }
                    icon={<ButtonArrowIcon/>}
                    onClick={_submit}
                    isDisabled={buttonDisabled}
                />
            </div>
        </div>
    );
}

export default DashboardChangePhoneFirstPage;
