/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {Link, NavLink} from "react-router-dom";
import {checkIsActive} from "../../../../_helpers";
//import {DidbMiniLogo,TruckIcon} from "../../../../_assets/icons";
import {
    DashboardIcon,
    LogoutIcon,
    PaperIcon,
    PersonalDataIcon,
    SettingsIcon,
} from "../../../../_assets/icons";
import {useIntl} from "react-intl";
import Totp from "../../../../_partials/Components/Totp";

export function AsideMenuList({layoutProps}) {
    const intl = useIntl();
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu &&
            "menu-item-active"} menu-item-not-hightlighted`
            : "";
    };


    return (
        <div className="d-flex flex-column justify-content-between h-100">
            <div>
                <ul className={`menu-nav ${layoutProps.ulClasses} p-0`}>
                    {/*begin::1 Level*/}
                    <li
                        className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/dashboard">
                            <span className="menu-icon">
                              <DashboardIcon active={checkIsActive(useLocation(), "/dashboard")}/>
                            </span>
                            <span className="menu-text">{intl.formatMessage({id: "DASHBOARD"})}</span>
                        </NavLink>
                    </li>
                    {/*end::1 Level*/}

                    {/*begin::1 Level*/}
                    <li
                        className={`menu-item ${getMenuItemActive("/personal-data", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/personal-data">
                            <span className="menu-icon">
                              <PersonalDataIcon active={checkIsActive(useLocation(), "/personal-data")}/>
                            </span>
                            <span className="menu-text">{intl.formatMessage({id: "PERSONAL_DATA"})}</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                    </li>

                    {/* Material-UI */}
                    {/*begin::1 Level*/}
                    {
                        /*
                        DIDB DATA LINK COMMENTED ON MVP VERSION
                        <li
                        className={`menu-item ${getMenuItemActive(
                            "/google-material",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <div className="menu-link" style={{cursor: 'not-allowed'}}>
                            <span className="menu-icon">
                              <DidbMiniLogo active={checkIsActive(useLocation(), "/didb-data")}/>
                            </span>
                            <span className="menu-text">{intl.formatMessage({id: "DIDB_DATA"})}</span>
                            <i className="menu-arrow"/>
                        </div>
                    </li>
                         */
                    }
                    {/*end::1 Level*/}

                    {/* Bootstrap */}
                    {/*begin::1 Level*/}
                    <li
                        className={`menu-item ${getMenuItemActive(
                            "/documents",
                            false
                        )}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/documents">
                            <span className="menu-icon">
                              <PaperIcon active={checkIsActive(useLocation(), "/documents")}/>
                            </span>
                            <span className="menu-text">{intl.formatMessage({id: "DOCUMENTS"})}</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                    </li>

                    {
                        /*
                        FREIGHTS LINK COMMENTED ON MVP VERSION

                        <li
                        className={`menu-item ${getMenuItemActive(
                            "/e-commerce",
                            false
                        )}`}
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                    >
                        <div className="menu-link" style={{cursor: 'not-allowed'}}>
                            <span className="menu-icon">
                              <TruckIcon active={checkIsActive(useLocation(), "/freights")}/>
                            </span>
                            <span className="menu-text">{intl.formatMessage({id: "FREIGHTS"})}</span>
                            <i className="menu-arrow"/>
                        </div>
                    </li>
                         */
                    }
                    {/*end::1 Level*/}

                    {/*begin::1 Level*/}
                    <li
                        className={`menu-item ${getMenuItemActive("/settings", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/settings">
                        <span className="menu-icon">
                          <SettingsIcon active={checkIsActive(useLocation(), "/settings")}/>
                        </span>
                            <span className="menu-text">{intl.formatMessage({id: "SETTINGS"})}</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                    </li>


                    <Totp from={"sidemenu"} />
                    {/*end::1 Level*/}
                </ul>
            </div>


            <div>

                <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                    <li
                        className={`menu-item ${getMenuItemActive("/terms-of-use", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/document/terms-of-use">
                            <span className="menu-text">{intl.formatMessage({id:"TERMS_OF_USE"})}</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                    </li>
                    <li
                        className={`menu-item ${getMenuItemActive("/privacy-policy", false)}`}
                        aria-haspopup="true"
                    >
                        <NavLink className="menu-link" to="/document/privacy-policy">
                            <span className="menu-text">{intl.formatMessage({id:"PRIVACY_POLICY"})}</span>
                            <i className="menu-arrow"/>
                        </NavLink>
                    </li>
                    <li
                        className={`menu-item`}
                    >
                        <div className="menu-link">
                            <span className="menu-text">{intl.formatMessage({id:"VERSION"})}</span>
                            <span className="menu-version-text">1.0.01</span>
                        </div>
                    </li>
                    <Link to={"/logout"} className="menu-logout-container mt-2 cursor-pointer">
                        <LogoutIcon/>
                        <p className="mb-0 ml-2">
                            {intl.formatMessage({id:"LOGOUT"})}
                        </p>
                    </Link>
                </ul>
            </div>

            {/* end::Menu Nav */}
        </div>
    );
}
