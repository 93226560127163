import React from 'react';
//import {Dropdown} from "react-bootstrap";
//import {DropdownCustomToggler, DropdownMenu2} from "../../dropdowns";
//import {WidgetMenuIcon} from "../../../_assets/icons";
import {useIntl} from "react-intl";

function DocumentsWidget(props) {
    const intl = useIntl();
    const documentNoFormatter = (docNo) => {
        if(docNo) {
            let formattedDocumentNo = docNo.match(/.{1,4}/g);
            return formattedDocumentNo.join(' ');
        } else {
            return "";
        }
    }
    return (
        <div className={`card card-custom widget-container card-stretch-half card-stretch gutter-b`}>
            {/* Header */}
            <div className="d-flex align-items-center justify-content-between widget-header">
                <h3 className="widget-header-text">{intl.formatMessage({id:"DOCUMENTS"})}</h3>
                        {
                            /*
                            //DROPDOWN MENU COMMENTED ON MVP VERSION


                <div className="card-toolbar">
                    <Dropdown className="dropdown-inline" alignRight>
                        <Dropdown.Toggle
                            variant="transparent"
                            id="dropdown-toggle-top"
                            className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            as={DropdownCustomToggler}
                        >
                            <WidgetMenuIcon/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <DropdownMenu2/>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                            */
                        }
            </div>
            {/* Body */}
            <div className="card-body p-0">
                {
                    props.documents.map((doc, index) =>
                        <div key={index} className="mb-5 d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex align-items-center">
                                {doc.icon}
                                <div className="ml-5">
                                    <p className="widget-document-title">{doc.title}</p>
                                    <p className="widget-document-desc-text">{intl.formatMessage({id:"DOCUMENT_NO"})}<span
                                        className="widget-text-light-gray">{documentNoFormatter(doc.documentNo)}</span></p>
                                </div>
                            </div>
                            <div>
                                <p className="widget-document-expiry-title">{intl.formatMessage({id:"EXPIRY"})}</p>
                                <p className="widget-document-expiry-desc">{doc.expiryDate}</p>
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    );
}

export default DocumentsWidget;
