/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import AccountTypeSelector from "./AccountTypeSelector";

export function AuthPage() {
  return (
      <Switch>
        <ContentRoute path="/auth/account-type" component={AccountTypeSelector}/>
        <ContentRoute path="/auth/login" component={Login}/>
        <ContentRoute path="/auth/registration" component={Registration}/>
        <ContentRoute path="/auth/forgot-password" component={ForgotPassword}/>
        <Redirect from="/auth" exact={true} to="/auth/account-type"/>
        <Redirect to="/auth/account-type"/>
      </Switch>
  );
}
