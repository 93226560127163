import React from "react";
import {useLang} from "./Metronici18n";
import {IntlProvider} from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/ja";
import "@formatjs/intl-relativetimeformat/dist/locale-data/zh";


import huMessages from "./messages/hu";
import enMessages from "./messages/en";
import skMessages from './messages/sk';
import plMessages from "./messages/pl";
import roMessages from "./messages/ro";
import ruMessages from "./messages/ru";
import trMessages from "./messages/tr";
import uaMessages from "./messages/ua";


const allMessages = {
    en: enMessages,
    hu: huMessages,
    sk: skMessages,
    pl: plMessages,
    ro: roMessages,
    ru: ruMessages,
    tr: trMessages,
    ua: uaMessages
};


export function I18nProvider({children}) {
    const locale = useLang();

    return (
        <IntlProvider locale={locale} messages={allMessages[locale]}>
            {children}
        </IntlProvider>
    );
}

/*


import {LayoutSplashScreen} from "../layout";




import {GoogleSpreadsheet} from "google-spreadsheet";
import * as googleSecret from "../../google_api_secret_key";

    async function loadMessages() {
        try {
            const doc = new GoogleSpreadsheet('1G4NN3jypHsMsfescbwgg-xSQgRRKfVoaSeOE3vyApnk');
            await doc.useServiceAccountAuth({
                client_email: googleSecret.client_email,
                private_key: googleSecret.private_key,
            });
            await doc.loadInfo(); // loads document properties and worksheets
            const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
            const rows = await sheet.getRows();
            const webAppKeys = rows.filter(item => item["WEB keys"] === "YES");
            let localizationEN = {};
            let localizationHU = {};
            let localizationSK = {};
            let localizationRO = {};
            let localizationPL = {};
            let localizationUA = {};
            let localizationRU = {};
            let localizationTR = {};
            //Get english version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationEN[webAppKeys[i].KEY] = webAppKeys[i].EN ? webAppKeys[i].EN : webAppKeys[i].KEY
            }
            //Get Hungarian version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationHU[webAppKeys[i].KEY] = webAppKeys[i].HU ? webAppKeys[i].HU : webAppKeys[i].KEY
            }
            //Get PL version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationPL[webAppKeys[i].KEY] = webAppKeys[i].PL ? webAppKeys[i].PL : webAppKeys[i].KEY
            }
            //Get Romanian version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationRO[webAppKeys[i].KEY] = webAppKeys[i].RO ? webAppKeys[i].RO : webAppKeys[i].KEY
            }
            //Get RU version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationRU[webAppKeys[i].KEY] = webAppKeys[i].RU ? webAppKeys[i].RU : webAppKeys[i].KEY
            }
            //Get Slovakian version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationSK[webAppKeys[i].KEY] = webAppKeys[i].SK ? webAppKeys[i].SK : webAppKeys[i].KEY
            }
            //Get TR version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationTR[webAppKeys[i].KEY] = webAppKeys[i].TR ? webAppKeys[i].TR : webAppKeys[i].KEY
            }
            //Get UA version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationUA[webAppKeys[i].KEY] = webAppKeys[i].UA ? webAppKeys[i].UA : webAppKeys[i].KEY
            }
            console.log(JSON.stringify(localizationUA, "en"));
        } catch (e) {
            console.error('Error: ', e);
            return false;
        }
    }

    useEffect(() => {
        loadMessages()
    }, []);





    // Visitor-App
    async function loadMessages() {
        try {
            const doc = new GoogleSpreadsheet('1G4NN3jypHsMsfescbwgg-xSQgRRKfVoaSeOE3vyApnk');
            await doc.useServiceAccountAuth({
                client_email: googleSecret.client_email,
                private_key: googleSecret.private_key,
            });
            await doc.loadInfo(); // loads document properties and worksheets
            const sheet = doc.sheetsByTitle["Visitor_app"]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
            const rows = await sheet.getRows();
            const webAppKeys = rows.filter(item => item["WEB keys"] === "YES");
            let localizationEN = {};
            let localizationHU = {};
            let localizationSK = {};
            let localizationRO = {};
            let localizationDE = {};
            let localizationUA = {};
            let localizationRU = {};
            let localizationTR = {};
            //Get english version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationEN[webAppKeys[i].KEY] = webAppKeys[i].EN ? webAppKeys[i].EN : webAppKeys[i].KEY
            }
            //Get Hungarian version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationHU[webAppKeys[i].KEY] = webAppKeys[i].HU ? webAppKeys[i].HU : webAppKeys[i].KEY
            }
            //Get PL version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationDE[webAppKeys[i].KEY] = webAppKeys[i].DE ? webAppKeys[i].DE : webAppKeys[i].KEY
            }
            //Get Romanian version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationRO[webAppKeys[i].KEY] = webAppKeys[i].RO ? webAppKeys[i].RO : webAppKeys[i].KEY
            }
            //Get RU version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationRU[webAppKeys[i].KEY] = webAppKeys[i].RU ? webAppKeys[i].RU : webAppKeys[i].KEY
            }
            //Get Slovakian version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationSK[webAppKeys[i].KEY] = webAppKeys[i].SK ? webAppKeys[i].SK : webAppKeys[i].KEY
            }
            //Get TR version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationTR[webAppKeys[i].KEY] = webAppKeys[i].TR ? webAppKeys[i].TR : webAppKeys[i].KEY
            }
            //Get UA version
            for (let i = 0; i < webAppKeys.length; i++) {
                localizationUA[webAppKeys[i].KEY] = webAppKeys[i].UA ? webAppKeys[i].UA : webAppKeys[i].KEY
            }
            console.log(JSON.stringify(localizationHU, "en"));
        } catch (e) {
            console.error('Error: ', e);
            return false;
        }
    }

    useEffect(() => {
        loadMessages()
    }, []);


 */

