import React from 'react';
import propTypes from 'prop-types';

FormButtonGroup.propTypes = {
    leftTitle: propTypes.string,
    onLeftButtonClick: propTypes.func,
    leftButtonTitle: propTypes.string,
    leftButtonIcon: propTypes.any,
    onButtonClick: propTypes.func,
    buttonDisable: propTypes.bool,
    buttonActive: propTypes.bool,
    buttonTitle: propTypes.string,
    buttonIcon: propTypes.any,
    leftButtonDisable: propTypes.bool
}


function FormButtonGroup(props) {
    return (
        <div id="buttonGroups" className="d-flex justify-content-between align-items-center">
            <div
                 className="form-left-button"
                 id="buttonGroupLeftText">
                <span>{props.leftTitle}</span>
                <button
                    className={`create-account-button ${props.leftButtonDisable && "passive"}`}
                    disabled={props.leftButtonDisable}
                    onClick={props.onLeftButtonClick}
                >
                    {props.leftButtonIcon && props.leftButtonIcon}
                    <span>{props.leftButtonTitle}</span>
                </button>
            </div>
            <button
                className={`btn ${props.buttonActive ? "btn-primary" : "btn-secondary"} font-weight-bold px-9 py-4 my-3`}
                onClick={props.onButtonClick}
                disabled={props.buttonDisable}
            >
                <div className="d-flex flex-center">
                    <span>{props.buttonTitle}</span>
                    {props.buttonIcon && props.buttonIcon}
                </div>
            </button>
        </div>
    );
}

export default FormButtonGroup;
