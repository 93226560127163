import React, {useEffect, useState} from 'react';
import DashboardSettingsHeader from "./DashboardSettingsHeader";
import {BackIcon, ButtonArrowIcon} from "../../_assets/icons";
import TooltipComponent from "./TooltipComponent";
import DashboardInput from "./DashboardInput";
import DashboardSelect from "./DashboardSelect";
import FormButton from "../../../app/modules/Auth/Components/FormButton";
import propTypes from 'prop-types';
import {useIntl} from "react-intl";
import {
    answerSecurityQuestionUpdatePassword,
    getSecurityQuestionsUpdatePassword,
    startUpdatePassword
} from "../../../app/modules/Auth/_redux/authCrud";

DashboardChangePasswordFirstPage.propTypes = {
    iconOnClick : propTypes.func.isRequired,
    changePage : propTypes.func.isRequired
}


function DashboardChangePasswordFirstPage(props) {
    const intl = useIntl();
    const [answer,setAnswer] = useState("");
    const [securityQuestion,setSecurityQuestion] = useState([]);
    const [answerError,setAnswerError] = useState({status:false,message:''});
    const [buttonDisabled,setButtonDisabled] = useState(true);

    const _startChangePassword = () => {
        startUpdatePassword()
            .then(data => {
                getSecurityQuestionsUpdatePassword()
                    .then(({data}) => {
                        let newData = {label:data.securityQuestion.question,value:data.securityQuestion.question,...data.securityQuestion}
                        setSecurityQuestion([...securityQuestion,newData])
                    })
                    .catch(err => {
                        console.log("error when getting security question")
                        props.setToastMenu({
                            status: true,
                            descText: err?.response?.data?.errorMessage,
                            headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                            isWarning : true
                        })
                    })
            })
            .catch(err => {
                console.log("error when starting update password flow")
                props.setToastMenu({
                    status: true,
                    descText: err?.response?.data?.errorMessage,
                    headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                    isWarning : true
                })
            })
    }

    useEffect(_startChangePassword,[]);

    const _submit = () => {
        answerSecurityQuestionUpdatePassword(answer)
            .then((data)=>{
                props.changePage();
            })
            .catch(err => {
                console.log("error happen when answering security question on update password flow")
                setAnswerError({status:true,message:err?.response?.data?.errorMessage})
            })
    }

    const _inputHandler = (e) => {
        let numbers = /[0-9]/g;
        let inputValue = e.target.value.trim();
        const {answerValueMin, answerValueMax} = securityQuestion[0];
        const formattedValue = parseInt(inputValue);
        const formattedMinValue = parseInt(answerValueMin);
        const formattedMaxValue = parseInt(answerValueMax);
        if (!formattedValue) {
            setAnswer('');
            return;
        }
        if (inputValue[inputValue.length - 1].match(numbers)) {
            setAnswer(e.target.value);
            if (formattedValue <= formattedMaxValue && formattedValue >= formattedMinValue) {
                setButtonDisabled(false);
                setAnswerError({status: false, message: ''})
            } else {
                setButtonDisabled(true);
                setAnswerError({
                    status: true,
                    message: intl.formatMessage({id:"ANSWER_VALUE_BETWEEN"},{formattedMinValue,formattedMaxValue})
                })
            }
        }
    }

    return (
        <div className="dashboard-form-area">
            <DashboardSettingsHeader
                icon={<BackIcon/>}
                iconOnClick={props.iconOnClick}
                title={intl.formatMessage({id:"CHANGE_PASSWORD"})}
                desc={
                    <p className="widget-freights-desc-text mt-5 ml-2" style={{fontWeight: '500'}}>
                        {intl.formatMessage({id: "PLEASE_FILL_FOLLOWING_INFORMATION"})}
                    </p>
                }
            />

            <div className="row mt-10 ml-5">

                <div className="col-12">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id:"SECURITY_QUESTION"})} <span
                            style={{color: 'red'}}>*</span></p>
                        <TooltipComponent tooltipText={"This is tooltip text in one line"}/>
                    </div>
                    <DashboardSelect
                        options={securityQuestion}
                    />
                </div>

            </div>

            <div className="row mt-10 ml-5">

                <div className="col-6">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id:"ANSWER"})}</p>
                    </div>
                    <DashboardInput
                        value={answer}
                        onChange={(e)=>_inputHandler(e)}
                        error={answerError}
                    />
                </div>

            </div>

            <div className="d-flex mt-10 ml-5 justify-content-end">
                <FormButton
                    title={intl.formatMessage({id:"REQUEST_NEW_PASSWORD"})}
                    icon={<ButtonArrowIcon/>}
                    isDisabled={buttonDisabled}
                    onClick={_submit}
                />
            </div>
        </div>
    );
}

export default DashboardChangePasswordFirstPage;
