import React from 'react';
import PropTypes from 'prop-types';
import {SelectArrowIcon} from "../../../../_metronic/_assets/icons";
import TooltipComponent from "../../../../_metronic/_partials/Components/TooltipComponent";

SelectComponent.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.string,
    onSelect: PropTypes.func,
    error: PropTypes.object,
    tooltip: PropTypes.bool,
    tooltipText: PropTypes.string
}

SelectComponent.defaultProps = {
    error: {status: false, message: ''}
}

function SelectComponent(props) {
    return (
        <div className="form-group fv-plugins-icon-container">
            <div className="d-flex form-title">
                {props.title}
                {
                    props.tooltip &&
                    <TooltipComponent tooltipText={props.tooltipText}/>
                }
            </div>
            <div className={"position-relative " + (props.className ? props.className : "")}>
                <select
                    className={"selectComponent " + (props.error.status ? "form-error" : "")}
                    value={props.value && props.value}
                    onChange={props.onSelect}
                >
                    {
                        props.options !== null &&
                            props.options.map((option, index) =>
                                <option key={index}>{option}</option>
                            )
                    }
                </select>
                <div className="selectComponentArrow">
                    <SelectArrowIcon/>
                </div>
            </div>
            {
                (props.error.message && props.error.status) &&
                <div className="form-error-text mt-3 mb-0">{props.error.message}</div>
            }
        </div>
    );
}

export default SelectComponent;
