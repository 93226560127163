import React, {useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {getTermsOfUse} from "../../../app/modules/Auth/_redux/authCrud";

function TermsOfUse(props) {
    const intl = useIntl();
    const [file, setFile] = useState("");
    const [loading, setLoading] = useState(true);
    const _getTermsOfUse = () => {
        getTermsOfUse()
            .then(({data}) => {
                setFile(`data:${data.mimeType};base64,${data.fileData}#toolbar=0&navpanes=0&scrollbar=0`)
                setLoading(false);
            })
            .catch(err => {
                console.log(err, "error when getting terms of use")
            })
    }

    useEffect(_getTermsOfUse, []);

    return (
        <div className="bg-white w-100 h-100 card card-custom p-10">
            {
                !loading &&
                <>
                    <h3 className="form-title text-center">{intl.formatMessage({id: "TERMS_OF_USE"})}</h3>
                    <iframe
                        title={intl.formatMessage({id: "TERMS_OF_USE"})}
                        src={file}
                        className={"pdfiFrame"}
                    />
                </>
            }
        </div>
    );
}

export default TermsOfUse;
