import React from 'react';
import {DashboardTimer} from "../../../_assets/icons";
//import {WidgetMenuIcon} from "../../../_assets/icons";
//import {Dropdown} from "react-bootstrap";
//import {DropdownCustomToggler, DropdownMenu1} from "../../dropdowns";
import {useIntl} from "react-intl";

function PinVerificationWidget(props) {
    const intl = useIntl();
    return (
        <div className="card card-custom card-stretch card-stretch-quarter gutter-b widget-pin-verification-container">
            {/* Header */}
            <div className="card-header align-items-center border-0 mt-4">
                <h3 className="widget-header-text flex-column" style={{color:'white'}}>
                    {intl.formatMessage({id:"PASSCODE"})}
                </h3>
                {
                    /*
                    //DROPDOWN MENU COMMENTED MVP VERSION


                <div className="card-toolbar">
                    <Dropdown className="dropdown-inline" alignRight>
                        <Dropdown.Toggle
                            id="dropdown-toggle-top"
                            as={DropdownCustomToggler}
                        >
                            <WidgetMenuIcon/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <DropdownMenu1/>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                     */
                }
            </div>
            <div className="card-body pt-4 d-flex align-items-center justify-content-between w-100">
                <div>
                    <p className="pin-code m-0" style={{color:'white'}}>{props.didbIdNumericpart} <span style={{color:'#00C233'}}>{props.totp}</span></p>
                </div>
                <div style={{width:'10%'}}>
                    <DashboardTimer sec={props.secondLeft} fillColor={"#00C233"} fontColor={"white"} totalSec={props.timerDuration} />
                </div>
            </div>
        </div>
    );
}

export default PinVerificationWidget;
