import React, {useEffect, useState} from 'react';
import FormHeader from "./FormHeader";
import FormInput from "./FormInput";
import {membershipInput} from "./InputController";
import ReCAPTCHA from "react-google-recaptcha";
import FormButtonGroup from "./FormButtonGroup";
import {ButtonArrowIcon} from "../../../../_metronic/_assets/icons";
import {register} from "../_redux/authCrud";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../_redux/authRedux";
import {useIntl} from "react-intl";
import RegisterPageStepIcons from "./RegisterPageStepIcons";
import {Link} from 'react-router-dom';

function RegisterFormFirst(props) {
    const intl = useIntl();
    const [loading, setLoading] = useState(true);
    const [inputError, setInputError] = useState({status: false, message: ''});
    const [passError, setPassError] = useState({status: false, message: ''});
    const [termsOfUse, setTermsOfUse] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [inputs, setInputs] = useState({
        didbId: '',
        securityCode: '',
        captchaToken: null
    });

    const _nextButtonController = () => {
        if (inputs.captchaToken !== null && termsOfUse && privacyPolicy && inputs.didbId !== "" && inputs.securityCode !== "") {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }

    useEffect(_nextButtonController, [
        inputs,
        termsOfUse,
        privacyPolicy
    ])


    const _submitForm = () => {
        setLoading(true);
        if (inputs.didbId === "") {
            setInputError({status: true, message: intl.formatMessage({id: "THIS_FIELD_REQUIRED"})});
            setLoading(false);
            return;
        }
        if (inputs.securityCode === "") {
            setPassError({status: true, message: intl.formatMessage({id: "THIS_FIELD_REQUIRED"})});
            setLoading(false);
            return;
        }

        let didbId = inputs.didbId.split(" ").join("");
        let securiyCode = inputs.securityCode;
        register(didbId, securiyCode, inputs.captchaToken)
            .then(({data: {accessToken, refreshToken}}) => {
                console.log(accessToken, refreshToken);
                props.register(accessToken, refreshToken);
                props.changePage();
            })
            .catch(err => {
                console.log(err.response, "error happened when registering didb number");
                setInputError({status: true, message: err?.response?.data?.errorMessage});
                setPassError({status: true, message: err?.response?.data?.errorMessage});
                setLoading(false);
            })
    }


    return (
        <div className="d-flex flex-column flex-row-fluid position-relative form-area">
            <div style={{justifySelf: 'flex-start'}} id="registration-page-div">
                <RegisterPageStepIcons step={0}/>
            </div>
            <div className="d-flex flex-column-fluid align-items-stretch justify-content-start">
                <div className="login-form login-signin d-flex align-items-stretch flex-column justify-content-center">
                    <div className="mb-0">
                        <FormHeader
                            title={intl.formatMessage({id: "REGISTER_FORM_TITLE"})}
                            desc={
                                <p className="form-head-desc">{intl.formatMessage({id: "REGISTER_FORM_DESC"})}</p>
                            }
                        />
                    </div>
                    <div className="mb-0">
                        <FormInput
                            title={intl.formatMessage({id: "DIDB_MEMBERSHIP_INPUT_TITLE"})}
                            inputType={"text"}
                            inputName={"MembershipNo"}
                            placeHolder={intl.formatMessage({id: "DIDB_MEMBERSHIP_INPUT_PLACEHOLDER"})}
                            isRequired
                            questionMark
                            toolTipTitle={intl.formatMessage({id: "LOGIN_MEMBERSHIP_TOOLTIP"})}
                            error={inputError}
                            value={inputs.didbId}
                            onChange={(e) => membershipInput(e, (text) => {
                                let newInputs = Object.assign({}, inputs);
                                newInputs.didbId = text;
                                setInputs(newInputs)
                            })}
                            maxLength={7}
                        />
                        <FormInput
                            title={intl.formatMessage({id: "REGISTRATION_CODE"})}
                            inputType={"password"}
                            inputName={"password"}
                            placeHolder={intl.formatMessage({id: "REGISTRATION_CODE_PLACE_HOLDER"})}
                            isRequired
                            questionMark
                            toolTipTitle={intl.formatMessage({id: "REGISTRATION_CODE_TOOLTIP"})}
                            error={passError}
                            value={inputs.securityCode}
                            onChange={(e) => {
                                let newInputs = Object.assign({}, inputs);
                                newInputs.securityCode = e.target.value;
                                setInputs(newInputs);
                            }}
                            maxLength={12}
                        />
                    </div>


                    <div className="recaptcha">
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                            onChange={(value) => {
                                let newInputs = Object.assign({}, inputs);
                                newInputs.captchaToken = value;
                                setInputs(newInputs);
                            }}
                            onExpired={() => {
                                let newInputs = Object.assign({}, inputs);
                                newInputs.captchaToken = null;
                                setInputs(newInputs);
                            }}
                        />
                    </div>

                    <div className="form-head-desc my-5">
                        <div className="d-flex align-items-center">

                            <div className="checkbox-inline">
                                <label className="checkbox checkbox-primary">
                                    <input type="checkbox" value={termsOfUse}
                                           onChange={e => setTermsOfUse(e.target.checked)}/>
                                    <span className="ml-2"></span>
                                    <div className="d-flex align-items-center">
                                        <Link
                                            to={"/terms-of-use"}
                                            target={"_blank"}
                                        >{intl.formatMessage({id: "TERMS_OF_USE"})}</Link>
                                        <span className="ml-1">{intl.formatMessage({id: "IS_ACCEPTED"})}</span>
                                    </div>
                                </label>
                            </div>

                        </div>
                        <div className="d-flex align-items-center">

                            <div className="checkbox-inline">
                                <label className="checkbox checkbox-primary">
                                    <input type="checkbox" value={privacyPolicy}
                                           onChange={e => setPrivacyPolicy(e.target.checked)}/>
                                    <span className="ml-2"></span>
                                    <div className="d-flex align-items-center">
                                        <Link
                                            to={"/privacy-policy"}
                                            target={"_blank"}
                                        >{intl.formatMessage({id: "PRIVACY_POLICY"})}</Link>
                                        <span className="ml-1">{intl.formatMessage({id: "IS_ACCEPTED"})}</span>
                                    </div>
                                </label>
                            </div>

                        </div>
                    </div>

                    <FormButtonGroup
                        leftTitle={intl.formatMessage({id: "ALREADY_HAVE_ACCOUNT"})}
                        leftButtonTitle={intl.formatMessage({id: "LOGIN_BUTTON_TITLE"})}
                        onLeftButtonClick={props.goLoginPage}
                        buttonIcon={<ButtonArrowIcon/>}
                        buttonTitle={intl.formatMessage({id: "NEXT_BUTTON"})}
                        onButtonClick={_submitForm}
                        buttonDisable={loading}
                        buttonActive={true}/>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(RegisterFormFirst));
