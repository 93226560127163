import React, {useState} from "react";
import {connect, shallowEqual} from "react-redux";
import {injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import LeftMenu from "../Components/LeftMenu";
import RegisterFormFirst from "../Components/RegisterFormFirst";
import OTPScreen from "../Components/OTPScreen";
import GeneratePassword from "../Components/GeneratePassword";
import RegisterSecurityQuestions from "../Components/RegisterSecurityQuestions";
import FinalForm from "../Components/FinalForm";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

function Registration(props) {
    const _initialPage = () => {
        const {accessToken} = useSelector(
            ({auth}) => ({
                accessToken: auth.accessToken,
            }),
            shallowEqual
        );
        if (accessToken && props.location.state) {
            return props.location.state.page
        } else {
            return 0
        }
    }
    const intl = useIntl();
    const [page, setPage] = useState(_initialPage());

    return (
        <div className="d-flex flex-column flex-root form-background">
            <div className="login login-1 login-signin-on d-flex flex-column">
                <div className="d-flex flex-column flex-lg-row h-100 form-container">
                    {
                        page === 0 ?
                            <RegisterFormFirst
                                goLoginPage={() => props.history.push('/auth/login')}
                                changePage={() => setPage(1)}
                            /> :
                            page === 1 ?
                                <OTPScreen
                                    from={"register"}
                                    iconOnClick={() => setPage(0)}
                                    changePage={() => setPage(2)}
                                /> :
                                page === 2 ?
                                    <GeneratePassword
                                        title={intl.formatMessage({id: "CREATE_PASSWORD_TITLE"})}
                                        desc={
                                            <p className="form-head-desc">
                                                {intl.formatMessage({id: "CREATE_PASSWORD_DESC"})}
                                            </p>
                                        }
                                        iconOnClick={() => setPage(1)}
                                        changePage={() => setPage(3)}
                                        from={"register"}
                                    /> :
                                    page === 3 ?
                                        <RegisterSecurityQuestions
                                            iconOnClick={() => setPage(2)}
                                            changePage={() => setPage(4)}
                                            disableBackScreen={props.location.state ? true : false}
                                        /> :
                                        <FinalForm
                                            text={
                                                <>
                                                    <div
                                                        className="finalFormText">{intl.formatMessage({id: "REGISTRATION_COMPLETE_TEXT"})}</div>
                                                    <div
                                                        onClick={()=>{
                                                            props.redirectDashboard();
                                                        }}
                                                        className="text-dark-50 text-hover-primary my-3 mr-2 mt-5 cursor-pointer"
                                                    >
                                                        <u style={{
                                                            color: "#3699FF",
                                                            marginLeft: 5
                                                        }}>
                                                            {intl.formatMessage({id: "GO_TO_DASHBOARD"})}
                                                        </u>
                                                    </div>
                                                </>
                                            }
                                        />
                    }

                    <LeftMenu
                        imageType={page === 0 ? "idInformation" : page === 1 ? "phoneVerification" : page === 2 ? "generatingPassword" :  page === 3 ? "securityQuestion" : ""}
                    />
                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Registration));

/*


    const [resendOTP, setResendOTP] = useState(null);
    const timerRef = useRef();


    const startTimer = () => {
        timerRef.current.changeTimer(300)
    }

    useEffect(() => {
        if (page === 1) {
            if (resendOTP === false) {
                //Code generated so timer start until timer finish resend button will be passive
                startTimer();
            }
        }
    }, [page, resendOTP]);






    import {ExpiryAnimation, IdCardIcon, PhoneIcon} from "../../../../_metronic/_assets/icons";

    const page0boxes = [
        {
            icon: <IdCardIcon/>,
            title: intl.formatMessage({id: "DIDB_MEMBERSHIP_INPUT_TITLE"}),
            desc: "xxx - xxx"
        },
        {
            icon: (
                <IdCardIcon/>
            ),
            title: intl.formatMessage({id: "REGISTRATION_CODE"}),
            desc: "xxxx - xxxx - xxxx"
        }
    ];
    const otpBoxes = [
        {
            icon: <PhoneIcon/>,
            title: intl.formatMessage({id: "ONE_TIME_PASSWORD"}),
            desc: "xxx - xxx"
        },
        {
            icon: <ExpiryAnimation min={5} ref={timerRef} setResendButton={() => setResendOTP(true)}/>,
            title: intl.formatMessage({id: "OTP_EXPIRY"}),
            desc: intl.formatMessage({id: "OTP_EXPIRY_DESC"}),
            timer: true
        }
    ]
    const faq1 = [
        {
            title: "How do I start register process ?",
            content: "lorem ipsum dolor sit amet"
        },
        {
            title: "Where can I get the ID ?",
            content: "lorem ipsum dolor sit amet"
        },
        {
            title: "How many steps do we have ?",
            content: "lorem ipsum dolor sit amet"
        },
        {
            title: "How many days will it take in process ?",
            content: "lorem ipsum dolor sit amet"
        }
    ];
    const faq2 = [
        {
            title: "How do I start login process ?",
            content: "lorem ipsum dolor sit amet"
        },
        {
            title: "Where can I get the ID ?",
            content: "lorem ipsum dolor sit amet"
        },
        {
            title: "How many steps do we have ?",
            content: "lorem ipsum dolor sit amet"
        },
        {
            title: "How many days will it take in process ?",
            content: "lorem ipsum dolor sit amet"
        }
    ];

 */
