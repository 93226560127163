import React, {useState} from "react";
import PropTypes from "prop-types";
import TooltipComponent from "../../../../_metronic/_partials/Components/TooltipComponent";
import {EyeIcon, EyeInvisibleIcon} from "../../../../_metronic/_assets/icons";

function FormInput(props) {

    const [passwordInputType,setPasswordInputType] = useState("password");

    const _passwordTypeHandler = () => {
        if (passwordInputType === "password") {
            setPasswordInputType("text")
        } else {
            setPasswordInputType("password")
        }
    }

    return (
        <div className={"form-group fv-plugins-icon-container " + (props.className ? props.className : "")}>
            <div className={"d-flex form-title " + (props.headerClassName ? props.headerClassName : "")}>
                {props.title}
                {props.isRequired && <span style={{color: "red", marginLeft: ".5em"}}>*</span>}
                {props.questionMark &&
                <TooltipComponent tooltipText={props.toolTipTitle}/>
                }
            </div>
            <div className="position-relative">

                <input
                    placeholder={props.placeHolder}
                    type={props.inputType === "password" ? passwordInputType : props.inputType}
                    name={props.inputName}
                    value={props.value}
                    className={"form-control form-control-solid " + (props.error.status ? " form-error" : "")}
                    onChange={(e) => props.onChange(e)}
                    maxLength={props.maxLength}
                    readOnly={props.readOnly}
                />

                {
                    props.inputType === "password" &&
                        <div className={"form-eye-icon"} onClick={()=>_passwordTypeHandler()}>
                            {
                                passwordInputType === "password" ?
                                    <EyeInvisibleIcon/> : <EyeIcon/>
                            }
                        </div>
                }
            </div>

            {
                (props.error.message && props.error.status) &&
                <div className="form-error-text mt-2 mb-0">{props.error.message}</div>
            }

        </div>
    );
}

FormInput.propTypes = {
    title: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    questionMark: PropTypes.bool,
    inputName: PropTypes.string,
    inputType: PropTypes.string.isRequired,
    placeHolder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    error: PropTypes.object,
    maxLength: PropTypes.number,
    toolTipTitle: PropTypes.string,
    className: PropTypes.string,
    headerClassName : PropTypes.string,
    readOnly : PropTypes.bool
};

FormInput.defaultProps = {
    error: {status: false, message: ''}
}

export default FormInput;
