import React, {useState, useEffect} from 'react';
import SelectComponent from "./SelectComponent";
import FormHeader from "./FormHeader";
import FormButton from "./FormButton";
import {BackIcon, ButtonArrowIcon} from "../../../../_metronic/_assets/icons";
import {
    getSecurityQuestionsRegister,
    setSecurityQuestionsRegister
} from "../_redux/authCrud";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../_redux/authRedux";
import FormInput from "./FormInput";
import {useIntl} from "react-intl";
import RegisterPageStepIcons from "./RegisterPageStepIcons";
import ToastComponent from "./ToastComponent";

function RegisterSecurityQuestions(props) {
    const intl = useIntl();
    const [questionIndex, setQuestionIndex] = useState(1);
    const [allQuestions, setAllQuestions] = useState([]);
    const [availableQuestions, setAvailableQuestions] = useState(null);
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [answerInput, setAnswerInput] = useState("");
    const [answerError, setAnswerError] = useState({status: false, message: ''});
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const [buttonDisable, setButtonDisable] = useState(true);
    const [toastMenu, setToastMenu] = useState({status: false, descText: '', headerText: '', isWarning:false});

    const _getQuestionsFromServer = () => {
        getSecurityQuestionsRegister()
            .then(({data}) => {
                let questions = [
                    {id:-1,question:intl.formatMessage({id: "PLEASE_CHOOSE_SECURITY_QUESTION"}),answerType:intl.formatMessage({id: "ANSWER"})}
                    ,...data.securityQuestions
                ];
                setAllQuestions(questions);
                setActiveQuestion(questions[0]);
                setAvailableQuestions(questions);
            })
            .catch(err => {
                //Show error message
                setToastMenu({
                    status: true,
                    descText: err?.response?.data?.errorMessage,
                    headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                    isWarning : true
                })
            })
    }

    useEffect(_getQuestionsFromServer, []);

    const _indexController = () => {
        setButtonDisable(true);
        //Creating Object to push state
        let dataToServer = {
            securityQuestionId: activeQuestion.id,
            answer: answerInput
        }
        //Created object pushing state
        setAnsweredQuestions([...answeredQuestions, dataToServer]);
        //Clearing answer input
        setAnswerInput("");
        //Filter which questions left
        let questionsLeft = availableQuestions.filter(item => {
            return item.id !== activeQuestion.id
        })
        //Set filtered questions inside to available questions
        setAvailableQuestions(questionsLeft);
        //Change active question
        setActiveQuestion(questionsLeft[0]);

        console.log([...answeredQuestions, dataToServer], "answers Array")
        if (questionIndex < 3) {
            //If answered question length less than 3 increment to index
            setQuestionIndex(questionIndex + 1);
        } else if (questionIndex === 3) {
            // If answered question length is equal to 3 send data to server and change page
            setSecurityQuestionsRegister([...answeredQuestions, dataToServer])
                .then(({data}) => {
                    setButtonDisable(false);
                    props.changePage();
                })
                .catch(err => {
                    //If error happen when submiting security questions all state will be reset with initial values.
                    setToastMenu({
                        status: true,
                        descText: err?.response?.data?.errorMessage,
                        headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                        isWarning : true
                    })
                    setAnsweredQuestions([]);
                    setAvailableQuestions(allQuestions);
                    setActiveQuestion(allQuestions[0]);
                    setQuestionIndex(1);
                })
        }
    }

    const _onQuestionChanged = (e) => {
        setButtonDisable(true);
        //This function changes active question when question changed by user at selectbox.
        let questionData = availableQuestions.filter(item => {
            return item.question === e.target.value;
        })
        setActiveQuestion(...questionData);
        setAnswerInput("");
    }

    const _onAnswerChanged = (e) => {
        let numbers = /[0-9]/g;
        let inputValue = e.target.value.trim();
        const {answerValueMin, answerValueMax} = activeQuestion;
        const formattedValue = parseInt(inputValue);
        const formattedMinValue = parseInt(answerValueMin);
        const formattedMaxValue = parseInt(answerValueMax);
        if(activeQuestion.id === -1) {
            setButtonDisable(true);
            setAnswerError({
                status: true,
                message: intl.formatMessage({id: "PLEASE_CHOOSE_SECURITY_QUESTION"})
            })
            return;
        }
        if (!formattedValue) {
            setAnswerInput('');
            return;
        }
        if (inputValue[inputValue.length - 1].match(numbers)) {
            setAnswerInput(e.target.value);
            if (formattedValue <= formattedMaxValue && formattedValue >= formattedMinValue) {
                setButtonDisable(false);
                setAnswerError({status: false, message: ''})
            } else {
                setButtonDisable(true);
                setAnswerError({
                    status: true,
                    message: intl.formatMessage({id: "ANSWER_VALUE_BETWEEN"}, {formattedMinValue, formattedMaxValue})
                })
            }
        }
    };

    const _backButton = () => {
        //Deactivating button for block user flood;
        setButtonDisable(true);
        if (questionIndex === 1) {
            //Go back page
            props.iconOnClick();
        } else {
            //Question Back

            //Find last question answered
            let lastQuestion = allQuestions.filter(item => {
                return item.id === answeredQuestions[answeredQuestions.length - 1].securityQuestionId
            })
            //Add last answered questions into available questions array
            setAvailableQuestions([...lastQuestion, ...availableQuestions]);
            //Set active question on question select box as first index of available questions;
            setActiveQuestion([...lastQuestion, ...availableQuestions][0]);
            //Clear Input
            setAnswerInput("");

            //Delete last question's answer from state
            let deleteLastAnswer = answeredQuestions.filter(item => {
                return item.securityQuestionId !== lastQuestion[0].id
            });
            //Set state with new answers again
            setAnsweredQuestions(deleteLastAnswer);

            //Decreasing questions index -1
            setQuestionIndex(questionIndex - 1);
            //Functions finished and making button active again
            console.log([...lastQuestion, ...availableQuestions], "deleted last item answered questions");
        }
    }

    return (
        <div className="d-flex flex-column flex-row-fluid position-relative form-area">
            <ToastComponent
                headerText={toastMenu.headerText}
                descText={toastMenu.descText}
                show={toastMenu.status}
                onClose={() => {
                    setToastMenu({...toastMenu, status: false})
                }}
                isWarning={toastMenu.isWarning}
            />
            <div style={{justifySelf:'flex-start'}} id="registration-page-div">
                <RegisterPageStepIcons step={3}/>
            </div>
            <div className="d-flex flex-column-fluid align-items-stretch justify-content-start">
                <div className="login-form login-signin d-flex align-items-stretch justify-content-center flex-column">
                    <FormHeader
                        title={intl.formatMessage({id: "REGISTER_SECURITY_QUESTION_TITLE"}, {questionIndex})}
                        desc={
                            <p className="form-head-desc">
                                {intl.formatMessage({id: "REGISTER_SECURITY_QUESTION_DESC"})}
                            </p>
                        }
                        icon={props.disableBackScreen ? null : <BackIcon/>}
                        iconOnClick={_backButton}
                    />
                    <div className="mt-10">

                        <SelectComponent
                            title={intl.formatMessage({id: "SECURITY_QUESTION"})}
                            className="w-100"
                            options={availableQuestions !== null ? availableQuestions.map(item => item.question) : null}
                            value={availableQuestions !== null ? activeQuestion.question : ""}
                            onSelect={(e) => _onQuestionChanged(e)}
                            tooltip
                            tooltipText={intl.formatMessage({id: "SETTING_SECURITY_QUESTIONS_TOOLTIP"})}
                        />
                        <FormInput
                            title={availableQuestions !== null ? intl.formatMessage({id: activeQuestion?.answerType?.toUpperCase()}) : intl.formatMessage({id: "ANSWER"})}
                            inputName={"answer"}
                            inputType={"text"}
                            value={answerInput}
                            error={answerError}
                            maxLength={5}
                            onChange={(e) => _onAnswerChanged(e)}
                            className={"col-12 col-md-6 p-0"}
                        />
                        <FormButton
                            icon={<ButtonArrowIcon/>}
                            title={questionIndex < 3 ? intl.formatMessage({id: "NEXT_BUTTON"}) : intl.formatMessage({id: "FINISH_BUTTON"})}
                            isPassive={buttonDisable}
                            isDisabled={buttonDisable}
                            onClick={() => _indexController()}
                            className={"mt-20"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(RegisterSecurityQuestions));
