import React, {useState} from 'react';
import FormHeader from "./FormHeader";
import FormInput from "./FormInput";
import FormButton from "./FormButton";
import {BackIcon, ButtonArrowIcon} from "../../../../_metronic/_assets/icons";
import {membershipInput} from "./InputController";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../_redux/authRedux";
import {passwordReset} from "../_redux/authCrud";
import {useIntl} from "react-intl";

function ForgotPasswordFirst(props) {
    const intl = useIntl();
    const [inputError, setInputError] = useState({status: false, message: ''});
    const [loading, setLoading] = useState(false);


    const verifyToServer = () => {
        setLoading(true);
        let didbId = props.membership.split(' ').join('');

        passwordReset(didbId)
            .then(({data: {accessToken, refreshToken}}) => {
                setLoading(false);
                props.resetPassword(accessToken, refreshToken);
                props.changePage();
            })
            .catch(err => {
                console.log("error happen when generating token on reset password",err.response);
                setLoading(false);
                setInputError({status: true, message: err?.response?.data?.errorMessage});
            })
    }

    return (
        <div className="d-flex flex-column flex-row-fluid position-relative form-area">
            <div className="d-flex flex-column-fluid align-items-stretch justify-content-center w-100">
                <div className="login-form login-signin d-flex align-items-stretch justify-content-center flex-column">

                    <div className="mb-5">
                        <FormHeader
                            title={intl.formatMessage({id: "FORGOT_PASSWORD"})}
                            icon={<BackIcon/>}
                            iconOnClick={props.iconOnClick}
                        />
                    </div>

                    <div className="mb-5">
                        <FormInput
                            title={intl.formatMessage({id: "DIDB_MEMBERSHIP_INPUT_TITLE"})}
                            inputType={"text"}
                            inputName={"membershipNo"}
                            placeHolder={intl.formatMessage({id: "DIDB_MEMBERSHIP_INPUT_PLACEHOLDER"})}
                            isRequired
                            questionMark
                            toolTipTitle={intl.formatMessage({id: "LOGIN_MEMBERSHIP_TOOLTIP"})}
                            error={inputError}
                            value={props.membership}
                            onChange={(e) => membershipInput(e, (text) => props.setMembership(text))}
                            maxLength={7}
                        />
                    </div>

                    <FormButton
                        icon={<ButtonArrowIcon/>}
                        title={intl.formatMessage({id: "REQUEST_NEW_PASSWORD"})}
                        isPassive={props.membership.length === 7 ? false : true}
                        isDisabled={loading}
                        onClick={verifyToServer}
                    />
                </div>

            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(ForgotPasswordFirst));
