import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "../_redux/authRedux";
import {logout} from "../_redux/authCrud";

class Logout extends Component {
  componentDidMount() {
    logout()
        .then(({data})=>{
          console.log(data,"user successfully logged out");
          this.props.logout();
        })
        .catch(err => {
          console.log(err.response,"error happen when log out");
          this.props.logout();
        })
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.accessToken) }),
  auth.actions
)(Logout);
