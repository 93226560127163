import React from 'react';
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import successGif from "../../_assets/gifs/success.gif";
import iosQr from "../../_assets/img/iosQr.png";
import androidQr from "../../_assets/img/androidQr.png";

function EmailVerify(props) {
    const intl = useIntl();
    return (
        <div className="bg-white d-flex align-items-stretch justify-content-center h-100">
            <div className="d-flex flex-column flex-center w-100">
                <div style={{width: '110px', height: '110px'}}>
                    <img src={successGif} alt="success-gif" className="w-100"/>
                </div>
                <div className="w-75 text-center mt-5 d-flex flex-column flex-center"
                     id={"finalFormContext"}>
                    <div className="finalFormText">
                        Your email <b>“youremail@gmail.com”</b> has successfully been verified.
                    </div>
                    <Link
                        to="/auth/login"
                        className="text-dark-50 text-hover-primary my-3 mr-2 mt-5"
                    >
                        <u style={{
                            color: "#3699FF",
                            marginLeft: 5
                        }}>{intl.formatMessage({id: "GO_TO_LOGIN"})}</u>
                    </Link>
                    <div className="mt-20 w-100">
                        <p className="form-head-text mb-10">{intl.formatMessage({id: "DOWNLOAD_THE_APP"})}</p>
                        <div className="d-flex align-items-center justify-content-around w-100" id="appMarkets">
                            <div className="d-flex flex-center flex-column">
                                <img src={iosQr} alt="iosQrCode" style={{maxHeight:200}}/>
                                <div onClick={()=>window.open("http://sectran.eu/redirects/mobile/ios", '_blank').focus()} className="ios">
                                    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.68475 24C3.11283 23.9794 0.158203 16.6879 0.158203 12.9745C0.158203 6.90873 4.70859 5.58077 6.46228 5.58077C7.25259 5.58077 8.09649 5.89112 8.84081 6.16577C9.36131 6.35734 9.89963 6.55511 10.199 6.55511C10.3782 6.55511 10.8005 6.38688 11.1734 6.23927C11.9685 5.92272 12.9579 5.5293 14.1101 5.5293C14.1122 5.5293 14.115 5.5293 14.117 5.5293C14.9773 5.5293 17.5859 5.71811 19.1542 8.0733L19.5216 8.62534L18.9929 9.0243C18.2376 9.5942 16.8594 10.6338 16.8594 12.6931C16.8594 15.132 18.4202 16.07 19.17 16.5211C19.501 16.7202 19.8436 16.9255 19.8436 17.3746C19.8436 17.6678 17.5035 23.9636 14.1053 23.9636C13.2738 23.9636 12.686 23.7137 12.1676 23.4933C11.643 23.2701 11.1905 23.0779 10.4427 23.0779C10.0637 23.0779 9.58439 23.2571 9.07697 23.4473C8.38355 23.7061 7.59867 24 6.7081 24H6.68475Z" fill="white"/>
                                        <path d="M14.4698 0C14.5583 3.19106 12.2762 5.40488 9.99673 5.26603C9.62112 2.71945 12.2759 0 14.4698 0Z" fill="white"/>
                                    </svg>
                                    <p className="m-0 ml-3">{intl.formatMessage({id:"DOWNLOAD_APP"})}</p>
                                </div>
                            </div>
                            <div onClick={()=>window.open("http://sectran.eu/redirects/mobile/android", '_blank').focus()} className="d-flex flex-center flex-column">
                                <img src={androidQr} alt="iosQrCode" style={{maxHeight:200}}/>
                                <div className="android">
                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.33114 7.77393C1.91762 7.77393 1.56677 7.91813 1.27836 8.20654C0.989951 8.49495 0.845703 8.84103 0.845703 9.24496V15.4468C0.845703 15.8609 0.989908 16.2114 1.27836 16.4999C1.56677 16.7883 1.91762 16.9325 2.33114 16.9325C2.74436 16.9325 3.09309 16.7883 3.37703 16.4999C3.6604 16.2115 3.80248 15.8609 3.80248 15.4468V9.24496C3.80248 8.84098 3.65828 8.49495 3.36982 8.20654C3.08137 7.91813 2.73507 7.77393 2.33114 7.77393Z" fill="#464E5F"/>
                                        <path d="M14.2738 2.20654L15.2979 0.317431C15.3651 0.192364 15.3412 0.0964595 15.226 0.0290222C15.1008 -0.0291283 15.0047 0.00033753 14.9375 0.115337L13.8992 2.01955C12.9855 1.61558 12.0191 1.41322 11 1.41322C9.98074 1.41322 9.01427 1.61562 8.10092 2.01955L7.0625 0.115337C6.99502 0.00033753 6.8989 -0.0288679 6.77409 0.0290222C6.65857 0.0967633 6.63466 0.192364 6.70214 0.317431L7.72624 2.20654C6.68782 2.7361 5.86078 3.47362 5.24543 4.42069C4.63007 5.36828 4.32227 6.40379 4.32227 7.52905H17.6633C17.6633 6.40406 17.3554 5.3685 16.7401 4.42069C16.1247 3.47362 15.3025 2.7361 14.2738 2.20654ZM8.35322 4.95429C8.24247 5.06529 8.1102 5.12053 7.95645 5.12053C7.8024 5.12053 7.67282 5.06529 7.5671 4.95429C7.46139 4.8438 7.40854 4.71205 7.40854 4.55778C7.40854 4.40402 7.46139 4.27201 7.5671 4.16127C7.67282 4.05078 7.8027 3.99554 7.95645 3.99554C8.1102 3.99554 8.24247 4.05078 8.35322 4.16127C8.46371 4.27227 8.51921 4.40402 8.51921 4.55778C8.51891 4.71179 8.46345 4.8438 8.35322 4.95429ZM14.4324 4.95429C14.3264 5.06529 14.1966 5.12053 14.0431 5.12053C13.889 5.12053 13.7567 5.06529 13.6462 4.95429C13.5355 4.8438 13.4803 4.71205 13.4803 4.55778C13.4803 4.40402 13.5355 4.27201 13.6462 4.16127C13.7567 4.05078 13.889 3.99554 14.0431 3.99554C14.1968 3.99554 14.3264 4.05078 14.4324 4.16127C14.5382 4.27227 14.5909 4.40402 14.5909 4.55778C14.5909 4.71179 14.5381 4.8438 14.4324 4.95429Z" fill="#464E5F"/>
                                        <path d="M4.37891 17.6537C4.37891 18.0964 4.53266 18.4712 4.8402 18.7788C5.14801 19.0863 5.52278 19.2401 5.9652 19.2401H7.03256L7.04719 22.5145C7.04719 22.9279 7.19139 23.279 7.4798 23.5675C7.76821 23.856 8.11455 24.0002 8.51822 24.0002C8.93148 24.0002 9.28255 23.856 9.571 23.5675C9.85946 23.279 10.0037 22.928 10.0037 22.5145V19.2404H11.994V22.5145C11.994 22.9279 12.1381 23.279 12.4266 23.5675C12.715 23.856 13.0658 24.0002 13.4794 24.0002C13.8926 24.0002 14.2437 23.856 14.5322 23.5675C14.8206 23.279 14.9648 22.928 14.9648 22.5145V19.2404H16.0465C16.4791 19.2404 16.8491 19.0866 17.1572 18.779C17.4647 18.4715 17.6186 18.0967 17.6186 17.654V8.04785H4.37891V17.6537Z" fill="#464E5F"/>
                                        <path d="M19.6683 7.77393C19.2644 7.77393 18.9183 7.916 18.6299 8.19938C18.3415 8.48327 18.1973 8.832 18.1973 9.24496V15.4468C18.1973 15.8609 18.3414 16.2114 18.6299 16.4999C18.9183 16.7883 19.2646 16.9325 19.6683 16.9325C20.0816 16.9325 20.4327 16.7883 20.7211 16.4999C21.0096 16.2114 21.1537 15.8609 21.1537 15.4468V9.24496C21.1537 8.83196 21.0096 8.48327 20.7211 8.19938C20.4327 7.916 20.0816 7.77393 19.6683 7.77393Z" fill="#464E5F"/>
                                    </svg>
                                    <p className="m-0 ml-3">{intl.formatMessage({id:"DOWNLOAD_APP"})}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailVerify;
