import {REFRESH_TOKEN_URL} from "../app/modules/Auth/_redux/authCrud";
import {actions} from "../app/modules/Auth";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

function setupAxios(axios, store) {
    //On Request
    const requestInterceptor = axios.interceptors.request.use(
        config => {
            const {
                auth: {accessToken}
            } = store.getState();
            const lang = JSON.parse(localStorage.getItem(I18N_CONFIG_KEY))
            config.headers["x-language"] = `${lang?.selectedLang || "en"}`;
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return config;
        });

    let isAlreadyFetchingAccessToken = false;
    let subscribers = [];

    function onAccessTokenFetched(access_token) {
        subscribers = subscribers.filter(callback => callback(access_token))
    }

    function addSubscriber(callback) {
        subscribers.push(callback)
    }

    //On Response
    const responseInterceptor = axios.interceptors.response.use(response => {
            return response
        },
        err => {
            if (err?.response?.status === 401 && err?.response?.data?.errorCode === 4) {
                try {
                    const {
                        auth: {refreshToken}
                    } = store.getState();

                    const {config} = err;
                    const originalRequest = config;

                    if (!isAlreadyFetchingAccessToken) {
                        isAlreadyFetchingAccessToken = true;
                        axios.interceptors.response.eject(responseInterceptor);
                        axios.interceptors.request.eject(requestInterceptor);
                        axios.defaults.headers.Authorization = 'Bearer ' + refreshToken;
                        axios.post(REFRESH_TOKEN_URL).then(data => {
                            const newToken = data.data.accessToken;
                            onAccessTokenFetched(newToken);
                            isAlreadyFetchingAccessToken = false
                        }).catch(err => {
                            console.log("error when refreshing token");
                            actions.logout();
                        })
                    }
                    const retryOriginalRequest = new Promise((resolve) => {
                        addSubscriber(access_token => {
                            originalRequest.headers.Authorization = 'Bearer ' + access_token;
                            axios.defaults.headers.Authorization = 'Bearer ' + access_token;
                            console.log('changing token', access_token)
                            store.dispatch(actions.changeAccessToken(access_token));
                            resolve(axios(originalRequest))
                        })
                    })
                    return retryOriginalRequest
                } catch (e) {
                    console.log('error when refresh token progress')
                    actions.logout();
                } finally {
                    axios.interceptors.request.use(
                        config => {
                            const {
                                auth: {accessToken}
                            } = store.getState();
                            const lang = JSON.parse(localStorage.getItem(I18N_CONFIG_KEY))
                            config.headers["x-language"] = `${lang.selectedLang}`;
                            if (accessToken) {
                                config.headers.Authorization = `Bearer ${accessToken}`;
                            }
                            return config;
                        });
                }
            } else {
                return Promise.reject(err)
            }
        }
    )
}

export default setupAxios;
