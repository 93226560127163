import React from 'react';
import {Toast} from "react-bootstrap";
import {ToastMenuSuccessIcon, ToastMenuTimesIcon, ToastMenuWarningIcon} from "../../../../_metronic/_assets/icons";
import propTypes from 'prop-types';

ToastComponent.propTypes = {
    onClose : propTypes.func,
    show : propTypes.bool,
    headerText : propTypes.string,
    descText : propTypes.string,
    isWarning : propTypes.bool
}


function ToastComponent(props) {
    return (
        <Toast
            onClose={props.onClose}
            show={props.show}
            animation={true}
            style={{position:'absolute',top: 10, right: 10, zIndex: 1000}}
            delay={3500}
            autohide
        >
            <Toast.Body>
                <div style={{height:24,width:24}}>
                    {
                        props.isWarning ?
                            <ToastMenuWarningIcon/> :
                            <ToastMenuSuccessIcon/>
                    }
                </div>
                <div className={"ml-3 mr-10"}>
                    <strong className="toast-header-text">{props.headerText}</strong><br/>
                    <span className="toast-desc">{props.descText}</span>
                </div>
                <div onClick={props.onClose} className="cursor-pointer">
                    <ToastMenuTimesIcon/>
                </div>
            </Toast.Body>
        </Toast>
    );
}

export default ToastComponent;
