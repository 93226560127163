import React from 'react';

function OtpTimer(props) {
    const colors = {
        green : "#00C233A6",
        red : "#FF4B55",
        orange : "#FF9220",
        yellow : "#ffd45d"

    }
    const _colorChanger = () => {
        if (props.secondsLeft > 200) {
            return colors.green;
        } else if (props.secondsLeft > 150 && props.secondsLeft <= 200 ) {
            return colors.yellow
        } else if (props.secondsLeft > 50 && props.secondsLeft <= 150) {
            return colors.orange
        } else {
            return colors.red
        }
    }
    return (
        <div className="my-10">
            <div className="passwordBar">
                <div className="strengthBar" style={{width : ((props.secondsLeft/300*100)+"%"),backgroundColor:_colorChanger()}}/>
            </div>
            <div className="d-flex justify-content-end mt-3 otpTimerText" style={{color:_colorChanger()}}>
                {props.secondsLeft}
            </div>
        </div>
    );
}

export default OtpTimer;
