import React, {useState} from 'react';
import FormHeader from "./FormHeader";
import OTPControl from "./OTPControl";
import propTypes from 'prop-types';
import {BackIcon} from "../../../../_metronic/_assets/icons";
import {useIntl} from "react-intl";
import RegisterPageStepIcons from "./RegisterPageStepIcons";
import ToastComponent from "./ToastComponent";

OTPScreen.propTypes = {
    changePage : propTypes.func.isRequired,
    from : propTypes.string.isRequired,
}

function OTPScreen(props) {
    const intl = useIntl();
    const [message,setMessage] = useState("");
    const [toastMenu, setToastMenu] = useState({status: false, descText: '', headerText: '', isWarning:false});
    return (
        <div className="d-flex flex-column flex-row-fluid position-relative overflow-hidden form-area">
            <ToastComponent
                headerText={toastMenu.headerText}
                descText={toastMenu.descText}
                show={toastMenu.status}
                onClose={() => {
                    setToastMenu({...toastMenu, status: false})
                }}
                isWarning={toastMenu.isWarning}
            />
            {
                props.from === "register" &&
                <div style={{justifySelf:'flex-start'}} id="registration-page-div">
                    <RegisterPageStepIcons step={1}/>
                </div>
            }
            <div className="d-flex flex-column-fluid align-items-stretch justify-content-center w-100">
                <div className="login-form login-signin position-relative d-flex align-items-stretch justify-content-center flex-column">
                    <FormHeader
                        title={
                            props.from === "register" ?
                                intl.formatMessage({id:"PHONE_VERIFICATION"}) :
                                intl.formatMessage({id:"ENTER_VERIFICATION_CODE"})
                        }
                        desc={
                            <p className="form-head-desc">
                                {message}
                            </p>
                        }
                        icon={<BackIcon/>}
                        iconOnClick={props.iconOnClick}
                    />
                    <OTPControl
                        from={props.from}
                        onVerified={props.changePage}
                        setMessage={setMessage}
                        setToastMenu={setToastMenu}
                    />
                </div>
            </div>
        </div>
    );
}

export default OTPScreen;
