/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Link} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {useSelector} from "react-redux";
import {DropdownTopbarItemToggler} from "../../../../_partials/dropdowns";
import {LogoutIcon} from "../../../../_assets/icons";
import {useIntl} from "react-intl";

export function UserProfileDropdown() {
    const intl = useIntl();
    const {user} = useSelector(state => state.auth);

    return (
        <Dropdown drop="down" alignRight>
            <Dropdown.Toggle
                as={DropdownTopbarItemToggler}
                id="dropdown-toggle-user-profile"
            >
                <div
                    className={
                        "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                    }
                >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            {intl.formatMessage({id: "HI_USER_WELCOME"})}
          </span>{" "}
                    <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.nameCardname}
          </span>
                    <span className="symbol symbol-35 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {user?.nameCardname?.substr(0,1).toUpperCase() || ""}
            </span>
          </span>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
                className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl w-auto">
                {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
                <div className="d-flex align-items-start flex-column p-5">
                    <div className="text-muted">
                        {intl.formatMessage({id:"SIGNED_IN_AS"})}
                    </div>
                    <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                        {user.contactinfo.email}
                    </div>
                </div>
                <div className="separator separator-solid"></div>

                <div className="navi navi-spacer-x-0 pt-2">
                    <Link to="/personal-data" className="navi-item px-5 cursor-pointer">
                        <div className="navi-link">
                            <div className="user-dropdown-menu-title">
                                {intl.formatMessage({id:"PERSONAL_DATA"})}
                            </div>
                        </div>
                    </Link>

                    {
                        /*

                        HELP CENTER LINK

                        <a className="navi-item px-5" href={"http://sectran.eu/redirects/help-center"} target={"_blank"}>
                        <div className="navi-link">
                            <div className="user-dropdown-menu-title">
                                {intl.formatMessage({id:"HELP_CENTER"})}
                            </div>
                        </div>
                    </a>
                         */
                    }

                    <Link to={"/faq"} className="navi-item px-5 cursor-pointer">
                        <div className="navi-link">
                            <div className="user-dropdown-menu-title">
                                {intl.formatMessage({id:"FAQS"})}
                            </div>
                        </div>
                    </Link>

                    <div className="separator separator-solid"></div>

                    <Link to="/logout" className="navi-item px-5">
                        <div className="navi-link">
                            <div className="navi-icon mr-2">
                                <LogoutIcon/>
                            </div>
                            <div className="user-dropdown-menu-title" style={{color:'#E64110'}}>
                                {intl.formatMessage({id:"SIGNOUT"})}
                            </div>
                        </div>
                    </Link>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}
