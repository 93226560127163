import React from 'react';
import Select from 'react-select';
import propTypes from 'prop-types';
import {useIntl} from "react-intl";

DashboardSelect.propTypes = {
    options : propTypes.array,
    placeholder : propTypes.string,
    value : propTypes.any,
    onChange : propTypes.func,
    minHeight : propTypes.number,
    backgroundColor : propTypes.string,
    menuWidth : propTypes.string
}

function DashboardSelect(props) {
    const intl = useIntl();
    const colourStyles = {
        control: styles => {
            return {
                ...styles,
                backgroundColor: props.backgroundColor || '#EEF1F5',
                color: '#2F3044',
                fontSize: '14px',
                border: props.error ? "1px solid #FF4B55" : 'none',
                outline: 'none',
                height: '100%',
                minHeight : props.minHeight || 44,
                boxShadow: 'none'
            }
        },
        option: (styles) => {
            return {
                ...styles,
                color: '#2F3044',
                fontSize: '14px',
                backgroundColor: '#ffffff',
            };
        },
        dropdownIndicator: styles => {
            return {
                ...styles,
                color: '#80808F'
            }
        },
        indicatorSeparator : styles => {
            return {
                display:'none'
            }
        },
        placeholder: styles => {
            return {
                ...styles,
                color: '#2F3044',
                fontSize: '14px',
            }
        },
        menu : styles => {
            return {
                ...styles,
                width:props.menuWidth || '100%',
            }
        }
    };
    return (
        <Select
            value={props.value}
            styles={colourStyles}
            options={props.options}
            noOptionsMessage={()=>intl.formatMessage({id: "NO_OPTIONS"})}
            placeholder={props.placeholder ? props.placeholder : null}
            onChange={props.onChange}
        />
    )
}

export default DashboardSelect
