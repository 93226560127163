import React, {useEffect, useState} from 'react';
import DashboardSettingsHeader from "./DashboardSettingsHeader";
import {BackIcon, ButtonArrowIcon} from "../../_assets/icons";
import DashboardInput from "./DashboardInput";
import FormButton from "../../../app/modules/Auth/Components/FormButton";
import propTypes from 'prop-types';
import {useIntl} from "react-intl";
import DashboardSelect from "./DashboardSelect";
import {countryPhoneCodes} from "../Constants";
import {setPhoneUpdatePhone} from "../../../app/modules/Auth/_redux/authCrud";
import parsePhoneNumber from 'libphonenumber-js'

DashboardChangePhone.propTypes = {
    iconOnClick: propTypes.func.isRequired,
    changePage: propTypes.func.isRequired
}

function DashboardChangePhone(props) {
    const intl = useIntl();
    const [buttonDisable, setButtonDisable] = useState(true);
    const [countryCode, setCountryCode] = useState({label: "+36", value: "36"});
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneError, setPhoneError] = useState({status:false,message:''});


    const _numberController = () => {
        const numberRegex = /[0-9]/g;
        if(phoneNumber?.length > 0 && phoneNumber?.match(numberRegex)?.length === phoneNumber?.length) {
            return true;
        } else {
            return false;
        }
    }

    const _numberHandler = async (phone) => {
        if(phone !== "" && phone.length > 3) {
            const newPhoneNumber = parsePhoneNumber(countryCode.label + phone);
            const formatPhoneNumber = newPhoneNumber.formatInternational();
            let turnArray = formatPhoneNumber.split(" ");
            setPhoneNumber(turnArray.slice(1,99).join(' '));
        } else {
            setPhoneNumber(phone)
        }
    }

    useEffect(_numberController, [phoneNumber])

    const _submit = () => {
        setButtonDisable(true);
        setPhoneUpdatePhone(countryCode.label, phoneNumber)
            .then(data => {
                props.changePage();
            })
            .catch(err => {
                console.log("error happen when setting new email on update email")
                setButtonDisable(false);
                setPhoneError({status:true,message:err?.response?.data?.errorMessage})
            })
    }

    return (
        <div className="dashboard-form-area">
            <DashboardSettingsHeader
                title={intl.formatMessage({id: "CHANGE_PHONE"})}
                icon={<BackIcon/>}
                iconOnClick={props.iconOnClick}
                desc={
                    <p className="widget-freights-desc-text mt-5 ml-2" style={{fontWeight: '500'}}>
                        {intl.formatMessage({id: "CHANGE_PHONE_DESC"})}
                    </p>
                }
            />

            <div className="row mt-10 ml-5 position-relative">
                <div className="col-3" id="password-container">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id: "COUNTRY_CODE"})} <span
                            style={{color: 'red'}}>*</span></p>
                    </div>
                    <DashboardSelect
                        value={countryCode}
                        options={countryPhoneCodes}
                        onChange={e => setCountryCode(e)}
                    />
                </div>
                <div className="col-9" id="password-container">
                    <div className="d-flex align-items-center mb-2">
                        <p className="dashboard-input-title mb-0">{intl.formatMessage({id: "NUMBER"})} <span
                            style={{color: 'red'}}>*</span></p>
                    </div>
                    <DashboardInput
                        value={phoneNumber}
                        onChange={(e) => _numberHandler(e.target.value)}
                        inputType={"text"}
                        placeholder={intl.formatMessage({id: "NUMBER"})}
                        error={phoneError}
                    />
                </div>


            </div>

            <div className="d-flex justify-content-end mt-10 ml-5">
                <FormButton
                    icon={<ButtonArrowIcon/>}
                    title={"Next"}
                    isDisabled={buttonDisable}
                    isPassive={buttonDisable}
                    onClick={_submit}
                />
            </div>

        </div>
    );
}

export default DashboardChangePhone;
