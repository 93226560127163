import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import LeftMenu from "../Components/LeftMenu";
import FinalForm from "../Components/FinalForm";
import {useIntl} from "react-intl";
import CarrierRegistrationFirst from "../Components/CarrierRegistrationFirst";
import CarrierRegistrationCompanyData from "../Components/CarrierRegistrationCompanyData";
import CarrierRegistrationRegistrants from "../Components/CarrierRegistrationRegistrants";
import CarrierRegistrationOverview from "../Components/CarrierRegistrationOverview";
import {setLanguage, useLang} from "../../../../_metronic/i18n";
import {getAllCountries, getAllPositions, getPhonePrefixes} from "../_redux/authCrud";
import ToastComponent from "../Components/ToastComponent";

function CarrierRegistration(props) {
    const intl = useIntl();
    const [page, setPage] = useState(0);
    const [allCountries, setAllCountries] = useState([]);
    const [allPositions, setAllPositions] = useState([]);
    const [phonePrefixes, setPhonePrefixes] = useState([]);
    const [toastError, setToastError] = useState({status: false, message: ''});
    const [carrierData, setCarrierData] = useState({
        contactDetails: {
            name: '',
            position: '',
            email: '',
            phoneNumber: '',
            countryCode: {label: "", value: ""}
        },
        companyData: {
            companyType: '',
            companyName: '',
            registrationNumber: '',
            VATCountry: '',
            VATNumber: '',
            nameRepresentative: '',
            positionRepresentative: '',
            country: '',
            zipCode: '',
            city: '',
            address: '',
            ibanNumber: '',
            centralEmail: '',
            invoiceEmail: '',
            payment: "",
            invoicePayerCompany: '',
            isPostalAddressDifferent: false,
            postalAddressCountry: '',
            postalAddressZipCode: '',
            postalAddressCity: '',
            postalAddressAddress: ''
        },
        registrants: []
    });

    const [errors, setErrors] = useState({
        contactDetails: {
            name: {status: false, message: ''},
            position: {status: false, message: ''},
            email: {status: false, message: ''},
            phoneNumber: {status: false, message: ''},
            countryCode: {status: false, message: ''}
        },
        companyData: {
            companyType: {status: false, message: ''},
            companyName: {status: false, message: ''},
            registrationNumber: {status: false, message: ''},
            VATCountry: {status: false, message: ''},
            VATNumber: {status: false, message: ''},
            nameRepresentative: {status: false, message: ''},
            positionRepresentative: {status: false, message: ''},
            country: {status: false, message: ''},
            zipCode: {status: false, message: ''},
            city: {status: false, message: ''},
            address: {status: false, message: ''},
            ibanNumber: {status: false, message: ''},
            centralEmail: {status: false, message: ''},
            invoiceEmail: {status: false, message: ''},
            payment: {status: false, message: ''},
            invoicePayerCompany: {status: false, message: ''},
            postalAddressCountry: {status: false, message: ''},
            postalAddressZipCode: {status: false, message: ''},
            postalAddressCity: {status: false, message: ''},
            postalAddressAddress: {status: false, message: ''}
        }
    })


    const _getCountries = () => {
        getAllCountries()
            .then(({data: {countries}}) => {
                let newCountryList = [];
                for (let i = 0; i < countries.length; i++) {
                    let newCountry = {value: countries[i].key, label: countries[i].value}
                    newCountryList.push(newCountry)
                }
                setAllCountries(newCountryList);
            })
            .catch(err => {
                console.log("error happen when getting country list")
            })
    }

    const _getPositions = () => {
        getAllPositions()
            .then(({data: {partnerContactPositions}}) => {
                let positionList = [];
                for (let i = 0; i < partnerContactPositions.length; i++) {
                    let newPosition = {value: partnerContactPositions[i].id, label: partnerContactPositions[i].name}
                    positionList.push(newPosition)
                }
                setAllPositions(positionList);
            })
            .catch(err => {
                console.log("error happen when getting position list")
            })
    }

    const _getPhonePrixes = () => {
        getPhonePrefixes()
            .then(({data: {phonePrefixes}}) => {
                let phonePrefixesList = [];
                for (let i = 0; i < phonePrefixes.length; i++) {
                    let newPrefix = {value: phonePrefixes[i].prefix, label: phonePrefixes[i].country}
                    phonePrefixesList.push(newPrefix);
                }
                setPhonePrefixes(phonePrefixesList);
            })
            .catch(err => {
                console.log("error happen when getting phone prefixes list")
            })
    }

    useEffect(() => {
        _getCountries();
        _getPositions();
        _getPhonePrixes();
    }, [])

    const lang = useLang();
    const _languageController = () => {
        if (lang !== "en" && lang !== "hu") {
            setLanguage("en");
        }
    }
    useEffect(_languageController, []);

    const regexs = {
        string3Chars: /(([^0-9-!@$%^&*()_+|~#=`{}[/\]:";'<>?,])){3}/g,
        VATRegex: /[0-9a-zA-Z-]{8}/g,
        numbersString3Chars: /(([^-!@$%^&*()_+|~#=`{}[/\]:";'<>?,])){3}/g,
        registrationNumberRegex: /[0-9-]{3}/g,
        zipCodeRegex: /[A-Za-z0-9-]{3}/g,
        ibanRegex: /[A-Z]{2}[0-9]{2} [A-Z0-9]{1,4} [A-Z0-9]{1,4} [A-Z0-9]{1,4}/g,
        emailRegex: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    }

    const companyDataFieldControls = [
        {
            fieldName: "companyType",
            fieldControl: regexs.numbersString3Chars,
            message: intl.formatMessage({id: "SELECT_ERROR"})
        },
        {
            fieldName: "companyName",
            fieldControl: /.{3}/g,
            message: intl.formatMessage({id: "COMPANY_NAME_ERROR"})
        },
        {
            fieldName: "registrationNumber",
            fieldControl: regexs.registrationNumberRegex,
            message: intl.formatMessage({id: "REGISTRATION_NUMBER_ERROR"})
        },
        {
            fieldName: "VATNumber",
            fieldControl: regexs.VATRegex,
            message: intl.formatMessage({id: "VAT_NUMBER_ERROR"})
        },
        {
            fieldName: "nameRepresentative",
            fieldControl: regexs.numbersString3Chars,
            message: intl.formatMessage({id: "NAME_ERROR"})
        },
        {
            fieldName: "positionRepresentative",
            fieldControl: regexs.numbersString3Chars,
            message: intl.formatMessage({id: "NAME_ERROR"})
        },
        {
            fieldName: "zipCode",
            fieldControl: regexs.zipCodeRegex,
            message: intl.formatMessage({id: "ZIP_CODE_ERROR"})
        },
        {
            fieldName: "city",
            fieldControl: regexs.string3Chars,
            message: intl.formatMessage({id: "CITY_ERROR"})
        },
        {
            fieldName: "address",
            fieldControl: regexs.string3Chars,
            message: intl.formatMessage({id: "STREET_ERROR"})
        },
        {
            fieldName: "ibanNumber",
            fieldControl: regexs.ibanRegex,
            message: intl.formatMessage({id: "IBAN_ERROR"})
        },
        {
            fieldName: "centralEmail",
            fieldControl: regexs.emailRegex,
            message: intl.formatMessage({id: "EMAIL_ERROR"})
        },
        {
            fieldName: "payment",
            fieldControl: regexs.string3Chars,
            message: intl.formatMessage({id: "SELECT_ERROR"})
        }
    ];
    const contactDetailsFieldControls = [
        {
            fieldName: "name",
            fieldControl: regexs.string3Chars,
            message: intl.formatMessage({id: "NAME_ERROR"})
        },
        {
            fieldName: "email",
            fieldControl: regexs.emailRegex,
            message: intl.formatMessage({id: "EMAIL_ERROR"})
        }
    ];
    const postalAddressFieldControls = [
        {
            fieldName: "postalAddressZipCode",
            fieldControl: regexs.zipCodeRegex,
            message: intl.formatMessage({id: "ZIP_CODE_ERROR"})
        },
        {
            fieldName: "postalAddressCity",
            fieldControl: regexs.string3Chars,
            message: intl.formatMessage({id: "CITY_ERROR"})
        },
        {
            fieldName: "postalAddressAddress",
            fieldControl: regexs.string3Chars,
            message: intl.formatMessage({id: "STREET_ERROR"})
        },
    ]

    const _numberController = () => {
        const numberRegex = /[0-9]/g;
        if (carrierData.contactDetails?.phoneNumber?.length > 0 && carrierData?.contactDetails?.phoneNumber?.match(numberRegex)?.length === carrierData?.contactDetails?.phoneNumber?.length) {
            return true;
        } else {
            return false;
        }
    }

    const pageController = (pageNumber) => {
        let newError = Object.assign({}, errors);
        if (pageNumber === 0) {
            for (let i = 0; i < companyDataFieldControls.length; i++) {
                if (
                    carrierData.companyData[companyDataFieldControls[i].fieldName].match(companyDataFieldControls[i].fieldControl)
                ) {
                    newError.companyData[companyDataFieldControls[i].fieldName] = {status: false, message: ''};
                } else {
                    newError.companyData[companyDataFieldControls[i].fieldName] = {
                        status: true,
                        message: companyDataFieldControls[i].message
                    };
                }
            }

            //POSTAL ADDRESS CONTROL
            if (carrierData.companyData.isPostalAddressDifferent) {
                for (let i = 0; i < postalAddressFieldControls.length; i++) {
                    if (
                        carrierData.companyData[postalAddressFieldControls[i].fieldName].match(postalAddressFieldControls[i].fieldControl)
                    ) {
                        newError.companyData[postalAddressFieldControls[i].fieldName] = {status: false, message: ''};
                    } else {
                        newError.companyData[postalAddressFieldControls[i].fieldName] = {
                            status: true,
                            message: postalAddressFieldControls[i].message
                        };
                    }
                }
                //COUNTRY CONTROL
                if (carrierData.companyData.postalAddressCountry !== "") {
                    newError.companyData.postalAddressCountry = {status: false, message: ""};
                } else {
                    newError.companyData.postalAddressCountry = {
                        status: true,
                        message: intl.formatMessage({id: "SELECT_ERROR"})
                    };
                }
            } else {
                newError.companyData.postalAddressCountry = {status: false, message: ''};
                newError.companyData.postalAddressAddress = {status: false, message: ''};
                newError.companyData.postalAddressCity = {status: false, message: ''};
                newError.companyData.postalAddressZipCode = {status: false, message: ''};
            }


            //INVOICE MAIL CONTROL
            if (carrierData.companyData.invoiceEmail.match(regexs.emailRegex)) {
                newError.companyData.invoiceEmail = {status: false, message: ""};
            } else {
                newError.companyData.invoiceEmail = {status: true, message: intl.formatMessage({id: "EMAIL_ERROR"})}
            }

            //COUNTRY CONTROL
            if (carrierData.companyData.country !== "") {
                newError.companyData.country = {status: false, message: ""};
            } else {
                newError.companyData.country = {status: true, message: intl.formatMessage({id: "SELECT_ERROR"})};
            }

            //VAT COUNTRY CONTROL
            if (carrierData.companyData.VATCountry?.value && carrierData.companyData.VATCountry?.value !== "") {
                newError.companyData.VATCountry = {status: false, message: ""};
            } else {
                newError.companyData.VATCountry = {status: true, message: intl.formatMessage({id: "SELECT_ERROR"})};
            }

            //PAYMENT TYPE CONTROL
            if (carrierData.companyData.payment === "byAnotherCompany" && carrierData.companyData.invoicePayerCompany.length > 2) {
                newError.companyData.invoicePayerCompany = {
                    status: false,
                    message: ""
                }
            } else if (carrierData.companyData.payment === "byAnotherCompany" && carrierData.companyData.invoicePayerCompany.length < 3) {
                newError.companyData.invoicePayerCompany = {
                    status: true,
                    message: intl.formatMessage({id: "COMPANY_NAME_ERROR"})
                }
            }
            setErrors(newError);
            for (let a = 0; a < Object.values(newError.companyData).length; a++) {
                if (Object.values(newError.companyData)[a].status) {
                    return false;
                }
            }
            return true;
        }
        if (pageNumber === 1) {
            for (let i = 0; i < contactDetailsFieldControls.length; i++) {
                if (
                    carrierData.contactDetails[contactDetailsFieldControls[i].fieldName].match(contactDetailsFieldControls[i].fieldControl)
                ) {
                    newError.contactDetails[contactDetailsFieldControls[i].fieldName] = {status: false, message: ''};
                } else {
                    newError.contactDetails[contactDetailsFieldControls[i].fieldName] = {
                        status: true,
                        message: contactDetailsFieldControls[i].message
                    };
                }
            }
            //Position Controller
            if (carrierData.contactDetails.position !== "" && carrierData.contactDetails.position.label !== "") {
                newError.contactDetails.position = {status: false, message: ''}
            } else {
                newError.contactDetails.position = {status: true, message: intl.formatMessage({id: "SELECT_ERROR"})}
            }

            //Phone Controller
            if (_numberController() && carrierData.contactDetails.phoneNumber.length > 0) {
                newError.contactDetails.phoneNumber = {status: false, message: ''}
            } else if (!_numberController()) {
                newError.contactDetails.phoneNumber = {status: true, message: intl.formatMessage({id: "PHONE_ERROR"})}
            }

            //Country Code Controller
            if (carrierData.contactDetails.countryCode.label === "") {
                newError.contactDetails.countryCode = {status: true, message: intl.formatMessage({id: "SELECT_ERROR"})}
            } else {
                newError.contactDetails.countryCode = {status: false, message: ''}
            }
            setErrors(newError);
            for (let a = 0; a < Object.values(newError.contactDetails).length; a++) {
                if (Object.values(newError.contactDetails)[a].status) {
                    return false;
                }
            }
            return true;
        }
    }


    return (
        <div className="d-flex flex-column flex-root form-background">
            <ToastComponent
                headerText={toastError.message}
                descText={""}
                show={toastError.status}
                onClose={() => {
                    setToastError({status: false, message: ''})
                }}
                isWarning={true}
            />
            <div className="login login-1 login-signin-on d-flex flex-column">
                <div className="d-flex flex-column flex-lg-row h-100 form-container">
                    {
                        page === 0 ?
                            <CarrierRegistrationCompanyData
                                changePage={() => setPage(1)}
                                data={carrierData}
                                setData={setCarrierData}
                                pageController={() => pageController(0)}
                                allCountries={allCountries}
                                allPositions={allPositions}
                                errors={errors.companyData}
                            /> :
                            page === 1 ?
                                <CarrierRegistrationFirst
                                    changePage={() => setPage(2)}
                                    iconOnClick={() => setPage(0)}
                                    data={carrierData}
                                    setData={setCarrierData}
                                    pageController={() => pageController(1)}
                                    allPositions={allPositions}
                                    phonePrefixes={phonePrefixes}
                                    errors={errors.contactDetails}
                                /> :
                                page === 2 ?
                                    <CarrierRegistrationRegistrants
                                        changePage={() => setPage(3)}
                                        iconOnClick={() => setPage(1)}
                                        data={carrierData}
                                        allPositions={allPositions}
                                        phonePrefixes={phonePrefixes}
                                        setData={setCarrierData}
                                    /> :
                                    page === 3 ?
                                        <CarrierRegistrationOverview
                                            changePage={() => setPage(4)}
                                            iconOnClick={() => setPage(2)}
                                            data={carrierData}
                                            setData={setCarrierData}
                                            allCountries={allCountries}
                                            allPositions={allPositions}
                                            phonePrefixes={phonePrefixes}
                                            errors={errors}
                                            pageController={pageController}
                                            setToastError={setToastError}
                                        /> :
                                        <FinalForm
                                            disableDownloadApp
                                            text={
                                                <div className="finalFormText">
                                                    <div>{intl.formatMessage({id: "CARRIER_REGISTRATION_COMPLETE_TEXT"})}</div>
                                                </div>
                                            }
                                        />
                    }

                    <LeftMenu
                        languageMenu
                        availableLangs={[
                            "en",
                            "hu"
                        ]}
                        imageType={page === 0 ? "carrierCompanyData" : page === 1 ? "companyContactInformation" : page === 2 ? "carrierRegistrantsData" : page === 3 ? "carrierOverview" : "carrierOverview"}
                    />
                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(CarrierRegistration));
