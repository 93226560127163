import React from 'react';
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";

const LoginSVG = <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M53.613 1.02848C53.169 0.384865 52.4376 0 51.6561 0H26.125C22.1968 0 19 3.19677 19 7.125V8.97496C19.0926 9.05846 19.1992 9.12238 19.287 9.21284L28.7873 18.7127C30.1337 20.0569 30.8747 21.8477 30.8747 23.7499C30.8747 25.6524 30.1337 27.4433 28.7873 28.7875L19.287 38.2873C19.1992 38.3752 19.0926 38.4395 19 38.5247V42.75C19 46.6782 22.1968 49.875 26.125 49.875H34.4372C35.4231 49.875 36.3063 49.267 36.6555 48.3455L53.8744 3.22069C54.1545 2.4914 54.0549 1.6721 53.613 1.02848Z"
        fill="#E6371F"/>
    <path
        d="M13.3402 35.4445C12.4544 35.0762 11.8751 34.2094 11.8751 33.2501V26.1251H2.37486C1.06414 26.1251 0 25.061 0 23.7498C0 22.4391 1.06414 21.375 2.37486 21.375H11.8751V14.25C11.8751 13.2906 12.4544 12.4235 13.3402 12.0556C14.2287 11.6872 15.2498 11.8916 15.9291 12.5709L25.4289 22.0708C26.3578 22.9997 26.3578 24.5004 25.4289 25.4289L15.9291 34.9292C15.2498 35.6084 14.2287 35.8124 13.3402 35.4445Z"
        fill="#121D2F"/>
    <path
        d="M50.7368 0.235476L36.4681 4.99258C34.542 5.6575 33.25 7.46963 33.25 9.50006V52.2501C33.25 54.8698 35.38 57.0002 37.9997 57.0002C38.5081 57.0002 38.9904 56.9267 39.5126 56.7649L53.7818 52.0078C55.7078 51.3429 56.9999 49.5308 56.9999 47.5004V4.75035C56.9999 1.62229 53.9575 -0.75517 50.7368 0.235476Z"
        fill="url(#paint0_linear_307_202)"/>
    <defs>
        <linearGradient id="paint0_linear_307_202" x1="33.25" y1="28.504" x2="56.9999" y2="28.504"
                        gradientUnits="userSpaceOnUse">
            <stop stopColor="#EC621C"/>
            <stop offset="0.37" stopColor="#EC601C"/>
            <stop offset="0.58" stopColor="#EB571D"/>
            <stop offset="0.75" stopColor="#E9491E"/>
            <stop offset="0.91" stopColor="#E73520"/>
            <stop offset="1" stopColor="#E52521"/>
        </linearGradient>
    </defs>
</svg>;

const registerSVG = <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M33.8438 7.125H6.53125C2.92125 7.125 0 10.0462 0 13.6562V43.3437C0 46.9537 2.92125 49.875 6.53125 49.875H24.0588L24.5813 46.9537C24.8211 45.6237 25.4363 44.4362 26.3886 43.4625L29.4761 40.375H8.90625C7.9325 40.375 7.125 39.5675 7.125 38.5937C7.125 37.62 7.9325 36.8125 8.90625 36.8125H31.4688C31.8962 36.8125 32.3 36.9787 32.6087 37.24L40.375 29.4975V13.6562C40.375 10.0462 37.4538 7.125 33.8438 7.125ZM31.4688 32.6562H8.90625C7.9325 32.6562 7.125 31.8487 7.125 30.875C7.125 29.9012 7.9325 29.0937 8.90625 29.0937H31.4688C32.4425 29.0937 33.25 29.9012 33.25 30.875C33.25 31.8487 32.4425 32.6562 31.4688 32.6562ZM31.4688 24.9375H8.90625C7.9325 24.9375 7.125 24.13 7.125 23.1562C7.125 22.1825 7.9325 21.375 8.90625 21.375H31.4688C32.4425 21.375 33.25 22.1825 33.25 23.1562C33.25 24.13 32.4425 24.9375 31.4688 24.9375Z"
        fill="url(#paint0_linear_307_236)"/>
    <path
        d="M29.0938 4.75H26.4813C25.6975 2.01875 23.18 0 20.1875 0C17.195 0 14.6775 2.01875 13.8938 4.75H11.2813C10.3075 4.75 9.5 5.5575 9.5 6.53125V11.2813C9.5 13.5613 11.3763 15.4375 13.6563 15.4375H26.7188C28.9988 15.4375 30.875 13.5613 30.875 11.2813V6.53125C30.875 5.5575 30.0675 4.75 29.0938 4.75Z"
        fill="#E6371F"/>
    <path
        d="M33.25 23.1562C33.25 24.13 32.4425 24.9375 31.4688 24.9375H8.90625C7.9325 24.9375 7.125 24.13 7.125 23.1562C7.125 22.1825 7.9325 21.375 8.90625 21.375H31.4688C32.4425 21.375 33.25 22.1825 33.25 23.1562Z"
        fill="white"/>
    <path
        d="M33.25 30.875C33.25 31.8487 32.4425 32.6562 31.4688 32.6562H8.90625C7.9325 32.6562 7.125 31.8487 7.125 30.875C7.125 29.9012 7.9325 29.0938 8.90625 29.0938H31.4688C32.4425 29.0938 33.25 29.9012 33.25 30.875Z"
        fill="white"/>
    <path
        d="M32.6087 37.24L29.4737 40.375H8.90625C7.9325 40.375 7.125 39.5675 7.125 38.5938C7.125 37.62 7.9325 36.8125 8.90625 36.8125H31.4687C31.8962 36.8125 32.3 36.9788 32.6087 37.24Z"
        fill="white"/>
    <path
        d="M9.5 7.125H6.53125C2.92125 7.125 0 10.0462 0 13.6562V43.3437C0 46.9537 2.92125 49.875 6.53125 49.875H20.1875V40.375H8.90625C7.9325 40.375 7.125 39.5675 7.125 38.5937C7.125 37.62 7.9325 36.8125 8.90625 36.8125H20.1875V32.6562H8.90625C7.9325 32.6562 7.125 31.8487 7.125 30.875C7.125 29.9012 7.9325 29.0937 8.90625 29.0937H20.1875V24.9375H8.90625C7.9325 24.9375 7.125 24.13 7.125 23.1562C7.125 22.1825 7.9325 21.375 8.90625 21.375H20.1875V15.4375H13.6563C11.3763 15.4375 9.5 13.5612 9.5 11.2812V7.125Z"
        fill="#E6371F"/>
    <path
        d="M20.1875 0C17.195 0 14.6775 2.01875 13.8938 4.75H11.2813C10.3075 4.75 9.5 5.5575 9.5 6.53125V7.125V11.2813C9.5 13.5613 11.3763 15.4375 13.6563 15.4375H20.1875V0Z"
        fill="#E6371F"/>
    <path
        d="M20.1875 21.375H8.90625C7.9325 21.375 7.125 22.1825 7.125 23.1562C7.125 24.13 7.9325 24.9375 8.90625 24.9375H20.1875V21.375Z"
        fill="#DEDEDE"/>
    <path
        d="M20.1875 29.0938H8.90625C7.9325 29.0938 7.125 29.9012 7.125 30.875C7.125 31.8487 7.9325 32.6562 8.90625 32.6562H20.1875V29.0938Z"
        fill="#DEDEDE"/>
    <path
        d="M20.1875 36.8125H8.90625C7.9325 36.8125 7.125 37.62 7.125 38.5938C7.125 39.5675 7.9325 40.375 8.90625 40.375H20.1875V36.8125Z"
        fill="#DEDEDE"/>
    <path
        d="M29.752 56.9998C29.2841 56.9998 28.8281 56.8145 28.4933 56.4773C28.0824 56.0664 27.8971 55.4821 27.9993 54.9074L29.258 47.7705C29.3198 47.4119 29.4955 47.0794 29.752 46.8229L47.3864 29.1885C49.5524 27.0201 51.6804 27.6044 52.8441 28.7681L55.782 31.706C57.4041 33.3281 57.4041 35.962 55.782 37.5841L38.1476 55.2185C37.8911 55.4774 37.5586 55.6508 37.1976 55.7149L30.0608 56.9713L29.752 56.9998ZM36.8889 53.9598H36.9126H36.8889Z"
        fill="#26354E"/>
    <path
        d="M50.4216 27.728C49.4906 27.728 48.4433 28.1318 47.3864 29.1887L29.752 46.823C29.4955 47.0795 29.3198 47.412 29.258 47.7707L27.9993 54.9075C27.8971 55.4823 28.0824 56.0665 28.4933 56.4774C28.5123 56.4964 28.5289 56.513 28.5479 56.5296L54.5755 30.4997L52.8418 28.7659C52.248 28.1722 51.3978 27.728 50.4216 27.728Z"
        fill="#121D2F"/>
    <defs>
        <linearGradient id="paint0_linear_307_236" x1="-6.65116e-05" y1="28.5" x2="40.375" y2="28.5"
                        gradientUnits="userSpaceOnUse">
            <stop stopColor="#EC621C"/>
            <stop offset="0.37" stopColor="#EC601C"/>
            <stop offset="0.58" stopColor="#EB571D"/>
            <stop offset="0.75" stopColor="#E9491E"/>
            <stop offset="0.91" stopColor="#E73520"/>
            <stop offset="1" stopColor="#E52521"/>
        </linearGradient>
    </defs>
</svg>;

const timesSVG = <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34.5 11.5L11.5 34.5" stroke="#20282C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.5 11.5L34.5 34.5" stroke="#20282C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


function LoginTypeModal({closeModal}) {
    const intl = useIntl();
    return (
        <div className="languageModal">
            <div className="languageModalContent loginType">
                <div className="timesIcon" onClick={closeModal} style={{cursor:'pointer'}}>
                    {timesSVG}
                </div>
                <div className="loginTypeSelector">
                    <Link to={"/auth/login"} className="typeContainer">
                        <div>
                            {
                                LoginSVG
                            }
                            <h6>{intl.formatMessage({id: "LOGIN_BUTTON_TITLE"})}</h6>
                            <span>{intl.formatMessage({id: "LOGIN_MODAL_DESC"})}</span>
                        </div>
                    </Link>
                    <Link to={"/auth/registration"} className="typeContainer">
                        <div>
                            {
                                registerSVG
                            }
                            <h6>{intl.formatMessage({id: "REGISTER"})}</h6>
                            <span>{intl.formatMessage({id: "REGISTER_MODAL_DESC"})}</span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default LoginTypeModal;
