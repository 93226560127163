import React, {useState, useRef, useEffect} from 'react';
import tr from '../../../../_metronic/_assets/img/langFlags/tr.png';
import en from '../../../../_metronic/_assets/img/langFlags/en.png';
import ro from '../../../../_metronic/_assets/img/langFlags/ro.png';
import hu from '../../../../_metronic/_assets/img/langFlags/hu.png';
import pl from '../../../../_metronic/_assets/img/langFlags/pl.png';
import ua from '../../../../_metronic/_assets/img/langFlags/ua.png';
import ru from '../../../../_metronic/_assets/img/langFlags/ru.png';
import sk from '../../../../_metronic/_assets/img/langFlags/sk.png';
import {CheckIconLanguageDropdown, DropdownArrowIcon} from "../../../../_metronic/_assets/icons";
import propTypes from 'prop-types';
import {useLang, setLanguage} from "../../../../_metronic/i18n";
import {useSelector} from "react-redux";
import {setDriverLanguage} from "../_redux/authCrud";

let languages = [
    {
        icon: en,
        languageTag: 'en',
        languageName: 'English'
    },
    {
        icon: hu,
        languageTag: 'hu',
        languageName: 'Hungarian'
    },
    {
        icon: sk,
        languageTag: 'sk',
        languageName: 'Slovakian'
    },
    {
        icon: ro,
        languageTag: 'ro',
        languageName: 'Romanian'
    },
    {
        icon: pl,
        languageTag: 'pl',
        languageName: 'Polish'
    },
    {
        icon: ua,
        languageTag: 'ua',
        languageName: 'Ukranian'
    },
    {
        icon: ru,
        languageTag: 'ru',
        languageName: 'Russian'
    },
    {
        icon: tr,
        languageTag: 'tr',
        languageName: 'Turkish'
    }
]

LanguageDropdown.propTypes = {
    justFlag: propTypes.bool,
    langs: propTypes.array
}


function LanguageDropdown(props) {
    const lang = useLang();
    const language = languages.find(x => x.languageTag === lang);
    const [menu, setMenu] = useState(false);
    const menuRef = useRef(null);

    const state = useSelector(state => state.auth);


    const languageChanger = (lang) => {
        if(state.accessToken && state.isLogin) {
            //Set driver language at backend
            setDriverLanguage(lang.languageTag)
                .then(data => {
                    console.log("driver language setted on BE")
                    setLanguage(lang.languageTag);
                })
                .catch(err => {
                    console.log("error when setting driver language")
                })
        }
        setLanguage(lang.languageTag);
        setMenu(false);
    }

    const selectedLangs = () => {
        if(props.langs) {
            let selectedLangs = [];
            for(let i = 0; i < props.langs.length; i++) {
                let selectedLang = languages.filter(item => {
                    return item.languageTag === props.langs[i]
                })
                selectedLangs = ([...selectedLangs,...selectedLang]);
            }
            return selectedLangs;
        }
    }

    function useOutsideClickHandler(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setMenu(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useOutsideClickHandler(menuRef);


    return (
        <div className="languageDropdown" style={props.justFlag && {borderWidth: 0, width: 'auto', minWidth: 'auto'}}>
            <div className="d-flex align-items-center justify-content-between h-100 w-100"
                 onClick={() => setMenu(true)}>
                <div className="d-flex align-items-center w-75">
                    <img src={language.icon} alt={language.languageTag} className="languageDropdownFlag"/>
                    {
                        !props.justFlag &&
                        <div className="languageDropdownText">{language.languageName}</div>
                    }
                </div>
                {
                    !props.justFlag &&
                    <div className="w-25 d-flex justify-content-end">
                        <DropdownArrowIcon/>
                    </div>
                }
            </div>
            {
                menu &&
                <div className="languageDropdownMenu" ref={menuRef} style={props.justFlag && {right: '1em'}}>
                    {
                        !props.langs ?
                            languages.map((lang, index) =>
                                <div className="d-flex h-auto my-3 position-relative" key={index}>
                                    <div className="d-flex justify-content-start align-items-center"
                                         onClick={() => languageChanger(lang)}>
                                        <img src={lang.icon} alt={lang.languageTag} className="languageDropdownFlag"/>
                                        <div className="languageDropdownMenuText">{lang.languageName}</div>
                                    </div>
                                    {
                                        lang.languageTag === language.languageTag &&
                                        <div className="checkIconDropdown">
                                            <CheckIconLanguageDropdown/>
                                        </div>
                                    }
                                </div>
                            ) :
                            selectedLangs().map((lang, index) =>
                                <div className="d-flex h-auto my-3 position-relative" key={index}>
                                    <div className="d-flex justify-content-start align-items-center"
                                         onClick={() => languageChanger(lang)}>
                                        <img src={lang.icon} alt={lang.languageTag} className="languageDropdownFlag"/>
                                        <div className="languageDropdownMenuText">{lang.languageName}</div>
                                    </div>
                                    {
                                        lang.languageTag === language.languageTag &&
                                        <div className="checkIconDropdown">
                                            <CheckIconLanguageDropdown/>
                                        </div>
                                    }
                                </div> )

                    }
                </div>
            }
        </div>
    );
}

export default LanguageDropdown;
