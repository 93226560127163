import React from 'react';
import propTypes from 'prop-types';
import {Logo} from "../../../_assets/icons";
import {useIntl} from "react-intl";
import TooltipComponent from "../../Components/TooltipComponent";

LogoInformationWidget.propTypes = {
    driverName: propTypes.string,
    driverCode: propTypes.string,
    expiryDate: propTypes.string,
    status: propTypes.string
}

function LogoInformationWidget(props) {
    const _colorize = () => {
        const blueColor = '#3699FF';
        const blueBackground = 'rgba(56, 156, 255, 0.1)';
        const greenColor = "#00C233";
        const greenBackground = "rgba(0, 194, 51, 0.1)";
        const redColor = "#E61010";
        const redBackground = "rgba(229, 16, 16, 0.1)";
        const checkStatus = props.status.toLowerCase();
        if (checkStatus === "notclassified" || checkStatus === "pending") {
            return {
                color: blueColor,
                backgroundColor: blueBackground
            }
        } else if (checkStatus === "valid") {
            return {
                color: greenColor,
                backgroundColor: greenBackground
            }
        } else if (checkStatus === "invalid" || checkStatus === "sleep" || checkStatus === "deleted" || checkStatus === "invaliddocuments") {
            return {
                color: redColor,
                backgroundColor: redBackground
            }
        }
    }
    const intl = useIntl();
    return (
        <div className={`card card-custom widget-container card-stretch-half card-stretch gutter-b`}>
            <div className="d-flex align-items-center justify-content-between widget-header">
                <h3 className="widget-header-text">{intl.formatMessage({id: "MY_ECARD"})}</h3>
            </div>
            <div>
                <Logo/>
                <p className="widget-logo-desc-text">{intl.formatMessage({id: "DIDB_FULL_NAME"})}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <h3 className="widget-driver-name mb-2">{props.driverName}</h3>
                <div className="d-flex align-items-center">
                    <p className="widget-driver-code mb-0">{props.driverCode}</p><TooltipComponent
                    tooltipText={intl.formatMessage({id: "ECARD_TOOLTIP"})}/>
                </div>
            </div>
            <div className="d-flex align-items-start justify-content-between">
                <div className={"mr-5"}>
                    <p className="widget-expiry-title mb-1">{intl.formatMessage({id: "MEMBERSHIP_EXPIRY_DATE"})}</p>
                    <p className="widget-expiry-text mb-0">{props.expiryDate}</p>
                </div>
                <div>
                    <p className="widget-expiry-title mb-1">{intl.formatMessage({id: "MEMBERSHIP_STATUS"})}</p>
                    <div className="widget-status-container" style={_colorize()}>
                        {props.statusDisplay}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LogoInformationWidget;
