import React, {useState} from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import * as auth from "../_redux/authRedux";
import LeftMenu from "../Components/LeftMenu";
import {
    ButtonArrowIcon,
} from "../../../../_metronic/_assets/icons";
import {useIntl} from "react-intl";
import LanguageDropdown from "../Components/LanguageDropdown";
import FormHeader from "../Components/FormHeader";
import FormInput from "../Components/FormInput";
import {membershipInput} from "../Components/InputController";
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import FormButtonGroup from "../Components/FormButtonGroup";
import {authUser, login} from "../_redux/authCrud";

function Login(props) {

    const intl = useIntl();
    const [inputs, setInputs] = useState({
        didbId: '',
        password: '',
        captchaToken : null
    });
    const [inputError, setInputError] = useState({status: false, message: ''});
    const [passError, setPassError] = useState({status: false, message: ''});
    const [loading, setLoading] = useState(true);

    const _onFormSubmitted = () => {
        setLoading(true);
        let membershipNo = inputs.didbId.split(" ").join("");
        login(membershipNo, inputs.password, inputs.captchaToken)
            .then(({data: {accessToken, refreshToken}}) => {
                console.log(`accessToken : ${accessToken} \n refreshToken : ${refreshToken}`);
                props.saveToken(accessToken, refreshToken);
                return ({accessToken, refreshToken});
            })
            .then((tokens) => {
                authUser()
                    .then(({data: {availableFunction}}) => {
                        if (availableFunction === "security-questions") {
                            console.log('redirecting....');
                            props.history.push({pathname: '/auth/registration', state: {page: 3}})
                        } else if (availableFunction === "dashboard") {
                            props.login(tokens.accessToken, tokens.refreshToken);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.log(err.response, "error happen when login on auth/me endpoint")
                    })
            })
            .catch((err) => {
                console.log(err.response, "error happen when try login",err);
                setLoading(false);
                if (err.response?.data?.didbId) {
                    setInputError({status: true, message: err.response.data.didbId[0]})
                }
                if (err.response?.data?.password) {
                    setPassError({status: true, message: err.response.data.password[0]})
                }
                if (err.response?.data?.errorMessage) {
                    setInputError({status: true, message: err?.response?.data?.errorMessage});
                    setPassError({status: true, message: err?.response?.data?.errorMessage});
                }
            })
    }


    return (
        <div className="d-flex flex-column flex-root position-relative form-background">

            <div className="login login-1 login-signin-on d-flex flex-column">
                <div className="d-flex flex-column flex-lg-row h-100 form-container">

                    <div className="d-flex flex-column flex-row-fluid position-relative form-area">
                        <div className="d-flex flex-column-fluid align-items-stretch justify-content-start">
                            <div className="login-form login-signin d-flex align-items-stretch justify-content-center flex-column">

                                <div className="d-flex d-lg-none align-items-center justify-content-end mb-2">
                                    <LanguageDropdown/>
                                </div>


                                <div className="mb-5">
                                    <FormHeader
                                        title={intl.formatMessage({id: "LOGIN_HEADER"})}
                                        desc={
                                            <p className="form-head-desc">
                                                {intl.formatMessage({id: "LOGIN_HEADER_DESC"})}
                                            </p>
                                        }
                                    />
                                </div>

                                <div className="mb-5">
                                    <FormInput
                                        title={intl.formatMessage({id: "DIDB_MEMBERSHIP_INPUT_TITLE"})}
                                        inputType={"text"}
                                        inputName={"membershipNo"}
                                        placeHolder={intl.formatMessage({id: "DIDB_MEMBERSHIP_INPUT_PLACEHOLDER"})}
                                        isRequired
                                        questionMark
                                        toolTipTitle={intl.formatMessage({id: "LOGIN_MEMBERSHIP_TOOLTIP"})}
                                        error={inputError}
                                        value={inputs.didbId}
                                        onChange={(e) => membershipInput(e, (text) => {
                                            let newInputs = Object.assign({}, inputs);
                                            newInputs.didbId = text;
                                            setInputs(newInputs);
                                        })}
                                        maxLength={7}
                                    />
                                    <FormInput
                                        title={intl.formatMessage({id: "PASSWORD_INPUT_TITLE"})}
                                        inputType={"password"}
                                        inputName={"password"}
                                        placeHolder={intl.formatMessage({id: "PASSWORD_INPUT_PLACEHOLDER"})}
                                        isRequired
                                        questionMark
                                        toolTipTitle={intl.formatMessage({id: "LOGIN_PASSWORD_TOOLTIP"})}
                                        error={passError}
                                        value={inputs.password}
                                        onChange={(e) => {
                                            let newInputs = Object.assign({}, inputs);
                                            newInputs.password = e.target.value;
                                            setInputs(newInputs);
                                        }}
                                        maxLength={12}
                                        className={"mb-0"}
                                    />

                                    <div className="d-flex justify-content-end">
                                        <Link
                                            to="/auth/forgot-password"
                                            className="text-dark-50 text-hover-primary my-3 mr-2"
                                            id="kt_login_forgot"
                                        >
                                            <u style={{color: "#3699FF"}}>{intl.formatMessage({id: "FORGOT_PASSWORD"})}</u>
                                        </Link>
                                    </div>

                                </div>

                                <div className="recaptcha">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                        onChange={(value) => {
                                            let newInputs = Object.assign({}, inputs);
                                            newInputs.captchaToken = value;
                                            setInputs(newInputs);
                                            setLoading(false);
                                        }}
                                        onExpired={()=>{
                                            console.log('captcha expired');
                                            let newInputs = Object.assign({}, inputs);
                                            newInputs.captchaToken = null;
                                            setInputs(newInputs);
                                            setLoading(true);
                                        }}
                                    />
                                </div>



                                <FormButtonGroup
                                    buttonTitle={intl.formatMessage({id: "LOGIN_BUTTON_TITLE"})}
                                    leftTitle={intl.formatMessage({id: "CREATE_ACCOUNT_BUTTON_DESC"})}
                                    leftButtonTitle={intl.formatMessage({id: "CREATE_ACCOUNT_BUTTON_TITLE"})}
                                    onLeftButtonClick={() => props.history.push('/auth/registration')}
                                    onButtonClick={_onFormSubmitted}
                                    buttonDisable={loading}
                                    buttonActive={true}
                                    buttonIcon={<ButtonArrowIcon/>}
                                />
                            </div>
                        </div>
                    </div>

                    <LeftMenu
                        languageMenu
                        imageType={"login"}
                    />
                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
