export const membershipInput = (e, setFunction) => {
    let numbers = /[0-9]/g;
    let inputValue = e.target.value.trim();
    if (e.target.value.length === 0) {
        //User wants delete all input
        setFunction('');
    } else if (e.target.value[e.target.value.length - 1].match(numbers)) {
        //Adding new validated chars to input
        let newString = inputValue.split(" ").join("");
        setFunction(newString.replace(/(.{3})/g, '$1 ').trim())
    } else if (e.target.value[e.target.value.length - 1] === " " || e.target.value === "") {
        //User trying to delete blank space
        setFunction(e.target.value.trim());
    } else {
        //Error handling inacceptable chars sending by user function return false;
        return;
    };
}

export const passwordStrengthController = (password, setFunction) => {
    return new Promise((resolve, reject) => {
        let strength = 0;
        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        //let specialChars = /[-!@$%^&*()_+|~#=`{}[/\]:";'<>?,.]/g;
        let numbers = /[0-9]/g;
        if (!password) {
            reject("Error Happened !")
        }
        if (password.length < 13 && password.length > 7) {
            strength += 25;
        }
        if (password.match(numbers)) {
            strength += 25;
        }
        if (password.match(upperCaseLetters)) {
            strength += 25;
        }
        if (password.match(lowerCaseLetters)) {
            strength += 25;
        }
        setFunction(strength);
        resolve({
            length: password.length < 13 && password.length > 7 ? true : false,
            letters: password.match(lowerCaseLetters) && password.match(upperCaseLetters) ? true : false,
            numbers: password.match(numbers) ? true : false,
        })
    })
}

export const ibanController = (iban,setFunction) => {
    if (!iban) {
        setFunction('');
    }

    const cleanIban = iban
        .replace(/\s\s+/g, ' ')
        .replace(/[^0-9a-zA-Z]/gi, '')
        .toUpperCase()

    const parts = []

    if (cleanIban.length > 0) {
        parts.push(cleanIban.substring(0, 4))
    }

    if (cleanIban.length > 4) {
        parts.push(cleanIban.substring(4, 8))
    }

    if (cleanIban.length > 8) {
        parts.push(cleanIban.substring(8, 12))
    }

    if (cleanIban.length > 12) {
        parts.push(cleanIban.substring(12, 16))
    }

    if (cleanIban.length > 16) {
        parts.push(cleanIban.substring(16, 20))
    }

    if (cleanIban.length > 20) {
        parts.push(cleanIban.substring(20, 24))
    }

    if (cleanIban.length > 24) {
        parts.push(cleanIban.substring(24, 28))
    }

    if (cleanIban.length > 28) {
        parts.push(cleanIban.substring(28, 32))
    }

    const newIban =  parts.join(' ');
    setFunction(newIban);
}
