import React from 'react';
import propTypes from 'prop-types';

DashboardSettingsHeader.propTypes = {
    icon : propTypes.any,
    iconOnClick: propTypes.func,
    title : propTypes.string,
    desc : propTypes.any
}

function DashboardSettingsHeader(props) {
    return (
        <div className="d-flex align-items-start">
            {
                props.icon &&
                    <div onClick={props.iconOnClick} className="cursor-pointer">
                        {props.icon}
                    </div>
            }
            <div className="mt-1">
                <h3 className="dashboard-form-title ml-2 mb-0">{props.title}</h3>
                {
                    props.desc
                }
            </div>
        </div>
    );
}

export default DashboardSettingsHeader;
