import React, {useEffect, useState} from 'react';
import FormHeader from "./FormHeader";
import {BackIcon, ButtonArrowIcon} from "../../../../_metronic/_assets/icons";
import propTypes from "prop-types";
import FormButton from "./FormButton";
import TooltipComponent from "../../../../_metronic/_partials/Components/TooltipComponent";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../_redux/authRedux";
import {answerSecurityQuestionPasswordReset, getSecurityQuestionPasswordReset} from "../_redux/authCrud";
import FormInput from "./FormInput";
import {useIntl} from "react-intl";
import ToastComponent from "./ToastComponent";

ForgotPasswordSecurityQuestion.propTypes = {
    iconOnClick: propTypes.func.isRequired,
    changePage: propTypes.func.isRequired
}

function ForgotPasswordSecurityQuestion(props) {
    const intl = useIntl();
    const [answer, setAnswer] = useState("");
    const [inputError, setInputError] = useState({status: false, message: ''});
    const [buttonLoading, setButtonLoading] = useState(true);
    const [question, setQuestion] = useState("");
    const [toastMenu, setToastMenu] = useState({status: false, descText: '', headerText: '', isWarning:false});

    const _getSecurityQuestion = () => {
        getSecurityQuestionPasswordReset()
            .then(({data}) => {
                setQuestion(data.securityQuestion);
            })
            .catch(err => {
                console.log("error happen when getting security questions on password reset");
                setToastMenu({
                    status: true,
                    descText: err?.response?.data?.errorMessage,
                    headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                    isWarning : true
                })
            })
    }

    useEffect(_getSecurityQuestion, []);


    const _inputHandler = (e) => {
        let numbers = /[0-9]/g;
        let inputValue = e.target.value.trim();
        const {answerValueMin, answerValueMax} = question;
        const formattedValue = parseInt(inputValue);
        const formattedMinValue = parseInt(answerValueMin);
        const formattedMaxValue = parseInt(answerValueMax);
        if (!formattedValue) {
            setAnswer('');
            return;
        }
        if (inputValue[inputValue.length - 1].match(numbers)) {
            setAnswer(e.target.value);
            if (formattedValue <= formattedMaxValue && formattedValue >= formattedMinValue) {
                setButtonLoading(false);
                setInputError({status: false, message: ''})
            } else {
                setButtonLoading(true);
                setInputError({
                    status: true,
                    message: intl.formatMessage({id:"ANSWER_VALUE_BETWEEN"},{formattedMinValue,formattedMaxValue})
                })
            }
        }
    }

    const verifyToServer = () => {
        setButtonLoading(true);
        answerSecurityQuestionPasswordReset(answer)
            .then(({data}) => {
                setButtonLoading(false);
                props.changePage();
            })
            .catch(err => {
                console.log(err.response, "error happen when answers sending to server on password reset");
                setInputError({status: true, message: err?.response?.data?.errorMessage});
                setButtonLoading(false);
            })
    }

    return (
        <div className="d-flex flex-column flex-row-fluid position-relative overflow-hidden form-area">
            <ToastComponent
                headerText={toastMenu.headerText}
                descText={toastMenu.descText}
                show={toastMenu.status}
                onClose={() => {
                    setToastMenu({...toastMenu, status: false})
                }}
                isWarning={toastMenu.isWarning}
            />
            <div className="d-flex flex-column-fluid align-items-stretch justify-content-center w-100">
                <div className="login-form login-signin position-relative d-flex align-items-stretch justify-content-center flex-column">
                    <div className="mb-5">
                        <FormHeader
                            title={intl.formatMessage({id:"FORGOT_PASSWORD"})}
                            icon={<BackIcon/>}
                            iconOnClick={props.iconOnClick}
                        />
                    </div>

                    <div className="d-flex form-title mb-5">
                        {intl.formatMessage({id:"SECURITY_QUESTION"})}
                        <TooltipComponent tooltipText={intl.formatMessage({id: "FORGOT_PASSWORD_SECURITY_QUESTION_TOOLTIP"})}/>
                    </div>
                    <p className="forgotPasswordQuestionText mb-10">{question.question ? question.question : ""}</p>

                    <div className="mb-5">
                        <FormInput
                            title={intl.formatMessage({id:"ANSWER"})}
                            inputName={"answer"}
                            inputType={"text"}
                            placeHolder={intl.formatMessage({id:"ANSWER_INPUT_PLACE_HOLDER"})}
                            value={answer}
                            onChange={(e) => _inputHandler(e)}
                            error={inputError}
                            maxLength={5}
                            className={"col-12 col-md-6 p-0"}
                        />
                    </div>

                    <FormButton
                        className={"mt-15"}
                        icon={<ButtonArrowIcon/>}
                        title={intl.formatMessage({id:"REQUEST_NEW_PASSWORD"})}
                        isDisabled={buttonLoading}
                        isPassive={buttonLoading}
                        onClick={verifyToServer}
                    />
                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(ForgotPasswordSecurityQuestion));
