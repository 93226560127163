import React, {useState} from 'react';
import FormHeader from "./FormHeader";
import FormInput from "./FormInput";
import {BackIcon, ButtonArrowIcon, ButtonBackIcon} from "../../../../_metronic/_assets/icons";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../_redux/authRedux";
import {useIntl} from "react-intl";
import FormButton from "./FormButton";
import PhoneInputComponent from "./PhoneInputComponent";
import propTypes from 'prop-types';
import CarrierRegisterPageStepIcons from "./CarrierRegisterPageStepIcons";
import LanguageDropdown from "./LanguageDropdown";
import CarrierSelect from "./CarrierSelect";

CarrierRegistrationFirst.propTypes = {
    changePage: propTypes.func,
    data: propTypes.object
}

function CarrierRegistrationFirst(props) {
    const intl = useIntl();
    const [checkbox, setCheckbox] = useState(false);

    const _inputOnChange = (e) => {
        const textRegex = /(([^0-9-!@$%^&*()_+|~#=`{}[/\]:";'<>?,.]))/g;
        const checkText = () => {
            // This functions checks is fields have text chars
            if (e.target.value[e.target.value.length - 1] === undefined) {
                deleteField();
                return;
            }
            if (e.target.value[e.target.value.length - 1].match(textRegex)) {
                return true
            } else {
                return false
            }
        }
        const deleteField = () => {
            let newData = Object.assign({}, props.data);
            newData.contactDetails[e.target.name] = "";
            props.setData(newData)
        }

        const updateFields = () => {
            let newData = Object.assign({}, props.data);
            newData.contactDetails[e.target.name] = e.target.value;
            props.setData(newData)
        }

        if (e.target.name === "name") {
            if (checkText()) updateFields()
        } else {
            updateFields();
        }
    }

    const _submitForm = () => {
        if (props.pageController()) {
            if (checkbox) {
                let newRegistrant = {
                    name: props.data.contactDetails.name,
                    position: props.data.contactDetails.position,
                    email: props.data.contactDetails.email,
                    phoneNumber: props.data.contactDetails.phoneNumber,
                    id: props.data.registrants.length + 1,
                    countryCode: {
                        label: props.data.contactDetails.countryCode.label,
                        value: props.data.contactDetails.countryCode.value
                    }
                }
                let newData = Object.assign({}, props.data);
                newData.registrants.push(newRegistrant);
                props.changePage();
            } else {
                props.changePage();
            }
        }
    }

    return (
        <div className="d-flex flex-column flex-row-fluid position-relative form-area">
            <div className="d-flex flex-column-fluid align-items-stretch justify-content-start">
                <div className="login-form login-signin d-flex align-items-stretch justify-content-center flex-column">
                    <div className="d-flex d-lg-none align-items-center justify-content-end my-5">
                        <LanguageDropdown langs={["hu", "en"]}/>
                    </div>
                    <div className="mb-5">
                        <FormHeader
                            title={intl.formatMessage({id: "CARRIER_REGISTER_FORM_TITLE"})}
                            desc={
                                <p className="form-head-desc">{intl.formatMessage({id: "CARRIER_REGISTRATION_DESC"})}</p>
                            }
                            icon={<BackIcon/>}
                            iconOnClick={props.iconOnClick}
                        />
                        <div style={{justifySelf: 'flex-start'}} id="registration-page-div">
                            <CarrierRegisterPageStepIcons step={1}/>
                        </div>
                    </div>
                    <div className="mb-5">
                        <div className="container p-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <FormInput
                                        title={intl.formatMessage({id: "YOUR_NAME"})}
                                        inputType={"text"}
                                        inputName={"name"}
                                        placeHolder={intl.formatMessage({id: "YOUR_NAME"})}
                                        isRequired
                                        error={props.errors.name}
                                        value={props.data.contactDetails.name}
                                        onChange={(e) => _inputOnChange(e)}
                                        headerClassName={"light"}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <CarrierSelect
                                        title={intl.formatMessage({id: "YOUR_POSITION"})}
                                        options={props.allPositions}
                                        value={props.data.contactDetails.position}
                                        onChange={(position) => _inputOnChange({
                                            target: {
                                                value: position,
                                                name: 'position'
                                            }
                                        })}
                                        error={props.errors.position}
                                        placeholder={intl.formatMessage({id: "PLEASE_SELECT"})}
                                        headerClassName={"light"}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <FormInput
                                        title={intl.formatMessage({id: "YOUR_EMAIL_ADDRESS"})}
                                        inputType={"email"}
                                        inputName={"email"}
                                        placeHolder={intl.formatMessage({id: "YOUR_EMAIL_ADDRESS"})}
                                        isRequired
                                        error={props.errors.email}
                                        value={props.data.contactDetails.email}
                                        onChange={(e) => _inputOnChange(e)}
                                        headerClassName={"light"}
                                    />
                                </div>
                                    <PhoneInputComponent
                                        title={intl.formatMessage({id: "PHONE_NUMBER"})}
                                        value={props.data.contactDetails.phoneNumber}
                                        error={props.errors.phoneNumber}
                                        countryCodeError={props.errors.countryCode}
                                        countryCode={props.data.contactDetails.countryCode}
                                        phonePrefixes={props.phonePrefixes}
                                        setCountryCode={(countryCode) => _inputOnChange({
                                            target: {
                                                name: "countryCode",
                                                value: countryCode
                                            }
                                        })}
                                        onChange={(phone) => _inputOnChange({
                                            target: {
                                                name: "phoneNumber",
                                                value: phone
                                            }
                                        })}
                                        headerClassName={"light"}
                                    />
                                <div className="col-md-12 mt-10">
                                    <div className="d-flex align-items-center">

                                        <div className="checkbox-inline">
                                            <label className="checkbox checkbox-primary">
                                                <input type="checkbox" checked={checkbox}
                                                       onChange={(e) => setCheckbox(e.target.checked)}/>
                                                <span className="ml-2"></span>
                                                {intl.formatMessage({id: "PROVODING_E_REGISTRATION"})}
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                        <FormButton
                            icon={<ButtonBackIcon/>}
                            title={intl.formatMessage({id: "BACK"})}
                            onClick={props.iconOnClick}
                            contentClassName={"flex-row-reverse"}
                        />
                        <FormButton
                            icon={<ButtonArrowIcon/>}
                            title={intl.formatMessage({id: "NEXT_BUTTON"})}
                            onClick={_submitForm}
                        />
                    </div>


                </div>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(CarrierRegistrationFirst));
