import React, {useState, useEffect} from 'react';
import {ButtonArrowIcon, RetryIcon} from "../../../../_metronic/_assets/icons";
import propTypes from 'prop-types';
import {
    commitUpdateEmail,
    commitUpdatePhone,
    generateOTPPasswordReset,
    generateOtpUpdateEmail,
    generateOtpUpdatePassword,
    generateOtpUpdatePhone,
    generateOtpUpdateSecurityQuestions,
    generateRegisterOtp, getUserByToken,
    startUpdateSecurityQuestions,
    validationGenerateOtpUpdateEmail, validationGenerateOtpUpdatePhone,
    validationVerifyOtpUpdateEmail, validationVerifyOtpUpdatePhone,
    verifyOTPPasswordReset,
    verifyOtpUpdateEmail,
    verifyOtpUpdatePassword, verifyOtpUpdatePhone,
    verifyRegisterOtp,
    verifyUpdateSecurityQuestionsOtp
} from "../_redux/authCrud";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../_redux/authRedux";
import {useIntl} from "react-intl";
import OtpTimer from "./OTPTimer";

OTPControl.propTypes = {
    onVerified: propTypes.func.isRequired,
    setMessage: propTypes.func,
    from: propTypes.string.isRequired,
    resendButton: propTypes.bool,
    setResendButton: propTypes.func,
    setError: propTypes.func
}

function OTPControl(props) {
    const intl = useIntl();
    const [input, setInput] = useState(["-", "-", "-", "-", "-", "-"]);
    const [nextButton, setNextButton] = useState(false);
    const [optWarn, setOptWarn] = useState(false);
    const [optSuccess, setOptSuccess] = useState(false);
    const [secondsLeft, setSecondsLeft] = useState(0);
    const [resendButton, setResendButton] = useState(false);
    const fields = new Array(6).fill("");


    const changeTimer = (second) => {
        setSecondsLeft(second);
    }

    useEffect(() => {
        const id = setInterval(() => {
            if (secondsLeft < 1) {
                setSecondsLeft(0);
                setResendButton(true);
                clearInterval(id);
            } else {
                setSecondsLeft(secondsLeft - 1)
            }
        }, 1000);
        return () => clearInterval(id);
    }, [secondsLeft, props]);

    const generateOPT = () => {
        switch (props.from) {
            case "register" :
                generateRegisterOtp()
                    .then(({data}) => {
                        props.setMessage(data.message);
                        setResendButton(false);
                        changeTimer(300);
                    })
                    .catch(err => {
                        setResendButton(true);
                        props.setToastMenu({
                            status: true,
                            descText: err?.response?.data?.errorMessage,
                            headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                            isWarning: true
                        })
                        changeTimer(0);
                    });
                break;
            case "forgotPassword" :
                generateOTPPasswordReset()
                    .then(({data}) => {
                        props.setMessage(data.message);
                        setResendButton(false);
                        changeTimer(300);
                    })
                    .catch(err => {
                        console.log("error happen when creating otp for password reset");
                        setResendButton(true);
                        props.setToastMenu({
                            status: true,
                            descText: err?.response?.data?.errorMessage,
                            headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                            isWarning: true
                        })
                        changeTimer(0);
                    });
                break;
            case "dashboardUpdateSecurity" :
                startUpdateSecurityQuestions()
                    .then((data) => {
                        generateOtpUpdateSecurityQuestions()
                            .then(({data}) => {
                                props.setHeaderDesc(data.message)
                                setResendButton(false);
                                changeTimer(300);
                            })
                            .catch(err => {
                                console.log("error happen when generating update security questions otp...")
                                props.setToastMenu({
                                    status: true,
                                    descText: err?.response?.data?.errorMessage,
                                    headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                                    isWarning: true
                                })
                                changeTimer(0);
                            })
                    })
                    .catch(err => {
                        console.log("error happen when starting update security progress...")
                        props.setToastMenu({
                            status: true,
                            descText: err?.response?.data?.errorMessage,
                            headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                            isWarning: true
                        })
                    })
                break;
            case "dashboardChangePassword" :
                generateOtpUpdatePassword()
                    .then(({data}) => {
                        props.setHeaderDesc(data.message)
                        setResendButton(false);
                        changeTimer(300);
                    })
                    .catch(err => {
                        console.log("error happen when generating update security questions otp...")
                        props.setToastMenu({
                            status: true,
                            descText: err?.response?.data?.errorMessage,
                            headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                            isWarning: true
                        })
                        changeTimer(0);
                    })
                break;
            case "dashboardUpdateEmail" :
                generateOtpUpdateEmail()
                    .then(({data}) => {
                        props.setHeaderDesc(data.message)
                        setResendButton(false);
                        changeTimer(300);
                    })
                    .catch(err => {
                        console.log("error happen when generating update security questions otp...")
                        props.setToastMenu({
                            status: true,
                            descText: err?.response?.data?.errorMessage,
                            headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                            isWarning: true
                        })
                        changeTimer(0);
                    })
                break;
            case "dashboardValidationUpdateEmail" :
                validationGenerateOtpUpdateEmail()
                    .then(({data}) => {
                        props.setHeaderDesc(data.message)
                        setResendButton(false);
                        changeTimer(300);
                    })
                    .catch(err => {
                        console.log("error happen when generating update security questions otp...")
                        props.setToastMenu({
                            status: true,
                            descText: err?.response?.data?.errorMessage,
                            headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                            isWarning: true
                        })
                        changeTimer(0);
                    })
                break;
            case "dashboardUpdatePhone" :
                generateOtpUpdatePhone()
                    .then(({data}) => {
                        props.setHeaderDesc(data.message)
                        setResendButton(false);
                        changeTimer(300);
                    })
                    .catch(err => {
                        console.log("error happen when generating update security questions otp...")
                        props.setToastMenu({
                            status: true,
                            descText: err?.response?.data?.errorMessage,
                            headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                            isWarning: true
                        })
                        changeTimer(0);
                    })
                break;
            case "dashboardValidationUpdatePhone" :
                validationGenerateOtpUpdatePhone()
                    .then(({data}) => {
                        props.setHeaderDesc(data.message)
                        setResendButton(false);
                        changeTimer(300);
                    })
                    .catch(err => {
                        console.log("error happen when generating update security questions otp...")
                        props.setToastMenu({
                            status: true,
                            descText: err?.response?.data?.errorMessage,
                            headerText: intl.formatMessage({id: "REQUEST_FAILED"}),
                            isWarning: true
                        })
                        changeTimer(0);
                    })
                break;
            default :
                console.log("error there is not any from props")
        }

    }

    useEffect(generateOPT, []);


    const _onChangeHandler = async (e) => {
        let newInput = [...input];
        newInput[Number(e.target.id)] = e.target.value;
        let nextInputId = (Number(e.target.id) + 1).toString();
        await setInput(newInput);
        if (nextInputId < 6) {
            await document.getElementById(nextInputId).focus();
        }
    }

    const _inputFocused = (index) => {
        let newInput = [...input];
        newInput[index] = "";
        setInput(newInput);
    }

    const _inputChecker = () => {
        // Controlling next button disable or active
        (input.indexOf('-') === -1 && input.indexOf('') === -1) ? setNextButton(true) : setNextButton(false);
        //After red border warning if user start typing again red borders will be canceled.
        (input.indexOf('') > -1 && optWarn === true) && setOptWarn({status: false, message: ''});
    }

    const verifyCode = () => {
        setNextButton(false);
        //Asking to server is code valid ?
        let otp = input.join('');

        switch (props.from) {
            case "register" :
                verifyRegisterOtp(otp)
                    .then(({data}) => {
                        console.log("otp verified");
                        setOptSuccess(true);
                        setTimeout(() => props.onVerified(), 1000);
                    })
                    .catch(err => {
                        console.log("error happen when verifing otp");
                        setOptWarn({status: true, message: err?.response?.data?.errorMessage});
                    });
                break;
            case "forgotPassword" :
                verifyOTPPasswordReset(otp)
                    .then(({data}) => {
                        setOptSuccess(true);
                        setTimeout(() => props.onVerified(), 1000);
                    })
                    .catch(err => {
                        console.log("error happen when verifing otp on password reset");
                        setOptWarn({status: true, message: err?.response?.data?.errorMessage});
                    })
                break;
            case "dashboardUpdateSecurity" :
                verifyUpdateSecurityQuestionsOtp(otp)
                    .then(({data}) => {
                        setOptSuccess(true);
                        setTimeout(() => props.onVerified(), 1000)
                    })
                    .catch(err => {
                        console.log("error happen when verifing otp on password reset");
                        setOptWarn({status: true, message: err?.response?.data?.errorMessage});
                    })
                break;
            case "dashboardChangePassword" :
                verifyOtpUpdatePassword(otp)
                    .then(({data}) => {
                        setOptSuccess(true);
                        setTimeout(() => props.onVerified(), 1000)
                    })
                    .catch(err => {
                        console.log("error happen when verifing otp on password reset");
                        setOptWarn({status: true, message: err?.response?.data?.errorMessage});
                    })
                break;
            case "dashboardUpdateEmail" :
                verifyOtpUpdateEmail(otp)
                    .then(({data}) => {
                        setOptSuccess(true);
                        setTimeout(() => props.onVerified(), 1000)
                    })
                    .catch(err => {
                        console.log("error happen when verifing otp on password reset");
                        setOptWarn({status: true, message: err?.response?.data?.errorMessage});
                    })
                break;
            case "dashboardValidationUpdateEmail" :
                validationVerifyOtpUpdateEmail(otp)
                    .then(({data}) => {
                        setOptSuccess(true);
                        commitUpdateEmail()
                            .then(() => {
                                getUserByToken()
                                    .then(({data: user}) => {
                                        props.fulfillUser(user);
                                    })
                                    .catch(err => {
                                        console.log('error when getting new user data on update email');
                                    })
                                    .finally(() => {
                                        setTimeout(() => props.onVerified(), 1000)
                                    })
                            })
                            .catch(err => {
                                console.log("error happen when committing update email")
                            })
                    })
                    .catch(err => {
                        console.log("error happen when verifing otp on password reset");
                        setOptWarn({status: true, message: err?.response?.data?.errorMessage});
                    })
                break;
            case "dashboardUpdatePhone" :
                verifyOtpUpdatePhone(otp)
                    .then(({data}) => {
                        setOptSuccess(true);
                        setTimeout(() => props.onVerified(), 1000)
                    })
                    .catch(err => {
                        console.log("error happen when verifing otp on password reset");
                        setOptWarn({status: true, message: err?.response?.data?.errorMessage});
                    })
                break;
            case "dashboardValidationUpdatePhone" :
                validationVerifyOtpUpdatePhone(otp)
                    .then(({data}) => {
                        console.log(data, "otp verified");
                        setOptSuccess(true);
                        commitUpdatePhone()
                            .then(() => {
                                getUserByToken()
                                    .then(({data: user}) => {
                                        props.fulfillUser(user);
                                    })
                                    .catch(err => {
                                        console.log('error when getting new user data on update phone');
                                    })
                                    .finally(() => {
                                        setTimeout(() => props.onVerified(), 1000)
                                    })
                            })
                            .catch(err => {
                                console.log(err, "error happen when committing update phone")
                            })
                    })
                    .catch(err => {
                        console.log(err.response, "error happen when verifing otp on password reset");
                        setOptWarn({status: true, message: err?.response?.data?.errorMessage});
                    })
                break;
            default :
                console.log("error there is not any from props")
        }


    }

    useEffect(_inputChecker, [input]);

    return (
        <div className="otpContainer mt-10">
            <div className="d-flex justify-content-between" id="OPTInputs">
                {
                    fields.map((item, index) =>
                        <input
                            type="text"
                            id={index}
                            key={index}
                            value={input[index] ? input[index] : ""}
                            maxLength={1}
                            onFocus={() => _inputFocused(index)}
                            className={"optInput " + (optWarn ? "optWarn" : optSuccess ? "optSuccess" : "")}
                            onChange={(e) => _onChangeHandler(e)}
                        />)
                }
            </div>
            {
                optWarn.status ? <div className="optWarnText mt-2">{optWarn.message}</div> :
                    optSuccess ?
                        <div className="optSuccessText mt-2">{intl.formatMessage({id: "OTP_SUCCESS"})}</div> : null
            }


            <OtpTimer secondsLeft={secondsLeft}/>


            <div id="buttonGroups" className="d-flex mt-20 justify-content-between align-items-center">
                <div className="form-left-button" id="buttonGroupLeftText">
                    <span
                        className={!resendButton ? "otp-button-passive" : ""}>{intl.formatMessage({id: "OTP_RESEND_BUTTON_DESC"})}</span>
                    <button
                        className={`create-account-button ${!resendButton ? "passive" : ""}`}
                        disabled={!resendButton}
                        onClick={generateOPT}
                    >
                        <RetryIcon isPassive={!resendButton}/>
                        <span className="ml-2">{intl.formatMessage({id: "OTP_RESEND_BUTTON_TITLE"})}</span>
                    </button>
                </div>
                <button
                    id="kt_login_signin_submit"
                    className={`btn ${nextButton ? " btn-primary" : " btn-secondary"} font-weight-bold px-9 py-4 my-3`}
                    onClick={verifyCode}
                    disabled={!nextButton}
                >
                    <div className="d-flex flex-center">
                        <span>{intl.formatMessage({id: "NEXT_BUTTON"})}</span>
                        <ButtonArrowIcon/>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(OTPControl));
