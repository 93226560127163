import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeLatest} from "redux-saga/effects";
import {getTotpSecretKey, getUserByToken} from "./authCrud";

export const actionTypes = {
    ChangeAccessToken: "[ChangeAccessToken] Action",
    SaveToken: "[SaveToken] Action",
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    Register: "[Register] Action",
    ResetPassword: "[ForgotPassword] Action",
    UserLoaded: "[Load User] Auth API",
    SetUser: "[Set User] Action",
    SetTotp : "[Set Totp] Action",
    RedirectDashboard : "[Redirect] Action"
};

const initialAuthState = {
    accessToken : undefined,
    refreshToken : undefined,
    isLogin : false,
    totp : null
};

export const reducer = persistReducer(
    {storage, key: "v726-demo1-auth", whitelist: ["accessToken", "refreshToken", "isLogin", "totp"]},
    (state = initialAuthState, action) => {
        switch (action.type) {
            case actionTypes.ChangeAccessToken: {
                const {newToken} = action.payload;
                return {...state, accessToken: newToken};
            }

            case actionTypes.SaveToken: {
                const {accessToken, refreshToken} = action.payload;
                return {accessToken, refreshToken};
            }

            case actionTypes.Login: {
                const {accessToken, refreshToken} = action.payload;
                return {accessToken, refreshToken, isLogin: true};
            }

            case actionTypes.RedirectDashboard: {
                return {...state , isLogin: true};
            }

            case actionTypes.Register: {
                const {accessToken, refreshToken} = action.payload;
                return {accessToken, refreshToken};
            }

            case actionTypes.ResetPassword: {
                const {accessToken, refreshToken} = action.payload;
                return {accessToken, refreshToken};
            }

            case actionTypes.Logout: {
                return initialAuthState;
            }

            case actionTypes.UserLoaded: {
                const {user} = action.payload;
                return {...state, user};
            }

            case actionTypes.SetUser: {
                const {user} = action.payload;
                return {...state, user};
            }

            case actionTypes.SetTotp: {
                const {totp} = action.payload;
                return {...state, totp};
            }

            default:
                return state;
        }
    }
);

export const actions = {
    changeAccessToken: (newToken) => ({type: actionTypes.ChangeAccessToken, payload: {newToken}}),
    saveToken: (accessToken, refreshToken) => ({type: actionTypes.SaveToken, payload: {accessToken, refreshToken}}),
    login: (accessToken, refreshToken) => ({type: actionTypes.Login, payload: {accessToken, refreshToken}}),
    register: (accessToken, refreshToken) => ({
        type: actionTypes.Register,
        payload: {accessToken, refreshToken}
    }),
    resetPassword: (accessToken, refreshToken) => ({
        type: actionTypes.ResetPassword,
        payload: {accessToken, refreshToken}
    }),
    logout: () => ({type: actionTypes.Logout}),
    fulfillUser: user => ({type: actionTypes.UserLoaded, payload: {user}}),
    setUser: user => ({type: actionTypes.SetUser, payload: {user}}),
    setTotp: totp => ({type: actionTypes.SetTotp, payload: totp}),
    redirectDashboard: () => ({type : actionTypes.RedirectDashboard})
};

export function* saga() {

    // On the last step of register yielded will be work here for redirect to dashboard
    /*

    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser());
    });
      yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser());
      });
     */


    yield takeLatest(actionTypes.Login, function* userRequested() {
        const {data: user} = yield getUserByToken();
        const {data: totp} = yield getTotpSecretKey();
        yield put(actions.fulfillUser(user));
        yield put(actions.setTotp(totp));
    });

    yield takeLatest(actionTypes.RedirectDashboard, function* userRequested() {
        const {data: user} = yield getUserByToken();
        const {data: totp} = yield getTotpSecretKey();
        yield put(actions.fulfillUser(user));
        yield put(actions.setTotp(totp));
    });
}
