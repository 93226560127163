import React, {useEffect, useState} from 'react';
import {getPrivacyPolicy, getTermsOfUse} from "../_redux/authCrud";
import {useIntl} from "react-intl";

function PublicDocuments(props) {
    let type = props.location.pathname;
    const intl = useIntl();
    const [file, setFile] = useState("");
    const [loading, setLoading] = useState(true);
    const _getDocuments = () => {
        if (type === "/privacy-policy") {
            getPrivacyPolicy()
                .then(({data}) => {
                    setFile(`data:${data.mimeType};base64,${data.fileData}#toolbar=0&navpanes=0&scrollbar=0`)
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err, "error when getting terms of use")
                })
        } else {
            getTermsOfUse()
                .then(({data}) => {
                    setFile(`data:${data.mimeType};base64,${data.fileData}#toolbar=0&navpanes=0&scrollbar=0`)
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err, "error when getting terms of use")
                })
        }
    }
    useEffect(_getDocuments, []);
    return (
        <div className="bg-white w-100 h-100 p-10">
            {
                !loading &&
                <>
                    <h3 className="form-title text-center">
                        {
                            type === "/privacy-policy" ?
                                intl.formatMessage({id: "PRIVACY_POLICY"}) :
                                intl.formatMessage({id: "TERMS_OF_USE"})
                        }
                    </h3>
                    <iframe
                        title={
                            type === "/privacy-policy" ?
                                intl.formatMessage({id: "PRIVACY_POLICY"}) :
                                intl.formatMessage({id: "TERMS_OF_USE"})
                        }
                        src={file}
                        className={"pdfiFrame"}
                    />
                </>
            }
        </div>
    );
}

export default PublicDocuments;
