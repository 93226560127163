import React, {useEffect, useState} from 'react';
import FinalForm from "../../../app/modules/Auth/Components/FinalForm";
import {Link} from "react-router-dom";
import DashboardChangePasswordFirstPage from "../Components/DashboardChangePasswordFirstPage";
import DashboardOtp from "../Components/DashboardOTP";
import DashboardCreatePassword from "../Components/DashboardCreatePassword";
import {BackIcon} from "../../_assets/icons";
import {useIntl} from "react-intl";
import ToastComponent from "../../../app/modules/Auth/Components/ToastComponent";

function SettingsChangePassword(props) {
    const intl = useIntl();
    const [page, setPage] = useState(0);
    const [toastMenu, setToastMenu] = useState({status: false, descText: '', headerText: '', isWarning:false});

    const _redirectLogin = () => {
        if(page === 3) {
            setTimeout(()=>{
                props.history.push('/dashboard')
            },6000)
        }
    }

    useEffect(_redirectLogin,[page])

    return (
        <div className="bg-white w-100 h-100 card card-custom p-10">
            <ToastComponent
                headerText={toastMenu.headerText}
                descText={toastMenu.descText}
                show={toastMenu.status}
                onClose={() => {
                    setToastMenu({...toastMenu, status: false})
                }}
                isWarning={toastMenu.isWarning}
            />
            {
                page === 0 ?
                    <DashboardChangePasswordFirstPage
                        iconOnClick={() => props.history.push('/settings')}
                        changePage={() => setPage(1)}
                        setToastMenu={setToastMenu}
                    /> :
                    page === 1 ?
                        <DashboardOtp
                            headerTitle={intl.formatMessage({id: "ENTER_VERIFICATION_CODE"})}
                            headerIcon={<BackIcon/>}
                            iconOnClick={() => setPage(0)}
                            changePage={() => setPage(2)}
                            from={"dashboardChangePassword"}
                            setToastMenu={setToastMenu}
                        /> :
                        page === 2 ?
                            <DashboardCreatePassword
                                iconOnClick={() => setPage(1)}
                                changePage={() => setPage(3)}
                                setToastMenu={setToastMenu}
                            /> :
                            <FinalForm
                                text={
                                    <>
                                        <div className="finalFormText">
                                            {intl.formatMessage({id: "FORGOT_PASSWORD_COMPLETE_TEXT"})}
                                        </div>
                                        <Link
                                            to="/dashboard"
                                            className="text-dark-50 text-hover-primary my-3 mr-2 mt-5"
                                        >
                                            <u style={{color: "#3699FF", marginLeft: 5}}>
                                                {intl.formatMessage({id:"GO_TO_DASHBOARD"})}
                                            </u>
                                        </Link>
                                    </>
                                }
                            />
            }
        </div>
    );
}

export default SettingsChangePassword;
