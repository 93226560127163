import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {QuestionMark} from "../../_assets/icons";
import propTypes from 'prop-types';

TooltipComponent.propTypes = {
    tooltipText : propTypes.string.isRequired
}

function TooltipComponent(props) {
    return (
        <OverlayTrigger
            placement={"top"}
            overlay={
                <Tooltip id={`tooltip-top`}>{props.tooltipText}</Tooltip>
            }
        >
            <div variant="secondary" className="ml-2"><QuestionMark/></div>
        </OverlayTrigger>
    );
}

export default TooltipComponent;

