import React from 'react';
import Select from 'react-select';
import propTypes from 'prop-types';
import {useIntl} from "react-intl";

CarrierSelect.propTypes = {
    options: propTypes.array,
    placeholder: propTypes.string,
    value: propTypes.any,
    onChange: propTypes.func,
    title: propTypes.string,
    error : propTypes.object,
    isRequired : propTypes.bool,
    marginBottom : propTypes.bool
}

CarrierSelect.defaultProps = {
    error : {status:false,message:''},
    isRequired : true,
    marginBottom : true
}

function CarrierSelect(props) {
    const intl = useIntl();
    const colourStyles = {
        control: styles => {
            return {
                ...styles,
                backgroundColor: '#F3F6F9',
                color: '#3F4254',
                fontSize: '1rem',
                border: props.error.status ? "1px solid #FF4B55" : "none",
                outline: 'none',
                height: 50,
                boxShadow: 'none',
                paddingLeft: '1rem'
            }
        },
        option: (styles) => {
            return {
                ...styles,
                color: '#2F3044',
                fontSize: '14px',
                backgroundColor: '#ffffff'
            };
        },
        dropdownIndicator: styles => {
            return {
                ...styles,
                color: '#80808F'
            }
        },
        indicatorSeparator: styles => {
            return {
                display: 'none'
            }
        },
        placeholder: styles => {
            return {
                ...styles,
                color: '#B5B5C3',
                fontSize: '14px',
            }
        }
    };

    return (
        <div className={props.marginBottom ? "mb-7" : ""}>
            {
                props.title &&
                <div className={"d-flex form-title " + (props.headerClassName ? props.headerClassName : "")}>
                    {props.title}
                    {
                        props.isRequired &&
                        <span style={{color: "red", marginLeft: ".5em"}}>*</span>
                    }
                </div>
            }
            <Select
                value={props.value}
                styles={colourStyles}
                options={props.options}
                noOptionsMessage={() => intl.formatMessage({id: "NO_OPTIONS"})}
                placeholder={props.placeholder ? props.placeholder : null}
                onChange={props.onChange}
            />
            {
                (props.error.message && props.error.status) &&
                <div className="form-error-text mt-2 mb-0">{props.error.message}</div>
            }
        </div>
    )
}

export default CarrierSelect
